import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import BranchDetails from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/BranchDetails';
import { BranchResponseType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/types';
import { transformBranchResponseToBranchType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/utils';
import ChannelAuditTrail from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrail';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from 'redux/modules/channelList';
import { ReducerStateType } from 'redux/modules/reducers';
import { FixMeLater } from 'types';
import { useToggle } from 'utils/hooks';
import { useHasUserPermission } from 'utils/permissions';

export type BranchTabProps = {
	refetchBranch?: FixMeLater;
	isBranchError?: boolean;
};

const BranchTab: React.FC<BranchTabProps> = ({
	refetchBranch,
	isBranchError,
}) => {
	// const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const dispatch = useDispatch();

	const activeTypes = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeType
	);

	useEffect(() => {
		if (activeTypes == 'branch') {
			dispatch(setActiveTab({ tab: 'DetailsTab' }));
		}
	}, [activeTypes, dispatch]);

	const handleSelectedIndex = (index: number) => {
		if (activeTypes == 'branch') {
			if (index === 0) {
				dispatch(setActiveTab({ tab: 'DetailsTab' }));
			} else if (index === 1) {
				dispatch(setActiveTab({ tab: 'AuditTrail' }));
			}
		}
	};

	const activeTab = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeTab
	);

	const channelPrimaryInfo = useSelector((state: FixMeLater) => {
		return state.channels.channelFormValues.primaryInfo.channelPrimaryInfo;
	});

	const channelAccountId = useSelector(
		(state: FixMeLater) =>
			state.channels.channelFormValues.primaryInfo.id as number
	);

	const selectedBranch = useSelector(
		(state: FixMeLater) => state.channels.selectedBranch
	);

	const branchFormValues = useSelector((state: FixMeLater) => {
		return state.channels.branchFormValues as BranchResponseType;
	});

	let updatedAt: string;

	if (branchFormValues?.data?.branchPrimaryInfos?.updatedAt) {
		updatedAt = moment(
			branchFormValues?.data?.branchPrimaryInfos?.updatedAt
		).format('MM/DD/YYYY hh:mm:ss a');
	} else {
		updatedAt = 'unknown';
	}

	const hasSelectedBranch =
		Object.keys(channelPrimaryInfo || {}).length > 0 &&
		Object.keys(selectedBranch || {}).length &&
		Object.keys(branchFormValues || {}).length > 0;

	const hasBranchUserPermission = useHasUserPermission('branches');

	const { hasViewBranchPermission } = useMemo(() => {
		return {
			hasViewBranchPermission: hasBranchUserPermission('view'),
		};
	}, [hasBranchUserPermission]);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();
	useEffect(() => {
		if (activeTab == 'DetailsTab') {
			if ((hasSelectedBranch && hasViewBranchPermission) || isBranchError) {
				hideLoading();
			} else {
				showLoading();
			}
		}
	}, [
		activeTab,
		hasSelectedBranch,
		hasViewBranchPermission,
		hideLoading,
		isBranchError,
		showLoading,
	]);

	const primaryTabProps = {
		tabs: [
			{
				title: 'Branch Details',
				component:
					hasSelectedBranch &&
					activeTab == 'DetailsTab' &&
					hasViewBranchPermission ? (
						<BranchDetails
							defaultValues={transformBranchResponseToBranchType(
								branchFormValues,
								channelPrimaryInfo
							)}
							updatedAt={updatedAt}
							updatedBy={
								branchFormValues?.data?.branchPrimaryInfos?.updatedBy ||
								'unknown'
							}
							channelAccountId={channelAccountId}
							branchId={branchFormValues.data.id}
							branchStatus={branchFormValues.data.status}
							refetchBranch={refetchBranch}
						/>
					) : null,
				scope: { permissions: ['view'] },
			},
			{
				title: 'Audit Trail',
				component:
					// lazy load if not yet selected
					activeTab == 'AuditTrail' ? <ChannelAuditTrail /> : <p>Loading...</p>,
				scope: { permissions: ['list.audit-trail'] },
			},
		],
		defaultIndex: 0,
	};
	return (
		<>
			<PrimaryTab
				{...primaryTabProps}
				onSelect={handleSelectedIndex}
				module="branches"
			/>
			{isLoading && (
				<FullPageLoader
					open={isLoading}
					message={'Please wait while data are being loaded'}
				/>
			)}
		</>
	);
};

export default BranchTab;
