import React from 'react';
import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import { useFormContext } from 'react-hook-form';

type Props = {
	mode?: string;
	disabled?: boolean;
	setCurrentSection?: any;
	setPrevSection?: any;
	updateAutoSave?: any;
};

const TerminalIntegration: React.FC<Props> = ({
	mode,
	disabled,
	setCurrentSection,
	setPrevSection,
	updateAutoSave,
}) => {
	const { control } = useFormContext();

	return (
		<>
			<Section
				onClick={() => {
					if (mode === 'ADD') {
						setCurrentSection((prevState) => {
							setPrevSection(prevState);
							setCurrentSection('Integration');
						});
						updateAutoSave();
					}
				}}
				title="Integration"
			>
				<SectionRow>
					<Grid column size={1} of={3}>
						<TextField
							label="Token ID Registration"
							name="tokenId"
							control={control}
							disabled={disabled}
						/>
					</Grid>
				</SectionRow>
			</Section>
		</>
	);
};

export default TerminalIntegration;
