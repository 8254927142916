import Grid from 'components/Grid/Grid';
import { SelectFieldWithOthers } from 'components/Inputs/SelectFieldWithOthers/SelectFieldsWithOthers';
import TimeInput from 'components/Inputs/TimeField/TimeField';
import { SectionRow } from 'components/Section/Section';
import { TimeEntryProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/TimeEntry/types';
import { appendSuffix } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/TimeEntry/utils';
import { useFormContext } from 'react-hook-form';
import styles from './style.module.css';
import { COVERED_SCHEDULE } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/TimeEntry/constants';
import { ReportsSchemaType } from '../../../ReportsTabSchema';
import React from 'react';

const TimeEntry: React.FC<TimeEntryProps> = ({
	name,
	labelSuffix,
	disabled,
}) => {
	const { control, setValue } = useFormContext<ReportsSchemaType>();

	return (
		<SectionRow className={styles.timeContainer}>
			<Grid column container vertical className={styles.time}>
				<Grid column container>
					<Grid column size={1} of={2}>
						<TimeInput
							label={appendSuffix('Generation Time', labelSuffix)}
							required
							control={control}
							name={`${name}.generationTime`}
							disabled={disabled}
							onChange={(value) =>
								setValue(`${name}.generationTime`, value, {
									shouldTouch: true,
								})
							}
						/>

						{/* REQUIRED SO THAT UPLOADID AND GENERATEID WON'T VANISH WHEN CHANGING FREQUENCY */}
						<TimeInput
							type="hidden"
							required
							control={control}
							name={`${name}.uploadId`}
							disabled={disabled}
						/>
						<TimeInput
							type="hidden"
							required
							control={control}
							name={`${name}.pairId`}
							disabled={disabled}
						/>
						<TimeInput
							type="hidden"
							required
							control={control}
							name={`${name}.generateId`}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={2}>
						<TimeInput
							label={appendSuffix('Time of Upload', labelSuffix)}
							required
							control={control}
							name={`${name}.uploadTime`}
							disabled={disabled}
							onChange={(value) =>
								setValue(`${name}.uploadTime`, value, {
									shouldTouch: true,
								})
							}
							// onBlur={(e) => {
							// 	// validate generation time if upload time is set
							// 	if (e.target.value && genTime)
							// 		genTime && trigger(`${name}.generationTime`);
							// }}
						/>
					</Grid>
				</Grid>
				<Grid column container>
					<Grid column size={1} of={3}>
						<SelectFieldWithOthers
							label={appendSuffix('Covered Schedule', labelSuffix)}
							required
							control={control}
							name={`${name}.coveredSchedule`}
							onChange={(value) =>
								setValue(`${name}.coveredSchedule`, value, {
									shouldTouch: true,
								})
							}
							options={COVERED_SCHEDULE}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<TimeInput
							label={appendSuffix('Covered Time (from)', labelSuffix)}
							required
							control={control}
							name={`${name}.coveredTimeFrom`}
							onChange={(value) =>
								setValue(`${name}.coveredTimeFrom`, value, {
									shouldTouch: true,
								})
							}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<TimeInput
							label={appendSuffix('Covered Time (to)', labelSuffix)}
							required
							control={control}
							name={`${name}.coveredTimeTo`}
							onChange={(value) =>
								setValue(`${name}.coveredTimeTo`, value, {
									shouldTouch: true,
								})
							}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>
		</SectionRow>
	);
};

export default React.memo(TimeEntry);
