import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';

export const BIP_WALLET_REPLENISHMENT_APPROVAL_PERMISSION = {
	scope: 'bip.wallet.approval.replenishment',
	resource: '/v1/wallet-replenishments/{walletReplenishmentId}/approve',
};

export const EDIT_CHANNEL_WALLET_PERMISSION = {
	scope: 'cws.wallet.edit',
	resource: '/v1/wallets/{id}',
};

export const isRoleAvailable = (validRoles, roles) => {};

export const transformScopesToPermissions = (
	scopes: any[]
): Record<string, any> => {
	if (!scopes) return {};

	return scopes.reduce((acc, x) => {
		const { scope, category } = x as { scope: string; category: string };

		if (!scope) return acc;

		const scopeArr = scope.split('.');

		if (!scopeArr.length) return acc;

		let scopeNode = acc;

		for (let i = 0; i < 2 && i < scopeArr.length; i++) {
			const scope = scopeArr[i];
			let nextNode = scopeNode[scope];

			if (!nextNode) {
				nextNode = scopeNode[scope] = {};
			}

			scopeNode = nextNode;
		}

		if (category) {
			if (!scopeNode[category]) {
				scopeNode[category] = {};
			}

			scopeNode = scopeNode[category];
		}

		const trailingScope: string[] = [];

		for (let i = 2; i < scopeArr.length; i++) {
			const scope = scopeArr[i];
			trailingScope.push(scope);
		}

		if (trailingScope.length) scopeNode[trailingScope.join('.')] = {};

		return acc;
	}, {}) as Record<string, any>;
};

export const useHasUserPermission = (
	...rootScopes: string[]
): ((...scopes: string[]) => boolean) => {
	const permissions = useSelector<
		ReducerStateType,
		Record<string, any> | undefined
	>((state) => {
		let scopeNode = state.userInfo.permissions || {};

		const platform = 'bip';

		if (!scopeNode[platform]) return undefined;

		scopeNode = scopeNode[platform];

		for (const scope of rootScopes) {
			const nextNode = scopeNode[scope];
			if (!nextNode) return nextNode;

			scopeNode = nextNode;
		}

		return scopeNode;
	});

	const hasUserPermission = useCallback(
		(...scopes: string[]) => {
			if (!permissions || _.isEmpty(permissions)) return false;

			let scopeNode = permissions;

			for (const scope of scopes) {
				const nextNode = scopeNode[scope];

				if (!nextNode) return false;

				scopeNode = nextNode;
			}

			return true;
		},
		[permissions]
	);

	return hasUserPermission;
};
