import { Dropdown } from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import Label from '../Label/Label';
import styles from './SelectField.module.css';

export type SelectOption = {
	label: string;
	value: any;
	className?: string;
	leftIcon?: {
		name: string;
		category: string;
	};
	isHidden?: boolean;
};

export type SelectFieldProps = {
	label?: string;
	placeholder?: string;
	onChange?: (value: SelectOption) => void;
	className?: string;
	value?: any;
	labelPrefix?: string;
	defaultValue?: any;
	error?: string;
	errorAdd?: string;
	name?: string;
	options: SelectOption[];
	required?: boolean;
	isLoading?: boolean;
	fullWidth?: boolean;
	displayErrorMessage?: boolean;
	disabled?: boolean;
	shouldUnregister?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
	onBlur?: () => void;
	optional?: boolean;
	getOptionValue?: (option: any) => any;
	getComparator?: (option: SelectOption, value: any) => boolean;
	size?: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';
	inputRef?: any;
	triggerClassName?: string;
	isOpen?: boolean;
	buttonClassName?: string;
	menuPosition?: string;
	overrideOption?: boolean;
};

const PlainSelectField: React.FC<SelectFieldProps> = ({
	label = '',
	placeholder,
	onChange,
	onOpen,
	onClose,
	className = '',
	value,
	defaultValue,
	error,
	errorAdd,
	name,
	options,
	required = false,
	isLoading = false,
	disabled = false,
	shouldUnregister = false,
	size = 'medium',
	optional = false,
	displayErrorMessage = true,
	getComparator = ({ value }, currVal) => value == currVal,
	onBlur,
	labelPrefix,
	inputRef = null,
	fullWidth = true,
	triggerClassName = '',
	isOpen,
	buttonClassName,
	menuPosition = 'relative',
	overrideOption = true,
}) => {
	const p = placeholder !== undefined ? placeholder : label;
	const [currVal, setCurrVal] = useState(defaultValue);
	const [initialized, setInitialized] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setCurrVal(value || defaultValue);
		setInitialized(true);
	}, []);

	useEffect(() => {
		if (initialized) {
			setCurrVal(value);
		}
	}, [initialized, value]);

	const formattedOptions = useMemo(() => {
		return !isLoading
			? options.map((o) => ({
					className: cx(styles.selectItem, {
						[styles.selectItemActive]: value?.value === o.value,
					}),
					...o,
			  }))
			: [];
	}, [isLoading, options, value]);

	const match = useMemo(() => {
		if (formattedOptions && currVal) {
			const out = formattedOptions.find(
				(option) => currVal == option.value || currVal == option.value?.id
			);

			if (out) {
				return {
					...out,
					label: `${labelPrefix || ''}${out.label}`,
				};
			}

			if (!overrideOption) return {};

			return defaultValue ? { label: label || defaultValue } : {};
		}
		return undefined;
	}, [currVal, formattedOptions, getComparator, labelPrefix]);

	const menuStyle = useMemo(
		() => ({
			// minWidth: dropdownRef.current?.clientWidth
			top: '100%',
			left: '0',
		}),
		[dropdownRef.current?.clientWidth]
	);

	return (
		<div
			className={cx(
				styles.container,
				{ [styles.fullWidth]: fullWidth },
				className
			)}
			ref={dropdownRef}
		>
			{label && (
				<Label required={required} optional={optional}>
					{label}
				</Label>
			)}
			<div tabIndex={0} ref={inputRef}>
				<Dropdown
					name={name}
					align="right"
					width={size}
					tabIndex={-1}
					iconCategory="utility"
					iconName={disabled ? '' : isLoading ? 'sync' : 'down'}
					iconPosition="right"
					className={styles.dropdownContainer}
					containerClassName={styles.dropdownContainer}
					menuStyle={menuStyle}
					// menuPosition={menuPosition}
					disabled={disabled || isLoading}
					defaultValue={defaultValue || value}
					onBlur={() => onBlur?.()}
					isOpen={isOpen}
					onOpen={() => {
						onOpen?.();
					}}
					onClose={() => {
						onClose?.();
					}}
					triggerClassName={cx(
						styles.select,
						{
							[styles.error]: !!error,
							[styles.errorAdd]: !!errorAdd,
							[styles.empty]: !value,
							[styles.loading]: isLoading,
							[styles.disabledField]: disabled,
						},
						triggerClassName
					)}
					label={match?.label || p}
					onSelect={(option: SelectOption) => {
						onChange && onChange(option);
					}}
					options={formattedOptions.filter((x) => !x.isHidden)}
					buttonClassName={buttonClassName}
					value={value || defaultValue || undefined}
				/>
			</div>
			{error && displayErrorMessage && (
				<div
					className={cx({
						'slds-has-error': !!error,
					})}
				>
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						{error}
					</div>
				</div>
			)}
			{errorAdd && displayErrorMessage && (
				<div
					className={cx({
						'slds-has-error': !!errorAdd,
					})}
				>
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						{errorAdd}
					</div>
				</div>
			)}
		</div>
	);
};

const SelectField: React.FC<SelectFieldProps & { control?: Control<any> }> = ({
	control,
	name,
	defaultValue,
	disabled,
	shouldUnregister,
	...rest
}) => {
	if (control && name) {
		const { onChange } = rest;

		return (
			<Controller
				control={control}
				name={name}
				shouldUnregister={shouldUnregister}
				render={({ field, fieldState: { error } }) => {
					const { getOptionValue = ({ value }) => value } = rest;
					return (
						<>
							<PlainSelectField
								{...rest}
								{...field}
								onChange={(v) => {
									field.onChange(getOptionValue(v));
									onChange?.(getOptionValue(v));
								}}
								defaultValue={defaultValue || field.value}
								onBlur={field.onBlur}
								error={error?.message}
								disabled={disabled}
								inputRef={field.ref}
								menuPosition="left"
							/>
						</>
					);
				}}
				defaultValue={defaultValue}
			/>
		);
	}
	return (
		<PlainSelectField
			name={name}
			defaultValue={defaultValue}
			disabled={disabled}
			{...rest}
		/>
	);
};

export default SelectField;
