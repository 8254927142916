import React, {
	useState,
	useEffect,
	useMemo,
	useCallback,
	ReactElement,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useToggle } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce, isEmpty } from 'lodash';
import btnStyle from 'components/PartnerDetails/PartnerDetails.module.css';
import ChannelForm from 'containers/ChannelManagement/Channel/ChannelForm/ChannelForm';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModal, { ErrorModalBody } from 'components/Modal/ErrorModal';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import channelDetailsStyle from 'containers/ChannelManagement/Channel/ChannelDetails/index.module.css';
import { ChannelDetailsProps } from 'containers/ChannelManagement/Channel/ChannelDetails/types';
import { ReactComponent as EditIcon } from 'assets/icons/ic-save.svg';
import { Button } from '@salesforce/design-system-react/module/components';
import ConfirmationModal from 'containers/ChannelManagement/Terminal/Modal/ConfirmationModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import Grid from 'components/Grid/Grid';
import ApiBatchResponseModal from 'components/Modal/ApiBatchResponseModal';
import StatusUpdate from 'containers/ChannelManagement/Channel/ChannelStatusSelect/StatusUpdate';
import cx from 'classnames';

import { transformResponseToChannelDetails } from 'containers/ChannelManagement/Channel/ChannelDetails/utils';
import { useGetChannelDetails } from 'containers/ChannelManagement/Channel/ChannelProfile/query';
import { UseUpdateChannelDetails } from 'containers/ChannelManagement/Channel/ChannelForm/ChannelFormQuery';
import { ReducerStateType } from 'redux/modules/reducers';
import { convertDate } from 'utils/date';

import {
	PrimaryInfoType,
	PrimaryInformationSchema,
	primartyInfoInitlValues,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/PrimaryInformationSchema';
import {
	ContractDetailsSchema,
	ContractDetailsType,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/ContractDetails/ContractDetailsSchemas';
import {
	ReportsSchemaType,
	reportsDefaultValue,
	reportsSchema,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/ReportsTabSchema';
import { CONTRACT_DETAILS_DEFAULT_VALUE } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/ContractDetails/ContractDetailsConstants';
import { useFormErrorHandling } from 'containers/ChannelManagement/Channel/ChannelForm/hooks';
import { useHasUserPermission } from 'utils/permissions';
import { FixMeLater } from 'types';
import { TabStatus } from 'containers/ChannelManagement/Channel/ChannelModal/types';
import ChannelProductModal from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal';
import moment from 'moment';
import {
	setLoader,
	selectedChannel as setSelectedChannel,
} from 'redux/modules/channelList';

export interface PermissionObject {
	primaryInfo: boolean;
	contractDetails: boolean;
	reports: boolean;
}

type UpdateSummary = {
	label: string;
	status: string;
};

const ChannelDetails: React.FC<ChannelDetailsProps> = ({
	selectedChannel,
	refetchChannel,
	setIsChannelDirty,
	isChannelDirty,
	setIsEditMode,
	isEditMode,
}) => {
	const [currentTab, setCurrentTab] = useState<number>(0);
	const [accessDeniedBodyText, setAccessDeniedBodyText] = useState(<></>);
	const [onActiveTab, setOnActiveTab] = useState<boolean>(true);
	const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(true);
	const [remarks, setRemarks] = useState('');
	const [isValidating, setIsValidating] = useState(true);
	const [isRefetch, setIsRefetch] = useState<boolean>(false);
	const [updatedAt, setUpdatedAt] = useState('');
	const [updatedBy, setUpdatedBy] = useState('');
	const [forceRerender, setForceRerender] = useState('primary');
	const [forceHideButtons, setForceHiddenButtons] = useState(true);
	const [channelStatus, setChannelStatus] = useState('');
	const [channelId, setChannelId] = useState();
	const [channelName, setChannelName] = useState('');

	const dispatch = useDispatch();

	const updateSummaryInitialValues = [
		{ label: 'Primary Information', status: 'No Action' },
		{ label: 'Contract Details', status: 'No Action' },
		{ label: 'Reports', status: 'No Action' },
	];
	const [updateSummary, setUpdateSummary] = useState<UpdateSummary[]>(
		updateSummaryInitialValues
	);

	const [tabStatus, setTabStatus] = useState<TabStatus>({
		primaryInformation: { disabled: false, finished: false },
		contractDetails: { disabled: true, finished: false },
		reports: { disabled: true, finished: false },
	});

	const [errorRemarks, setErrorRemarks] = useState({
		hasError: false,
		status: '',
	});
	const [exitModeMessage, setExitModeMessage] = useState<{
		title: string;
		message: string | ReactElement;
	}>({ title: '', message: '' });
	const hasUserPermission = useHasUserPermission('channels');
	const hasEditStatusPermission = hasUserPermission('edit.status');
	const hasChannelListPermission =
		hasUserPermission('view.primary-info') ||
		hasUserPermission('view.contract-details') ||
		hasUserPermission('view.reports');

	const {
		value: isExitModeModalOpen,
		valueOn: showExitModal,
		valueOff: hideExitModal,
	} = useToggle();

	const {
		value: isSummaryModalActive,
		valueOn: showSummaryModal,
		valueOff: hideSummaryModal,
	} = useToggle();

	const styles = {
		...btnStyle,
		...channelDetailsStyle,
	};

	const _userInfo = useSelector<ReducerStateType, FixMeLater>(
		(state) => state.userInfo
	);

	const channelFormValues = useSelector<
		ReducerStateType,
		{
			primaryInfo: FixMeLater;
			contractDetails: FixMeLater;
			reports: FixMeLater;
		}
	>((state) => state.channels && state.channels.channelFormValues);

	const selectedChannelReducer = useSelector<ReducerStateType, any>(
		(state) => state.channels.selectedChannel
	);

	const loader = useSelector<ReducerStateType, { isLoading: boolean }>(
		(state) => state.channels && state.channels.loader
	);

	useEffect(() => {
		if (selectedChannelReducer?.id !== channelId) {
			setIsEditMode(true);
			setIsValidating(true);
			setIsRefetch(!isRefetch);
			setCurrentTab(0);
		}
		setChannelId(selectedChannelReducer?.id);
	}, [selectedChannel]);

	const hasEdit: PermissionObject = useMemo(() => {
		return {
			primaryInfo: hasUserPermission('edit.primary-info'),
			contractDetails: hasUserPermission('edit.contract-details'),
			reports: hasUserPermission('edit.reports'),
		};
	}, [hasUserPermission]);

	const hasView: PermissionObject = useMemo(() => {
		return {
			primaryInfo: hasUserPermission('view.primary-info'),
			contractDetails: hasUserPermission('view.contract-details'),
			reports: hasUserPermission('view.reports'),
		};
	}, [hasUserPermission]);

	const channelDetailsValue = useGetChannelDetails(
		selectedChannel && selectedChannel.id
	);

	const allFetched = channelDetailsValue.every(
		(query) =>
			(query.isFetched === true && query.isFetching === false) ||
			(query.isFetched === false && query.status === 'idle')
	);

	const hasError = channelDetailsValue.every((query) => query.isError === true);

	const {
		value: hasPermission,
		valueOn: showPermissionErrorModal,
		valueOff: hidePermissionErrorModal,
	} = useToggle();

	const {
		value: isSaveConfirmation,
		valueOn: showSaveConfirmation,
		valueOff: hideSaveConfirmation,
	} = useToggle();

	const setErrorModal = (
		tab = 'Primary Info',
		isTimeoutError = false,
		buttonText = 'Close',
		recallFunc?: () => void
	) => {
		setAccessDeniedBodyText(
			<>
				<div className={styles.confirmationHeader}>
					{isTimeoutError ? 'Timeout Error!' : 'Access Denied'}
				</div>
				<div className={styles.subtext}>
					{isTimeoutError ? (
						<>
							<p>A problem occurred while loading the data.</p>
							<p>Please try again.</p>
							<br />
							<br />
						</>
					) : (
						<>
							{`Sorry, you don't have access to this function.`}
							<br />
							Please contact your admin to grant access
							<br />
							permission. Thank you.
							<br />
							<br />
						</>
					)}
				</div>
				<PrimaryButton
					className={styles.successBtn}
					onClick={() => {
						if (recallFunc) {
							hidePermissionErrorModal();
							recallFunc();
							// hideLoadingChannel();
						} else {
							buttonText === 'Close'
								? hidePermissionErrorModal()
								: showPermissionErrorModal();
						}
					}}
				>
					{buttonText}
				</PrimaryButton>
			</>
		);

		dispatch(setLoader({ isLoading: false, message: 'Please Wait...' }));

		showPermissionErrorModal();
	};

	const setLoaderStatus = (load, message) => {
		dispatch(
			setLoader({
				isLoading: load,
				message: message,
			})
		);
	};

	const transformedData = useMemo(() => {
		if (!isEmpty(selectedChannel)) {
			setLoaderStatus(true, 'Please wait while data are being loaded');

			setForceRerender(`render-${Math.random().toString(36).substring(2, 15)}`);
			if (allFetched) {
				if (hasError) {
					setTimeout(() => {
						setLoaderStatus(false, 'Please Wait...');
						setErrorModal('', true, 'Retry', async () => {
							await Promise.all(
								channelDetailsValue.map(async (query) => {
									const { refetch } = query;
									await refetch();
								})
							);
						});
					}, 500);
				}
				let payload: any = {
					primaryInfo: '',
					contractDetails: '',
					reports: '',
				};
				channelDetailsValue.forEach((data, index) => {
					if (data.isSuccess) {
						if (index === 0) {
							payload = { primaryInfo: data.data.data, meta: data.data.meta };
							setChannelName(data.data.data.channelPrimaryInfo.name);
						}
						if (index === 1) {
							payload = { ...payload, contractDetails: data.data.data };
							setChannelStatus(data.data.data.status);
						}
						if (index === 2) {
							payload = { ...payload, reports: data.data.data };
						}
					}
				});
				setLoaderStatus(false, 'Please Wait...');
				return transformResponseToChannelDetails(
					payload.primaryInfo,
					payload.contractDetails,
					payload.reports,
					payload.meta
				);
			}

			return null;
		}
	}, [allFetched, hasError, isRefetch, dispatch]);

	const primaryData = useMemo(() => {
		return transformedData &&
			!isEmpty(transformedData?.data?.channelPrimaryInfo)
			? { primaryInfo: transformedData?.data?.channelPrimaryInfo }
			: primartyInfoInitlValues; // get value from redux else initial value
	}, [transformedData, updateSummary]);

	const contractData = useMemo(() => {
		return transformedData &&
			!isEmpty(transformedData?.data?.channelContractDetails)
			? transformedData?.data?.channelContractDetails
			: CONTRACT_DETAILS_DEFAULT_VALUE; // get value from redux else initial value
	}, [transformedData]);

	const reportData =
		transformedData && !isEmpty(transformedData?.data?.channelReports)
			? transformedData?.data?.channelReports
			: reportsDefaultValue; // get value from redux else initial value

	const primaryForm = useForm<PrimaryInfoType>({
		mode: 'all',
		resolver: yupResolver(PrimaryInformationSchema.objectSchema),
		defaultValues: primaryData,
	});
	useFormErrorHandling(primaryForm, 'primaryForm');

	const contractForm = useForm<ContractDetailsType>({
		mode: 'all',
		resolver: yupResolver(ContractDetailsSchema),
		defaultValues: contractData,
	});

	useFormErrorHandling(contractForm, 'contractForm');

	const reportsForm = useForm<ReportsSchemaType>({
		mode: 'all',
		resolver: yupResolver(reportsSchema),
		defaultValues: reportData,
	});

	useFormErrorHandling(reportsForm, 'reportsForm');

	const { control: primaryControl, formState: form } = primaryForm;
	const { control: contractControl } = contractForm;
	const { control: reportsControl } = reportsForm;

	const primaryFormListener = useWatch({ control: primaryControl });
	const contractFormListener = useWatch({ control: contractControl });
	const reportsListener = useWatch({ control: reportsControl });

	useEffect(() => {
		primaryForm.reset(primaryData);
		contractForm.reset(contractData);
		reportsForm.reset(reportData);
	}, [transformedData, selectedChannel]);

	useEffect(() => {
		validateField(isValidating);
	}, [primaryFormListener, contractFormListener, reportsListener]);

	useEffect(() => {
		setUpdatedAt(convertDate(transformedData?.data.updatedAt, 'ISO') || 'N/A');
		setUpdatedBy(transformedData?.data.updatedBy || 'N/A');
	}, [transformedData]);

	const updateUpdatedAt = () => {
		setUpdatedBy(_userInfo.username);
		setUpdatedAt(moment().format('MM/DD/YYYY hh:mm:ss a'));
	};

	useEffect(() => {
		if (currentTab === 2) {
			setForceRerender('report');
		}
	}, [currentTab]);

	useEffect(() => {
		if (isChannelDirty) {
			updateUpdatedAt();
		}
	}, [isChannelDirty]);

	const validateField = useCallback(
		debounce(async (isValidating) => {
			primaryForm.formState.errors;
			contractForm.formState.errors;
			reportsForm.formState.errors;

			if (isValidating) {
				await primaryForm.trigger();
				await contractForm.trigger();
				await reportsForm.trigger();
			}

			const { isValid: isPrimaryValid } = primaryForm.formState;
			const { isValid: isContractValid } = contractForm.formState;
			const { isValid: isReportsValid } = reportsForm.formState;

			setIsChannelDirty(
				Object.keys(contractForm.formState.dirtyFields).length > 0 ||
					Object.keys(primaryForm.formState.dirtyFields).length > 0 ||
					Object.keys(reportsForm.formState.touchedFields).length > 0
			);

			const newTabStatus = {
				primaryInformation: {
					disabled: !isPrimaryValid,
					finished: isPrimaryValid,
				},
				contractDetails: {
					disabled: !isContractValid,
					finished: isContractValid,
				},
				reports: { disabled: !isReportsValid, finished: isReportsValid },
			};

			if (newTabStatus !== tabStatus) {
				setTabStatus(newTabStatus);
			}

			setIsValidating(false);
		}, 2000),
		[]
	);

	const getTabNameByIndex = (index: number) => {
		const names = ['Primary Information', 'Contract Details', 'Reports'];
		return names[index];
	};

	const getCurrentTab = (index: number) => {
		if (!isEditMode) {
			if (hasEditPermission(index) === false) {
				setErrorModal(getTabNameByIndex(index), false, 'Close');
				setOnActiveTab(false);
			} else {
				setCurrentTab(index);
				setOnActiveTab(false);
			}
		} else {
			if (isEditMode) {
				if (hasViewPermission(index) === false) {
					setErrorModal(getTabNameByIndex(index), false, 'Close');
					setCurrentTab(index);
				} else {
					setCurrentTab(index);
					setOnActiveTab(false);
				}
			}
		}
	};

	const hasEditPermission = (tab: number) => {
		const editKeys = ['primaryInfo', 'contractDetails', 'reports'];
		const editKey = editKeys[tab];
		return hasEdit[editKey];
	};

	const hasViewPermission = (tab: number) => {
		const viewKeys = ['primaryInfo', 'contractDetails', 'reports'];
		const viewKey = viewKeys[tab];
		return hasView[viewKey];
	};

	const handleEditModeClick = () => {
		const permission = hasEditPermission(currentTab);
		if (permission) {
			if (isEditMode) {
				setIsEditMode(!isEditMode);
				setIsChannelDirty(false);
				setForceHiddenButtons(false);
				setOnActiveTab(false);
			} else {
				if (isChannelDirty) {
					// to add contract and reports
					setExitModeMessage({
						title: 'Exit Edit Mode',
						message: (
							<>
								<br />
								Are you sure you want to exit without saving?
								<br />
								Any unsaved work will be lost.
							</>
						),
					});
					showExitModal();
				} else {
					setExitModeMessage({
						title: 'Exit Edit Mode',
						message: (
							<>
								<br />
								<br />
								Are you sure you want to exit edit mode?
							</>
						),
					});
					showExitModal();
				}
			}
		} else {
			if (isEditMode !== false) {
				setErrorModal(getTabNameByIndex(currentTab), false, 'Close');
			} else {
				showExitModal();
			}
		}
	};

	const cancelExitModal = () => {
		hideExitModal();
		// setOnActiveTab(!onActiveTab);
	};

	const saveChanges = async () => {
		closeSaveModal();

		dispatch(
			setLoader({
				isLoading: true,
				message: 'Please wait while we save your updates.',
			})
		);

		try {
			await Promise.all([
				Object.keys(contractForm.formState.dirtyFields).length > 0
					? await saveContractDetails().then(() => contractForm.reset())
					: Promise.resolve(),

				Object.keys(reportsForm.formState.touchedFields).length > 0
					? await saveReports().then(() => reportsForm.reset())
					: Promise.resolve(),

				Object.keys(primaryForm.formState.dirtyFields).length > 0
					? await savePrimaryInfo().then(() => primaryForm.reset())
					: Promise.resolve(),
			]);

			dispatch(
				setLoader({
					isLoading: false,
					message: 'Please Wait...',
				})
			);

			showSummaryModal();
		} catch (error) {
			dispatch(
				setLoader({
					isLoading: false,
					message: 'Please Wait...',
				})
			);

			setErrorModal('', true, 'Retry', saveChanges);
		}
	};

	const setRefetch = async () => {
		setIsRefetch(!isRefetch);
	};

	const setUpdateSummaryState = (scope: string, isSuccess: boolean) => {
		setUpdateSummary((prev) => {
			const data = prev.find((v) => v.label === scope);
			if (data) {
				data.status = isSuccess ? 'Success' : 'Failed';
			}
			return [...prev];
		});
	};

	const savePrimaryInfo = async () => {
		const toSave = primaryForm.getValues().primaryInfo;

		if (!primaryForm.formState.dirtyFields?.primaryInfo?.logo) {
			toSave.logo.objectKey = null;
		}

		const param = {
			remarks: remarks,
			channelPrimaryInfo: {
				...toSave,
				posType: toSave.posType ? toSave.posType : undefined,
			},
		};

		try {
			const result = await UseUpdateChannelDetails(
				selectedChannel.id,
				param,
				'primary-info'
			);
			if (result.status === 200) {
				const logo = result.data.data.logo;
				toSave.logo.objectKey = logo;

				setUpdateSummaryState('Primary Information', true);
				dispatch(
					setSelectedChannel({
						channel: {
							...selectedChannelReducer,
							name: toSave.channelAccountName,
						},
					})
				);
				setChannelName(toSave.channelAccountName);
				primaryForm.reset({ primaryInfo: toSave });
			}
		} catch (err) {
			setUpdateSummaryState('Primary Information', false);
			throw err;
		}
	};

	const saveContractDetails = async () => {
		const toSave = contractForm.getValues().contractDetails;
		const param = {
			remarks: remarks,
			channelContractDetails: {
				...toSave,
				bondStartDate: toSave.bondStartDate
					? moment(toSave.bondStartDate).format('YYYY-MM-DD')
					: null,
				bondEndDate: toSave.bondEndDate
					? moment(toSave.bondEndDate).format('YYYY-MM-DD')
					: null,
				channelCashCollection: toSave.channelCashCollection
					? toSave.channelCashCollection.every((obj) => {
							return !Object.values(obj).every(
								(value) => value === '' || value === null || value === undefined
							);
					  })
						? toSave.channelCashCollection
						: [
								{
									acctName: null,
									acctNo: null,
									acctType: null,
									bankBranch: null,
									bankId: null,
								},
						  ]
					: [
							{
								acctName: null,
								acctNo: null,
								acctType: null,
								bankBranch: null,
								bankId: null,
							},
					  ],
				channelCheckCollection: toSave.channelCheckCollection
					? toSave.channelCheckCollection.every((obj) => {
							return !Object.values(obj).every(
								(value) => value === '' || value === null || value === undefined
							);
					  })
						? toSave.channelCheckCollection
						: [
								{
									acctName: null,
									acctNo: null,
									acctType: null,
									bankBranch: null,
									bankId: null,
								},
						  ]
					: [
							{
								acctName: null,
								acctNo: null,
								acctType: null,
								bankBranch: null,
								bankId: null,
							},
					  ],
				channelTermsDuration: {
					...toSave.channelTermsDuration,
					executionDate: toSave.channelTermsDuration.executionDate
						? moment(toSave.channelTermsDuration.executionDate).format(
								'YYYY-MM-DD'
						  )
						: null,
					terminationDate: toSave.channelTermsDuration.terminationDate
						? moment(toSave.channelTermsDuration.terminationDate).format(
								'YYYY-MM-DD'
						  )
						: null,
				},
				otherBusinessLines: toSave.otherBusinessLines.toString(),
			},
		};
		try {
			const result = await UseUpdateChannelDetails(
				selectedChannel.id,
				param,
				'contract-details'
			);
			if (result.status === 200) {
				setUpdateSummaryState('Contract Details', true);
			}
		} catch (err) {
			setUpdateSummaryState('Contract Details', false);
			throw err;
		}
	};

	const saveReports = async () => {
		const toSave = reportsForm.getValues();
		const param = {
			remarks: remarks,
			channelReports: {
				...toSave,
				sftp: {
					...toSave.sftp,
					csrConfig: {
						...toSave.sftp.csrConfig,
						csrColumns: toSave.sftp.csrConfig.csrColumns
							? toSave.sftp.csrConfig.csrColumns.map(
									(column) => column.id || column
							  )
							: [],
					},
					uploadConfig: {
						...toSave.sftp.uploadConfig,
						csrColumns: toSave.sftp.uploadConfig.csrColumns
							? toSave.sftp.uploadConfig.csrColumns.map(
									(column) => column.id || column
							  )
							: [],
					},
				},
				smtp: {
					...toSave.smtp,
					csrConfig: {
						...toSave.smtp.csrConfig,
						csrColumns: toSave.smtp.csrConfig.csrColumns
							? toSave.smtp.csrConfig.csrColumns.map(
									(column) => column.id || column
							  )
							: [],
					},
					uploadConfig: {
						...toSave.smtp.uploadConfig,
						csrColumns: toSave.smtp.uploadConfig.csrColumns
							? toSave.smtp.uploadConfig.csrColumns.map(
									(column) => column.id || column
							  )
							: [],
					},
				},
			},
		};

		try {
			const result = await UseUpdateChannelDetails(
				selectedChannel.id,
				param,
				'reports'
			);
			if (result.status === 200) {
				reportsForm.reset(toSave);
				setUpdateSummaryState('Reports', true);
			}
		} catch (err) {
			setUpdateSummaryState('Reports', false);
			throw err;
		}
	};

	const handleRemarks = (e) => {
		const text = e.target.value;

		if (text === '' && remarks !== '') {
			setErrorRemarks({ hasError: true, status: 'Please input remarks.' });
			setIsRemarksEmpty(true);
		} else {
			setRemarks(text);
			setIsRemarksEmpty(false);
			setErrorRemarks({ hasError: false, status: '' });
		}
	};

	const closeSaveModal = () => {
		setErrorRemarks({ hasError: false, status: '' });
		setRemarks('');
		setIsRemarksEmpty(true);
		hideSaveConfirmation();
	};

	const createdAt =
		convertDate(transformedData?.data.createdAt, 'ISO') ||
		convertDate(channelFormValues.primaryInfo.createdAt, 'ISO') ||
		'N/A';
	const createdBy =
		transformedData?.data.createdBy ||
		channelFormValues.primaryInfo?.channelPrimaryInfo?.createdBy ||
		'N/A';

	return selectedChannel && selectedChannel.id !== undefined ? (
		<div className={styles.container}>
			{selectedChannel && !loader.isLoading && (
				<>
					<div className={styles.title}>
						{channelName || selectedChannelReducer?.name || 'Channel Name'}{' '}
						<div className={styles.rightPanel}>
							<div className={styles.edit}>
								<Button
									disabled={isValidating}
									className={cx(styles.linkBtn, styles.edit)}
									style={{ marginTop: '-5vh' }}
									onClick={handleEditModeClick}
								>
									<EditIcon />
									{!isEditMode ? 'Exit Edit Mode' : 'Edit Channel Details'}
								</Button>
							</div>
						</div>
					</div>

					<>
						<div className={styles.status}>
							<div className={styles.statusLabel}>Channel Status:</div>
							<div
								className={styles.statusField}
								onClick={() => {
									if (!hasEditStatusPermission) {
										setErrorModal('', false);
									}
								}}
							>
								<StatusUpdate
									value={channelStatus || ''}
									id={selectedChannel.id}
									idType="channels"
									onClose={(success) => {}}
									refetch={() => {
										refetchChannel?.();
									}}
								/>
							</div>
						</div>
						<div className={styles.rightPanelText}>
							<div className={cx(styles.dates, styles.contentSpace)}>
								<em className={styles.dateText}>
									{updatedAt && 'Last updated at ' + updatedAt}
									{updatedBy && updatedBy !== '-' && ' by ' + updatedBy}
								</em>
								<em className={styles.dateText}>
									{createdAt && 'Date created: ' + createdAt}
								</em>
								<em className={styles.dateText}>
									{createdBy && ' by ' + createdBy}
								</em>
							</div>
						</div>
					</>

					{transformedData &&
						transformedData.data &&
						!hasError &&
						forceRerender && (
							<ChannelForm
								primaryData={primaryData}
								primaryForm={primaryForm}
								contractForm={contractForm}
								reportsForm={reportsForm}
								disabled={isEditMode || onActiveTab}
								setSelectedIndex={getCurrentTab}
								selectedIndex={currentTab}
								tabStatus={tabStatus}
								isChannelDetails={true}
								hasPrimaryViewPermission={hasUserPermission(
									'view.primary-info'
								)}
								hasContractViewPermission={hasUserPermission(
									'view.contract-details'
								)}
								hasReportsViewPermission={hasUserPermission('view.reports')}
							/>
						)}

					{!isEditMode && !onActiveTab && !forceHideButtons && (
						<div style={{ padding: '17px', width: '100%', display: 'flex' }}>
							<div style={{ width: '20%', display: 'inline' }}>
								{currentTab !== 0 && (
									<Grid container gutters="xx-small" size={12}>
										<Grid column size={8} of={12}>
											<OutlineButton
												disabled={currentTab === 0}
												className={styles.successBtn}
												onClick={() => {
													const hasPermission = hasEditPermission(
														currentTab - 1
													);
													if (hasPermission) {
														setCurrentTab(currentTab - 1);
													} else {
														setErrorModal(
															getTabNameByIndex(currentTab + 1),
															false,
															'Close'
														);
													}
												}}
											>
												Back
											</OutlineButton>
										</Grid>
									</Grid>
								)}
							</div>
							<div className={styles.displayRow}>
								<div className={styles.displayColumn}>
									<PrimaryButton
										disabled={
											!(
												tabStatus.primaryInformation.finished &&
												tabStatus.contractDetails.finished &&
												tabStatus.reports.finished &&
												isChannelDirty
											)
										}
										style={{ float: 'right' }}
										className={styles.successBtn}
										onClick={showSaveConfirmation}
									>
										Save
									</PrimaryButton>
								</div>

								{currentTab !== 2 && (
									<div className={styles.displayColumn}>
										<PrimaryButton
											disabled={currentTab === 2}
											style={{ float: 'right' }}
											className={styles.successBtn}
											onClick={() => {
												const hasPermission = hasEditPermission(currentTab + 1);
												if (hasPermission) {
													setCurrentTab(currentTab + 1);
												} else {
													setErrorModal(
														getTabNameByIndex(currentTab + 1),
														false,
														'Close'
													);
												}
											}}
										>
											Next
										</PrimaryButton>
									</div>
								)}
							</div>
						</div>
					)}

					{isSummaryModalActive && (
						<ApiBatchResponseModal
							onClose={async () => {
								hideSummaryModal();
								closeSaveModal();
								setUpdateSummary(updateSummaryInitialValues);
								setForceHiddenButtons(true);

								await Promise.all(
									channelDetailsValue.map(async (query) => {
										const { refetch } = query;
										await refetch();
									})
								);
								await setRefetch();

								setForceHiddenButtons(false);
							}}
							open={isSummaryModalActive}
							data={updateSummary.filter(
								(item) => item.status === 'Success' || item.status === 'Failed'
							)}
						/>
					)}

					{isExitModeModalOpen && (
						<ConfirmationModal
							open={isExitModeModalOpen}
							heading={exitModeMessage.title}
							body={exitModeMessage.message}
							onClose={cancelExitModal}
							cancelButton="Cancel"
							submitButton="Confirm"
							handleSubmit={() => {
								setIsEditMode(!isEditMode);
								setIsChannelDirty(false);
								setOnActiveTab(!onActiveTab);

								setUpdatedAt(
									convertDate(transformedData?.data.updatedAt, 'ISO') || 'N/A'
								);
								setUpdatedBy(transformedData?.data.updatedBy || 'N/A');
								if (
									form.touchedFields ||
									contractForm.formState.touchedFields ||
									reportsForm.formState.touchedFields
								) {
									primaryForm.reset(primaryData);
									contractForm.reset(contractData);
									reportsForm.reset(reportData);
								}
								hideExitModal();
							}}
						/>
					)}

					{isSaveConfirmation && (
						<ChannelProductModal
							isOpen={isSaveConfirmation}
							heading={'Save Changes'}
							message={
								<>
									Are you sure you want to save the changes made? <br />
									If yes, please input remarks.{' '}
								</>
							}
							onCancelBtnClick={closeSaveModal}
							onClose={closeSaveModal}
							onConfirmBtnClick={saveChanges}
							remarksInfo={{
								text: '*Maximum of 1000 characters only',
								limit: 1000,
							}}
							handleChangeOfRemarks={handleRemarks}
							error={errorRemarks}
							isPrimaryBtnDisabled={isRemarksEmpty}
							cancelBtnLabel="Cancel"
							confirmBtnLabel="Confirm"
							showRemarks
							containerClassName={styles.containerWidth}
						/>
					)}

					{hasPermission && (
						<ErrorModal open={hasPermission} onClose={hidePermissionErrorModal}>
							<ErrorModalBody>{accessDeniedBodyText}</ErrorModalBody>
						</ErrorModal>
					)}
				</>
			)}
		</div>
	) : (
		<>
			<PanelPlaceholder
				title={'No Selected Channel'}
				subtitle={'Please select a channel.'}
			/>
		</>
	);
};

export default connect(
	(state) => ({ selectedChannel: state.channels.selectedChannel }),
	{}
)(ChannelDetails);
