import Grid from 'components/Grid/Grid';
import { SelectFieldWithOthers } from 'components/Inputs/SelectFieldWithOthers/SelectFieldsWithOthers';
import TextField from 'components/Inputs/TextField/TextField';

import Section, { SectionRow } from 'components/Section/Section';
import TimeEntry from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/TimeEntry/TimeEntry';
import { ConfigurationsProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/types';
import { FILE_FORMATS } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/constants';
import { useEffect, useState } from 'react';
import { useFormContext, Path, useFieldArray } from 'react-hook-form';
import { UploadSchedule } from 'utils/lookup';
import { ReportsSchemaType } from '../../ReportsTabSchema';
import { useDispatch, useSelector } from 'react-redux';
import {
	setLoaderMessage,
	toggleLoader,
	setLoader as setLoaderCommon,
} from 'redux/modules/common';
import { useDebounce } from 'react-use';
import { ReducerStateType } from 'redux/modules/reducers';
import {
	checkCoveredTimeField,
	checkTimeStampInterval,
	checkTimeStampIntervalField,
} from '../../utils';
import { LoaderState, setLoader } from 'redux/modules/channelList';
import React from 'react';

const Configurations: React.FC<ConfigurationsProps> = ({
	name,
	title,
	disabled,
}) => {
	const { control, setValue, watch } = useFormContext<ReportsSchemaType>();
	const uploadSchedule = watch(`${name}.schedule`);
	const uploadFrequency = watch(`${name}.uploadFrequency`);

	const {
		fields: timestamps,
		append,
		remove,
	} = useFieldArray({
		control,
		name: `${name}.schedule`,
	});

	const [prevUploadPrequency, setPrevUploadFrequency] =
		useState(uploadFrequency);
	const dispatch = useDispatch();

	const appendEmptyTimeEntry = (size: number) => {
		if (size > 0) {
			append(
				Array(size).fill({
					coveredSchedule: undefined,
					generationTime: undefined,
					generateId: undefined,
					uploadId: undefined,
				} as never)
			);
		}
	};

	useEffect(() => {
		if (uploadSchedule !== 'NEXT_DAY') return;

		setValue(`${name}.uploadFrequency`, 1);
	}, [uploadSchedule, name]);

	useEffect(() => {
		if (
			uploadFrequency === 1 &&
			(timestamps === undefined || (timestamps && timestamps.length === 0))
		) {
			appendEmptyTimeEntry(1);
		}
	}, []);

	useEffect(() => {
		if (
			Number(uploadFrequency) > -1 &&
			Number(uploadFrequency) !== timestamps.length
		) {
			if (Number(uploadFrequency) > Number(prevUploadPrequency)) {
				const size = Number(uploadFrequency) - Number(prevUploadPrequency);
				appendEmptyTimeEntry(size);
			} else if (Number(uploadFrequency) < Number(prevUploadPrequency)) {
				const indices = Array(Number(prevUploadPrequency))
					.fill(0)
					.map((_, idx) => idx)
					.slice(Number(uploadFrequency), Number(prevUploadPrequency));
				remove(indices);
			}
		}
		if (Number(uploadFrequency) <= 0) {
			setPrevUploadFrequency(0);
		} else {
			setPrevUploadFrequency(uploadFrequency);
		}
	}, [uploadFrequency]);

	const form = useFormContext<ReportsSchemaType>();
	const {
		getValues,
		formState: { isValidating, touchedFields, errors },
		clearErrors,
		setError,
	} = form;

	const isLoading = useSelector<ReducerStateType>(
		(state) => state.common.loaderIsOpen
	);

	const loader: LoaderState = useSelector<ReducerStateType, LoaderState>(
		(state) => state.common.loaderIsOpen
	);

	function findDuplicateIndicesExcludingUndefined(arr, propertyName) {
		const valuesMap = new Map(); // Use a Map to store property values and their indices
		const duplicateIndices: any = [];

		arr.forEach((obj, index) => {
			const propertyValue = obj[propertyName];

			if (propertyValue !== undefined) {
				if (valuesMap.has(propertyValue)) {
					// Duplicate found
					const firstIndex = valuesMap.get(propertyValue);
					duplicateIndices.push(firstIndex, index);
				} else {
					valuesMap.set(propertyValue, index);
				}
			}
		});

		return duplicateIndices;
	}

	const revalidateTimeStamps = async (keys) => {
		const { key1, key2, key3 } = keys;

		key3.forEach((timestampKeys) => {
			const allTimeFields = getValues(`${key1}.${key2}.schedule`);

			const timeOfUploadVal = getValues(
				`${key1}.${key2}.schedule[${timestampKeys}].uploadTime`
			);
			const generationTimeVal = getValues(
				`${key1}.${key2}.schedule[${timestampKeys}].generationTime`
			);

			const coveredTimeFromVal = getValues(
				`${key1}.${key2}.schedule[${timestampKeys}].coveredTimeFrom`
			);
			const coveredTimeToVal = getValues(
				`${key1}.${key2}.schedule[${timestampKeys}].coveredTimeTo`
			);

			const isIntervalValid = checkTimeStampIntervalField(
				generationTimeVal,
				timeOfUploadVal
			);

			const hasDuplicateGenerationId = findDuplicateIndicesExcludingUndefined(
				allTimeFields,
				'generationTime'
			);
			const hasDuplicateUploadId = findDuplicateIndicesExcludingUndefined(
				allTimeFields,
				'uploadTime'
			);
			const hasDuplicateGeneration = hasDuplicateGenerationId.includes(
				Number(timestampKeys)
			);

			const hasDuplicateUpload = hasDuplicateUploadId.includes(
				Number(timestampKeys)
			);

			if (isIntervalValid && !hasDuplicateGeneration && !hasDuplicateUpload) {
				clearErrors(
					`${key1}.${key2}.schedule[${timestampKeys}].uploadTime` as Path<ReportsSchemaType>
				);
				clearErrors(
					`${key1}.${key2}.schedule[${timestampKeys}].generationTime` as Path<ReportsSchemaType>
				);
			}

			if (coveredTimeFromVal && coveredTimeToVal) {
				if (checkCoveredTimeField(coveredTimeToVal, coveredTimeFromVal)) {
					setError(
						`${key1}.${key2}.schedule[${timestampKeys}].coveredTimeTo` as Path<ReportsSchemaType>,
						{ message: 'Invalid Time Input.' }
					);
				} else {
					clearErrors(
						`${key1}.${key2}.schedule[${timestampKeys}].coveredTimeTo` as Path<ReportsSchemaType>
					);
				}
			}
		});

		// dispatch(toggleLoader());
	};
	const revalidateCoveredTime = async (keys) => {
		const { key1, key2, key3 } = keys;

		key3.forEach((timestampKeys) => {
			const coveredTimeFromVal = getValues(
				`${key1}.${key2}.schedule[${timestampKeys}].coveredTimeFrom`
			);
			const coveredTimeToVal = getValues(
				`${key1}.${key2}.schedule[${timestampKeys}].coveredTimeTo`
			);

			if (coveredTimeFromVal && coveredTimeToVal) {
				if (checkCoveredTimeField(coveredTimeToVal, coveredTimeFromVal)) {
					setError(
						`${key1}.${key2}.schedule[${timestampKeys}].coveredTimeTo` as Path<ReportsSchemaType>,
						{ message: 'Invalid Time Input.' }
					);
				} else {
					clearErrors(
						`${key1}.${key2}.schedule[${timestampKeys}].coveredTimeTo` as Path<ReportsSchemaType>
					);
				}
			}
		});
	};

	useDebounce(
		() => {
			const errorFieldsString = JSON.stringify(errors);
			const touchedFieldsString = JSON.stringify(touchedFields);
			let timeoutTimer = 500;

			if (!isValidating && !isLoading)
				if (
					Object.keys(errors).length > 0 &&
					(errorFieldsString.includes('uploadTime') ||
						errorFieldsString.includes('generationTime') ||
						errorFieldsString.includes('coveredTimeTo'))
				) {
					const sftpError: any = errors.sftp;
					const smtpError: any = errors.smtp;
					timeoutTimer = Math.max(timestamps.length * 100, 500);

					if (!loader) {
						dispatch(
							setLoaderMessage(
								'Validating Report Timestamp (Generation Time/Time Of Upload)...'
							)
						);

						dispatch(setLoaderCommon(true));
					}

					if (sftpError) {
						const key1 = 'sftp';
						if (sftpError?.csrConfig) {
							const key3 = Object.keys(errors?.[key1]?.['csrConfig'].schedule);

							setTimeout(() => {
								revalidateTimeStamps({ key1: 'sftp', key2: 'csrConfig', key3 });
							}, 500);
						}
						if (sftpError?.uploadConfig) {
							const key3 = Object.keys(
								errors?.[key1]?.['uploadConfig'].schedule
							);

							setTimeout(() => {
								revalidateTimeStamps({
									key1: 'sftp',
									key2: 'uploadConfig',
									key3,
								});
								// dispatch(toggleLoader());
							}, 500);
						}
					}
					if (smtpError) {
						const key1 = 'smtp';
						if (smtpError?.csrConfig) {
							const key3 = Object.keys(errors?.[key1]?.['csrConfig'].schedule);

							setTimeout(() => {
								revalidateTimeStamps({ key1: 'smtp', key2: 'csrConfig', key3 });
								// dispatch(toggleLoader());
							}, 500);
						}
						if (smtpError?.uploadConfig) {
							const key3 = Object.keys(
								errors?.[key1]?.['uploadConfig'].schedule
							);
							// dispatch(
							// 	setLoaderMessage(
							// 		'Validating Report Timestamp (Generation Time/Time Of Upload)...'
							// 	)
							// );

							// dispatch(toggleLoader());

							setTimeout(() => {
								revalidateTimeStamps({
									key1: 'smtp',
									key2: 'uploadConfig',
									key3,
								});
								// dispatch(toggleLoader());
							}, 500);
						}
					}
				}
			if (
				Object.keys(touchedFields).length > 0 &&
				(touchedFieldsString.includes('coveredTimeFrom') ||
					touchedFieldsString.includes('coveredTimeTo'))
			) {
				const sftpTouched = touchedFields.sftp;
				const smtpTouched = touchedFields.smtp;

				if (sftpTouched) {
					const key1 = 'sftp';
					if (sftpTouched?.csrConfig) {
						const key3 = Object.keys(
							touchedFields[key1]['csrConfig'].schedule || {}
						);

						setTimeout(() => {
							revalidateCoveredTime({ key1: 'sftp', key2: 'csrConfig', key3 });
						}, 500);
					}
					if (sftpTouched?.uploadConfig) {
						const key3 = Object.keys(
							touchedFields[key1]['uploadConfig'].schedule || {}
						);

						setTimeout(() => {
							revalidateCoveredTime({
								key1: 'sftp',
								key2: 'uploadConfig',
								key3,
							});
						}, 500);
					}
				}
				if (smtpTouched) {
					const key1 = 'smtp';
					if (smtpTouched?.csrConfig) {
						const key3 = Object.keys(
							touchedFields[key1]['csrConfig'].schedule || {}
						);

						setTimeout(() => {
							revalidateCoveredTime({ key1: 'smtp', key2: 'csrConfig', key3 });
						}, 500);
					}
					if (smtpTouched?.uploadConfig) {
						const key3 = Object.keys(
							touchedFields[key1]['uploadConfig'].schedule || {}
						);

						setTimeout(() => {
							revalidateCoveredTime({
								key1: 'smtp',
								key2: 'uploadConfig',
								key3,
							});
						}, 500);
					}
				}
			}

			setTimeout(() => {
				dispatch(setLoaderCommon(false));
			}, timeoutTimer);
		},
		500,
		[isValidating, uploadFrequency]
	);

	return (
		<>
			<Section title={title}>
				<SectionRow>
					<Grid column size={1} of={3}>
						<SelectFieldWithOthers
							label="Filename Format"
							required
							control={control}
							name={`${name}.filenameFormat`}
							options={FILE_FORMATS.map((i) => ({
								label: i,
								value: i,
							}))}
							onChange={(value) =>
								setValue(`${name}.filenameFormat`, value, {
									shouldTouch: true,
								})
							}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<SelectFieldWithOthers
							label="Upload Schedule"
							required
							control={control}
							name={`${name}.uploadSchedule`}
							options={UploadSchedule}
							onChange={(value) =>
								setValue(`${name}.uploadSchedule`, value, {
									shouldTouch: true,
								})
							}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<TextField
							label="Upload Frequency"
							required
							type="number"
							control={control}
							onKeyPress={(e) => {
								if (['e', '.', '-', '+'].indexOf(e.key) > -1) {
									e.preventDefault();
								}
							}}
							onChange={(value) =>
								setValue(`${name}.uploadFrequency`, value, {
									shouldTouch: true,
								})
							}
							name={`${name}.uploadFrequency`}
							disabled={disabled}
						/>
					</Grid>
				</SectionRow>
				{timestamps.length <= 25 &&
					timestamps.map((_, index) => (
						<>
							{index <= 24 && (
								<TimeEntry
									key={index}
									labelSuffix={`(${index + 1}/${
										timestamps.length <= 25 ? timestamps.length : 25
									})`}
									name={`${name}.schedule[${index}]`}
									disabled={disabled}
								/>
							)}
						</>
					))}
			</Section>
		</>
	);
};

export default React.memo(Configurations);
