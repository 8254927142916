import SelectField from 'components/Inputs/SelectField/SelectField';
import { SelectFieldProps } from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import { useMeralcoPaymentCenterQuery } from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/utils';
import { MERALCO_PAYMENT_CENTER_CODE } from 'containers/ChannelManagement/Branch/BranchForm/constants';
import _ from 'lodash';
import { useMemo } from 'react';

export type MeralcoPaymentCenterSelectProps = {
	selectFieldProps?: SelectFieldProps;
};

const MeralcoPaymentCenterSelect: React.FC<MeralcoPaymentCenterSelectProps> = ({
	selectFieldProps,
}) => {
	const { data, isLoading } = useMeralcoPaymentCenterQuery();
	const options = useMemo(
		() =>
			data?.map(({ id, code, name, description }) => ({
				label: `${code} : ${name} - ${description}`,
				value: id,
			})) || [],

		[data]
	);

	return (
		<SelectField
			label={_.startCase(MERALCO_PAYMENT_CENTER_CODE)}
			isLoading={isLoading}
			options={options}
			{...selectFieldProps}
		/>
	);
};

export default MeralcoPaymentCenterSelect;
