import Grid from 'components/Grid/Grid';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import Section, { SectionRow } from 'components/Section/Section';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';
import {
	IS_AUTO_RENEW_OPTIONS,
	RENEWAL_FREQUENCY_OPTIONS,
} from '../ContractDetailsConstants';
import { ContractDetailsType } from '../ContractDetailsSchemas';
import styles from './styles.module.css';
type Props = {
	title: string;
	disabled: boolean;
	name: string;
};
const TermsAndDuration: React.FC<Props> = ({ disabled, title, name }) => {
	const { control, watch } = useFormContext<ContractDetailsType>();
	const { contractDetails } = watch();

	const { channelTermsDuration } = contractDetails || {};

	return (
		<Section title={title}>
			<SectionRow gutters="small">
				<Grid
					column
					className={cx(styles.componentWidth, 'slds-m-right_medium')}
				>
					<DatePickerField
						label="Execution Date"
						name={`${name}.executionDate`}
						control={control}
						disabled={disabled}
						placeholder="MM/DD/YYYY"
					/>
				</Grid>
				<Grid column>
					<RadioGroup
						label="Is Auto Renew?"
						control={control}
						name={`${name}.autoRenew`}
						disabled={disabled}
						options={IS_AUTO_RENEW_OPTIONS}
					/>
				</Grid>

				{channelTermsDuration?.autoRenew == '0' && (
					<Grid
						column
						className={cx(styles.terminationDateProp, 'slds-m-right_medium')}
					>
						<DatePickerField
							label="Termination Date"
							name={`${name}.terminationDate`}
							control={control}
							disabled={disabled}
							placeholder="MM/DD/YYYY"
							shouldUnregister
						/>
					</Grid>
				)}
				{channelTermsDuration?.autoRenew == '1' && (
					<Grid container column align="end">
						<Grid column className={styles.componentWidth}>
							<SelectField
								label="Renewal Frequency"
								placeholder="Select Renewal Frequency"
								control={control}
								name={`${name}.renewalFrequency`}
								options={RENEWAL_FREQUENCY_OPTIONS}
								disabled={disabled}
								shouldUnregister
								required
							/>
						</Grid>
						{channelTermsDuration?.renewalFrequency === 'EVERY_N_YEARS' && (
							<Grid column className={styles.componentWidth}>
								<TextField
									required
									label="Frequency Interval"
									placeholder={'0'}
									name={`${name}.frequencyInterval`}
									control={control}
									disabled={disabled}
									fixedTextRight="Years"
									shouldUnregister
								/>
							</Grid>
						)}
					</Grid>
				)}
			</SectionRow>
		</Section>
	);
};

export default TermsAndDuration;
