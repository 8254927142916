import cx from 'classnames';
import styles from './styles.module.css';
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import ChannelApprovalTab from './Tabs/Channel/ChannelApprovalTab';
import ServiceFeeApprovalTab from './Tabs/ServiceFeeShare/ServiceFeeApprovalTab';
import { FormProvider, useForm } from 'react-hook-form';
import { APPROVAL_LIST_FILTER } from './constants';
import { ChannelFilter } from './types';
import { useState } from 'react';

const ChannelApproval: React.FC = () => {
	const filterForm = useForm<ChannelFilter>({
		defaultValues: {
			channelTabFilter: APPROVAL_LIST_FILTER,
			serviceFeeTabFilter: APPROVAL_LIST_FILTER,
		},
	});
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const approvalTabs = {
		tabs: [
			{
				title: 'Channel',
				component:
					selectedIndex === 0 ? (
						<FormProvider {...filterForm}>
							<ChannelApprovalTab
								name="channelTabFilter"
								selectedIndex={selectedIndex}
							/>
						</FormProvider>
					) : (
						<></>
					),
				scope: { permissions: ['list.approvals'] },
			},
			{
				title: 'Service Fee Share',
				component:
					selectedIndex === 1 ? (
						<FormProvider {...filterForm}>
							<ServiceFeeApprovalTab
								name="serviceFeeTabFilter"
								selectedIndex={selectedIndex}
							/>
						</FormProvider>
					) : (
						<></>
					),
				scope: { permissions: ['fees.approval.list'] },
			},
		],
		defaultIndex: selectedIndex,
	};

	return (
		<div className={cx('slds-card', styles.paperContainer)}>
			<div className="slds-grid sls-grid_align-spread">
				<div className={cx('slds-col', styles.title)}>Approval</div>
			</div>
			<div>
				<PrimaryTab
					{...approvalTabs}
					onSelect={(val) => {
						setSelectedIndex(val);
					}}
					module="channels"
				/>
			</div>
			{/* <ChannelModal /> */}
		</div>
	);
};

export default ChannelApproval;
