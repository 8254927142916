import React, { useState, useEffect } from 'react';
import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import SelectField from 'components/Inputs/SelectField/SelectField';
import styles from 'containers/ChannelManagement/Terminal/style.module.css';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import { useFormContext, Controller } from 'react-hook-form';
import {
	MachineLocation,
	ChannelTransactionType,
	ChannelTypePhysical,
	ChannelTypeDigital,
	PosType,
	SettlementSetup,
	MeralcoPaymentCenterCode,
} from 'utils/lookup';
import { useList } from 'containers/ChannelManagement/List/hooks';

type Props = {
	mode?: string;
	disabled?: boolean;
	setCurrentSection?: any;
	setPrevSection?: any;
	updateAutoSave?: any;
};

const TerminalInformation: React.FC<Props> = ({
	mode,
	disabled,
	setCurrentSection,
	setPrevSection,
	updateAutoSave,
}) => {
	const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(false);
	const { channelTypeFilter, MeralcoCodeOptions } = useList();

	const { control, watch } = useFormContext();

	const { channelTransactionType, remarks } = watch();

	useEffect(() => {
		if (remarks && remarks.length > 1000) {
			setIsRemarksEmpty(true);
		} else {
			setIsRemarksEmpty(false);
		}
	}, [remarks]);

	const settlementSetupField = (
		<SelectField
			label="Settlement Setup"
			name="settlementSetup"
			control={control}
			options={SettlementSetup}
			disabled
		/>
	);

	const MeralcoPaymentCenterCodeField = (
		<SelectField
			required
			label="Meralco Payment Center Code"
			name="meralcoPaymentCenterId"
			control={control}
			options={MeralcoCodeOptions}
			disabled={disabled}
		/>
	);

	return (
		<Section
			onClick={() => {
				if (mode === 'ADD') {
					setCurrentSection((prevState) => {
						setPrevSection(prevState);
						setCurrentSection('TerminalInformation');
					});
					updateAutoSave();
				}
			}}
			title="Terminal Information"
		>
			<SectionRow>
				<Grid column size={2} of={3}>
					<TextField
						label="Channel Account Name"
						name="channelAccountName"
						control={control}
						readOnly
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Channel Account Code"
						name={'channelAccountCode'}
						control={control}
						readOnly
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<TextField
						label="Branch Name"
						name={'branchName'}
						control={control}
						readOnly
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Branch Code"
						name={'branchCode'}
						control={control}
						readOnly
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						required
						label="TPAID"
						name="tpaId"
						control={control}
						readOnly
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						label="Machine Location"
						name="machineLocation"
						options={MachineLocation}
						control={control}
						disabled
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<div className={styles.customLabel}>SPM Number</div>
					<div className={styles.spm}>
						<div className={styles.spmText}>SPM-&nbsp;&nbsp;</div>
						<div className={styles.spmTextField}>
							<TextField
								label=""
								name="spmNumber"
								placeholder="SPM Number"
								control={control}
								disabled={disabled}
							/>
						</div>
					</div>
				</Grid>
				<Grid column size={1} of={3}>
					<DatePickerField
						required
						label="Activation Date"
						name="activationDate"
						control={control}
						placeholder="MM/DD/YYYY"
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						label="Channel Transaction Type"
						name="channelTransactionType"
						control={control}
						options={ChannelTransactionType}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="Channel Type"
						name="channelType"
						control={control}
						options={
							channelTransactionType === 'DIGITAL'
								? ChannelTypeDigital.map((digital) => {
										const match: any = channelTypeFilter.find(
											(channelType) => channelType.label === digital.label
										);
										return {
											label: match?.label,
											value: match?.value,
										};
								  })
								: ChannelTypePhysical.map((physical) => {
										const match: any = channelTypeFilter.find(
											(channelType) => channelType.label === physical.label
										);
										return {
											label: match?.label,
											value: match?.value,
										};
								  })
						}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					{channelTransactionType === 'PHYSICAL' ? (
						<SelectField
							required={channelTransactionType === 'PHYSICAL'}
							label="POS Type"
							name="posType"
							control={control}
							options={PosType}
							disabled={disabled}
						/>
					) : (
						settlementSetupField
					)}
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					{channelTransactionType === 'PHYSICAL'
						? settlementSetupField
						: MeralcoPaymentCenterCodeField}
				</Grid>
				<Grid column size={1} of={3}>
					{channelTransactionType === 'PHYSICAL'
						? MeralcoPaymentCenterCodeField
						: null}
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={3} of={3}>
					<div className={styles.customLabel}>Remarks</div>
					<div className="slds-form-element__control">
						<Controller
							name="remarks"
							control={control}
							render={({ field }) => (
								<textarea
									className={'slds-textarea' + ' ' + styles.remarks}
									disabled={disabled}
									placeholder="Remarks"
									{...field}
								/>
							)}
						/>
						{isRemarksEmpty && (
							<div
								className={
									'slds-form-element__help' + ' ' + styles.remarksError
								}
							>
								<div>You can only input a max of 1000 characters.</div>
							</div>
						)}
					</div>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default TerminalInformation;
