import { FixMeLater } from 'types';
import {
	TChannelContactDetails,
	TChannelOfficerFields,
} from '../Channel/ChannelDetails/types';
import { decrypt } from 'utils/codeGenerator';
import moment from 'moment';

function formatContact<T>(data: T[], field: string): T[] {
	return (
		data &&
		data.map((item: any) => {
			if (item && typeof item.emailAddress === 'string') {
				item = {
					...item,
					emailAddress: item.emailAddress
						.split(',')
						.map((email) => email.trim())
						.filter((v) => v !== ''),
				};
			}
			if (item && typeof item.telNo === 'string') {
				item = {
					...item,
					telNo: item.telNo
						.split(',')
						.map((tel) => tel.trim())
						.filter((v) => v !== ''),
				};
			}
			if (item && typeof item.mobileNo === 'string') {
				item = {
					...item,
					mobileNo: item.mobileNo
						.split(',')
						.map((mobile) => mobile.trim())
						.filter((v) => v !== ''),
				};
			}
			return item;
		})
	);
}

const channelOfficerDefault = {
	emailAddres: [],
	mobileNo: [],
	name: '',
	telNo: [],
};

const channelContactDetailsDefault = [
	{
		name: '',
		telNo: [],
		position: '',
		department: '',
		emailAddress: [],
		mobileNo: [],
	},
];

const channelAddressDefault = [
	{
		locationBase: 'LOCAL',
		countryId: 175,
		area: '',
		regionId: null,
		provinceId: null,
		cityId: null,
		barangayId: null,
		buildingName: '',
		street: '',
		zipCodeId: null,
		id: 0,
	},
];

export const transformResponseToChannelDetails = (
	data: FixMeLater,
	meta?: FixMeLater
): FixMeLater => {
	const contactDetails = formatContact<TChannelContactDetails>(
		data?.channelContactDetails || [],
		'contactDetails'
	);
	const officer = formatContact<TChannelOfficerFields>(
		data?.channelOfficer ? [data.channelOfficer] : [],
		'officer'
	);

	const transformAddress = (address) => ({
		area: address?.area || null,
		barangayId: address?.barangayId || 0,
		buildingName: address?.buildingName || null,
		cityId: address?.cityId || null,
		countryId: address?.countryId || null,
		locationBase: address?.locationBase || null,
		provinceId: address?.provinceId || null,
		regionId: address?.regionId || null,
		street: address?.street || null,
		zipCodeId: address?.zipCodeId || null,
		id: address?.id || 0,
	});

	const channelAddress = data?.channelAddresses.map(transformAddress);

	const transformedData: FixMeLater = {
		data: {
			...(data && {
				updatedAt: meta?.lastUpdated || '',
				createdAt: data?.createdAt || '',
				createdBy: data?.channelPrimaryInfo.createdBy || '',
				updatedBy: meta?.updatedBy || '',
				username: data.channelPrimaryInfo.updatedBy || '',
				id: data.id || 0,
				channelPrimaryInfo: {
					businessGroup: data.channelPrimaryInfo.businessGroup || '',
					otherBusinessType: data.channelPrimaryInfo.otherBusinessType || null,
					channelAccountName: data.channelPrimaryInfo.name || '',
					channelType: data.channelPrimaryInfo.channelType || '',
					settlementSetup: data.channelPrimaryInfo.settlementSetup || '',
					channelTransactionType:
						data.channelPrimaryInfo.channelTransactionType || '',
					posType: data.channelPrimaryInfo.posType || null,
					channelContactDetails:
						contactDetails.length > 0
							? contactDetails
							: channelContactDetailsDefault,
					inCfs: String(data.channelPrimaryInfo.inCfs) || 0,
					tin: data.channelPrimaryInfo.tin || null,
					logo: {
						url: data.channelPrimaryInfo.logo,
						objectKey: null,
					} || {
						objectKey: null,
					},
					channelOfficer: officer[0] || channelOfficerDefault,
					partnershipType: data.channelPrimaryInfo.partnershipType || '',
					businessTypeId: data.channelPrimaryInfo.businessTypeId || 0,
					businessType: data.channelPrimaryInfo.businessTypeId || '',
					channelAccountCode: data.channelPrimaryInfo.tpaAccount || '',
					channelAddresses:
						data.channelAddresses.length > 0
							? channelAddress
							: channelAddressDefault,
				},
			}),
			channelReports: data ? transformChannelReports(data) : null,
			channelContractDetails: data ? transformContractDetails(data) : null,
		},
	};

	return transformedData;
};

function transformContractDetails(originalData) {
	const {
		bondEndDate = null,
		depositConsolidation = null,
		bondAmount = null,
		depositMode = null,
		otherBusinessLines = null,
		settlementAgreement = null,
		thresholdAmount = null,
		accreditationFee = null,
		vatRate = null,
		bondStartDate = null,
		annualFee = null,
		vatType = null,
		bondType = null,
	} = originalData?.channelContractDetails || {};

	const {
		executionDate = null,
		terminationDate = null,
		autoRenew = 0,
		renewalFrequency = null,
		frequencyInterval = 0,
	} = originalData?.channelTermsDuration || {};

	const {
		signedNda = null,
		auditFinancialStmt = null,
		dataPrivacyPat = null,
		nonDisclosure = null,
		secArticleIncorp = null,
		birCertReg = null,
		dataPrivacyPia = null,
		genInfoSheets = null,
		incomeTaxReturn = null,
		govtIds = null,
		secRegCert = null,
		businessPermit = null,
		certAuthority = null,
		secondAuditFinStmt = null,
		cbciAmlQuestion = null,
		companyProfile = null,
		thirdPartySecChecklist = null,
		corpSecCert = null,
	} = originalData?.channelAccreditation || {};

	const defaultCollection = {
		acctType: null,
		bankId: 0,
		acctNo: null,
		acctName: null,
		bankBranch: null,
		id: 0,
	};

	const transformCollection = (collection) => ({
		acctType: collection?.acctType || null,
		bankId: collection?.bankId || 0,
		acctNo: collection?.acctNo || null,
		acctName: collection?.acctName || null,
		bankBranch: collection?.bankBranch || null,
		id: collection?.id || 0,
	});

	const channelCheckCollection =
		originalData?.channelCheckCollection?.map(transformCollection);

	const channelCashCollection =
		originalData?.channelCashCollection?.map(transformCollection);

	return {
		contractDetails: {
			id: originalData?.channelContractDetails?.id || 0, // what id should be placed here? the api_reponse.data.id or api_response.data.channelContractDetails.id?
			bondEndDate: bondEndDate
				? moment(bondEndDate).format('MM/DD/YYYY')
				: null,
			depositConsolidation,
			bondAmount: bondAmount || '',
			depositMode,
			otherBusinessLines,
			settlementAgreement,
			thresholdAmount: thresholdAmount || '',
			accreditationFee,
			vatRate,
			bondStartDate: bondStartDate
				? moment(bondStartDate).format('MM/DD/YYYY')
				: null,
			channelCheckCollection:
				channelCheckCollection && channelCheckCollection.length > 0
					? channelCheckCollection
					: [defaultCollection],
			annualFee,
			vatType,
			channelTermsDuration: {
				executionDate: executionDate
					? moment(executionDate).format('MM/DD/YYYY')
					: null,
				terminationDate: terminationDate
					? moment(terminationDate).format('MM/DD/YYYY')
					: null,
				autoRenew: autoRenew ? String(autoRenew) : null,
				renewalFrequency: renewalFrequency || null,
				frequencyInterval: frequencyInterval ? String(frequencyInterval) : null,
				id: originalData?.channelTermsDuration?.id || null,
			},
			channelAccreditation: {
				signedNda,
				auditFinancialStmt,
				dataPrivacyPat,
				nonDisclosure,
				secArticleIncorp,
				birCertReg,
				dataPrivacyPia,
				genInfoSheets,
				incomeTaxReturn,
				govtIds,
				secRegCert,
				businessPermit,
				certAuthority,
				secondAuditFinStmt,
				cbciAmlQuestion,
				companyProfile,
				thirdPartySecChecklist,
				corpSecCert,
				id: originalData?.channelAccreditation?.id || 0,
			},
			bondType,
			channelCashCollection:
				channelCashCollection && channelCashCollection.length > 0
					? channelCashCollection
					: [defaultCollection],
		},
	};
}

function transformChannelReports(originalData) {
	const fetchReport = (type) =>
		originalData?.channelReports?.find((r) => r?.transmissionType === type) ||
		{};

	if (
		fetchReport('SFTP')?.config === null &&
		fetchReport('SMTP')?.config === null
	) {
		return;
	}
	const fetchReportSMTP = fetchReport('SMTP');
	const fetchReportSFTP = fetchReport('SFTP');
	const decryptedConfig = (report) =>
		decrypt(report?.config, process.env.REACT_APP_APP_KEY, true, true);

	const smtpValue = decryptedConfig(fetchReportSMTP);
	const sftpValue = decryptedConfig(fetchReportSFTP);

	const csrDefaults = {
		filenameFormat: '',
		uploadFrequency: '',
		uploadSchedule: '',
		schedule: [],
		csrColumns: [],
	};

	const convertTime = (time: string) => {
		if (typeof time !== 'string') {
			console.error('Invalid input: Expected a string');
			return;
		}
		if (!/^(0?\d|1\d|2[0-3]):[0-5]\d:[0-5]\d$/.test(time)) {
			console.error('Invalid time format: Expected format HH:mm:ss', time);
			return;
		}
		return time.slice(0, -3).padStart(5, '0');
	};

	const getCsrConfig = (type) => {
		const csr = originalData?.channelReports?.find(
			(detail) => detail?.transmissionType === type
		);
		if (csr) {
			const schedule = csr.channelReportEvents
				.filter((e) => e?.eventType === 'UPLOAD')
				.map((e, index) => {
					const generateValue = csr?.channelReportEvents.filter(
						(v) => v?.eventType === 'GENERATE'
					)[index];
					return {
						coveredSchedule: e.coveredSchedule || '',
						coveredTimeTo:
							e.coveredTimeTo && e.coveredTimeTo !== ''
								? convertTime(e.coveredTimeTo)
								: '' || '',
						generationTime:
							generateValue?.eventTime && generateValue?.eventTime !== ''
								? convertTime(generateValue?.eventTime)
								: '' || '',
						coveredTimeFrom:
							e.coveredTimeFrom && e.coveredTimeFrom !== ''
								? convertTime(e.coveredTimeFrom)
								: '' || '',
						uploadTime:
							e.eventTime && e.eventTime !== ''
								? convertTime(e.eventTime)
								: '' || '',
						uploadId: e?.id || 0,
						generateId: generateValue?.id || 0,
					};
				});

			return {
				filenameFormat: csr?.filenameFormat || '',
				uploadFrequency: csr?.uploadFrequency || '',
				uploadSchedule: csr?.uploadSchedule || '',
				id: csr?.id || '',
				schedule: schedule,
				csrColumns:
					csr?.channelReportColumns?.map((col) => col.reportFieldId) || [],
			};
		}

		return csrDefaults;
	};

	const getTransformedSftp = (sftp) => {
		const {
			path = '',
			password = '',
			port = '',
			host = '',
			username = '',
		} = sftp || {};
		return {
			path,
			password,
			port,
			host,
			username,
			id: fetchReportSFTP?.id || 0,
			csrConfig: getCsrConfig('SFTP'),
			uploadConfig: getCsrConfig('SFTP_UC'),
		};
	};

	const getTransformedSmtp = (smtp) => {
		const { sender = '', receivers = [] } = smtp || {};

		return {
			sender,
			receivers,
			id: fetchReportSMTP?.id || 0,
			csrConfig: getCsrConfig('SMTP'),
			uploadConfig: getCsrConfig('SMTP_UC'),
		};
	};

	return {
		sftp: getTransformedSftp(sftpValue),
		smtp: getTransformedSmtp(smtpValue),
	};
}
