import React, { useState, useEffect, useMemo, useRef } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';
import _, { isEmpty } from 'lodash';
import { useForm, Control, Controller } from 'react-hook-form';
import { setLoader } from 'redux/modules/channelList';
import client from 'helpers/ApiClient';
import { IconSettings, Button } from '@salesforce/design-system-react';
import PrimaryCard from 'components/Cards/PrimaryCard/PrimaryCard';
import RenderByType from 'components/common/RenderByType';
import axios from 'axios';
import { useQuery } from 'react-query';
import AccessDeniedModal from 'containers/ChannelManagement/Permission/AccessDeniedModal';

import OtpModal, {
	OtpModalBody,
	OtpText,
	OtpSubText,
	OtpModalActions,
	ResendOtpButton,
	ResendOtpViaEmailButton,
	ResendOtpViaSmsButton,
} from 'components/Modal/OtpModal';

import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';

import SuccessModal, {
	SuccessModalBody,
	SuccessText,
	SuccessModalActions,
} from 'components/Modal/SuccessModal';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import OtpInput from 'components/Modal/OtpInput';
import Grid from 'components/Grid/Grid';
import BasicInformation, {
	BasicInformationFields,
} from 'components/BillerForm/Tabs/PrimaryInformation/sections/BasicInformation/BasicInformation';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';

import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Credentials/ChannelCredentials.module.css';
import ChannelCredentialsTable from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Credentials/ChannelCredentialsTable';
import HTTP from 'helpers/ApiClient';
import cx from 'classnames';
import {
	useGetChannelCredentials,
	useGenerateClientCredentials,
	useSendClientCredentials,
	useSendOtpRequest,
	useVerifyOtpRequest,
} from 'containers/ChannelManagement/Channel/ChannelIntegration/ChannelIntegrationQuery';
import { usePreviousDistinct } from 'react-use';
import { ReactComponent as Icon } from 'assets/icons/ic-clock-transparent.svg';
import stylesSuccess from 'components/Modal/SuccessChannelModal.module.css';

interface ICell {
	value: any;
	row: any;
	cell?: any;
}

export type ChannelCredentialsFormData = {
	basicInformation?: BasicInformationFields;
};
type Props = {
	initialValues: ChannelCredentialsFormData;
	disabled?: boolean;
	onSubmit?: (values: any) => void;
	name?: string;
	onBlur?: (e) => void;
	onKeyPress?: (e) => void;
	onFocus?: () => void;
	onChange?: (value: string) => void;
	shouldUnregister?: boolean;
	defaultValue?: any;
};

type ApprovalFilterFormData = {
	filter: {
		createdAt?: string;
		updatedAt?: string;
		status?: string;
		partnerName?: string;
	};
};

const ChannelCredentials: React.FC<Props & { control?: Control<any> }> = ({
	initialValues,
	disabled,
	onSubmit,
	control,
	name,
	defaultValue,
	shouldUnregister,
	...rest
}) => {
	const [allChannelData, setChannelData] = useState<{ data: any[] }>({
		data: [],
	});

	const { handleSubmit } = useForm<ApprovalFilterFormData>({
		mode: 'all',
	});

	const handleFilterSubmit = (values, isSearch = false) => {
		const createdAt = values['createdAt'],
			updatedAt = values['updatedAt'];
	};

	interface ListSelection {
		id: number;
		name: string;
		logo: string;
		status: string;
		tpaAccount: string;
	}

	const selectedChannel = useSelector<ReducerStateType, ListSelection>(
		(state) => state.channels.selectedChannel
	);

	const activeTab: any = useSelector<ReducerStateType>(
		(state) => state.channels.activeTab
	);

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);
	const hasChannelUserPermission = useHasUserPermission('channels');

	const { hasRegenerateClientCredentialsPermission } = useMemo(() => {
		return {
			hasRegenerateClientCredentialsPermission: hasChannelUserPermission(
				'generate.client-credentials'
			),
		};
	}, [hasChannelUserPermission]);

	const { hasSendClientCredentialsChannelPermission } = useMemo(() => {
		return {
			hasSendClientCredentialsChannelPermission: hasChannelUserPermission(
				'send.client-credentials'
			),
		};
	}, [hasChannelUserPermission]);

	const ClockIcon: React.FC = () => <Icon className={styles.clockIcon} />;

	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorTitle, setErrorTitle] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [generateId, setGenerateId] = useState(0);
	const [successMessage, setSuccessMessage] = useState('');
	const [otpUserMessage, setOtpUserMessage] = useState('');
	const visibilityRef = useRef(true);
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [otpModalOnClick, setOtpModalOnClick] = useState({
		action: () => {
			showOtpModal();
		},
	});
	const [otp, setOTP] = useState('');
	const [otpRequest, setOtpRequest] = useState(['', '']);
	const [otpNumber, setOtpNumber] = useState('');
	const [otpSendVia, setOtpSendVia] = useState('sms');
	const [otpErrorMessage, setOtpErrorMessage] = useState('');
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [credentialId, setCredentialId] = useState();
	const [integrationQuery, setIntegrationQuery] = useState('');
	const [otpButtonText, setOtpButtonText] = useState('');
	const [otpButton, setOtpButton] = useState(true);
	const [otpErrorDesign, setOtpErrorDesign] = useState(false);
	const [userMobile, setUserMobile] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [regenButtonText, setRegenButtonText] = useState(
		'Regenerate Client Credentials'
	);
	const [regenButton, setRegenButton] = useState(false);
	const dispatch = useDispatch();
	const [tableFilter, setTableFilter] = useState({
		limit: 25,
		page: 1,
	});
	const [countdownTime, setCountdownTime] = useState(0);
	const [countdownTimeSms, setCountdownTimeSms] = useState(0);
	const [expirationTime, setExpirationTime] = useState(0);
	const [expirationTimeSms, setExpirationTimeSms] = useState(0);
	const [maxLimitTimer, setMaxLimitTimer] = useState(0);
	const [maxLimitTimerMinute, setMaxLimitTimerMinute] = useState('');

	const selectedId = () => {
		let id: number | undefined = undefined;
		id = selectedChannel?.id;
		return id;
	};

	const handleOTPChange = (val) => {
		// Handle the OTP change
		const numValue = val.replace(/\D/g, '');

		if (numValue.length <= 6) {
			setOTP(numValue);
			if (numValue.length >= 6) {
				setOtpButton(false);
			} else {
				setOtpButton(true);
			}
		}
	};

	const handleKeyPress = (e) => {
		const keyCode = e.keyCode || e.which;
		if (
			(keyCode < 48 || keyCode > 57) && // Numbers 0-9
			keyCode !== 8 && // Backspace
			keyCode !== 37 && // Left arrow
			keyCode !== 39 // Right arrow
		) {
			e.preventDefault();
		}
	};

	const renderInput = (inputProps, index, isBlurred) => {
		return (
			<input
				{...inputProps}
				type="text"
				value={inputProps.value}
				onKeyPress={handleKeyPress}
				onBlur={() => {
					if (isBlurred) {
						inputProps.onBlur();
					}
				}}
			/>
		);
	};

	function handleModalClose() {
		setOtpErrorDesign(false);
		setOtpErrorMessage('');
		hideOtpModal();
	}

	function handleSuccessModalClose() {
		refetchCredentialsList();
		hideSuccessModal();
	}

	const {
		value: isOtpModalShown,
		valueOn: showOtpModal,
		valueOff: hideOtpModal,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: hasPermission,
		valueOn: showPermissionErrorModal,
		valueOff: hidePermissionErrorModal,
	} = useToggle();

	const panelPlaceholderProps = {
		title: 'No Selected Channel',
		subtitle: 'Please select a channel.',
	};

	const [count, setCount] = useState(0);

	const errorMessageDuringLoading = 'A problem occurred with loading the data';

	const {
		data: credentialListData,
		isLoading: isQueryLoading,
		isSuccess: isQuerySuccess,
		error: credsError,
		refetch: refetchCredentialsList,
	} = useGetChannelCredentials(selectedId(), tableFilter, activeTab);
	const {
		data: generateData,
		isLoading: isGenerateQueryLoading,
		isSuccess: isGenerateQuerySuccess,
		error: generateError,
		refetch: refetchGenerate,
	} = useGenerateClientCredentials(selectedId(), integrationQuery);
	const {
		data: sendData,
		isLoading: isSendQueryLoading,
		error: sendError,
		refetch: refetchSend,
	} = useSendClientCredentials(selectedId(), credentialId, integrationQuery);
	const {
		data: otpData,
		isLoading: isOtpQueryLoading,
		isSuccess: isOtpQuerySuccess,
		error: otpError,
		refetch: refetchOtp,
	} = useSendOtpRequest(otpRequest[0], otpRequest[1], activeTab);
	const {
		data: otpVerify,
		isLoading: isOtpVerifyLoading,
		isSuccess: isOtpVerifySuccess,
		error: otpVerifyError,
		refetch: refetchOtpVerify,
	} = useVerifyOtpRequest(otpNumber, activeTab);

	const prevIsQueryLoading = usePreviousDistinct(isQueryLoading);

	useEffect(() => {
		if (otpErrorMessage && otpErrorMessage == 'Successfully verified') {
			setOtpErrorDesign(false);
		} else if (
			otpErrorMessage &&
			otpErrorMessage ==
				'You’ve already reached the maximum number of allowed attempts. Please try again after 15 minutes.'
		) {
			showErrorMessage(
				'You’ve already reached the maximum\nnumber of allowed attempts. Please\ntry again after 15 minutes.'
			);
			setErrorTitle('Too Many Attempt!');
			setOTP('');
			setMaxLimitTimer(900);
			setRegenButtonText('Regenerating');
			setRegenButton(true);
			setOtpErrorDesign(true);
		} else if (otpErrorMessage && otpErrorMessage != 'Successfully verified') {
			setOTP('');
			setOtpErrorDesign(true);
		} else {
			setOTP('');
			setOtpErrorDesign(false);
		}
	}, [otpErrorMessage]);

	useEffect(() => {
		const isLoading = isQuerySuccess;
		let message = 'Loading Integration...';

		if (prevIsQueryLoading !== isQueryLoading && isQueryLoading) {
			// setOTP('');
			// setOtpErrorDesign(false);
			// setRegenButton(false);
			// setRegenButtonText('Regenerate Client Credentials');
			// setOtpErrorMessage('');
			// setCountdownTimeSms(0);
			// setExpirationTimeSms(0);
			// setCountdownTime(0);
			// setExpirationTime(0);
			// setMaxLimitTimer(0);
			message = 'Please wait while Integrations are being loaded.';
		} else {
			// setOTP('');
			// setOtpErrorDesign(false);
			// setRegenButton(false);
			// setRegenButtonText('Regenerate Client Credentials');
			// setOtpErrorMessage('');
			// setCountdownTimeSms(0);
			// setExpirationTimeSms(0);
			// setCountdownTime(0);
			// setExpirationTime(0);
			// setMaxLimitTimer(0);
			message =
				"Please wait while the selected Integration's entries are being loaded.";
		}

		dispatch(setLoader({ isLoading, message }));
	}, [dispatch, isQueryLoading, prevIsQueryLoading, isQuerySuccess]);

	useEffect(() => {
		if (isOtpVerifyLoading) {
			setOtpButton(true);
			setOtpButtonText('Verifying');
		} else {
			setOtpButton(true);
			setOtpButtonText('Submit');
		}
	}, [isOtpVerifyLoading]);

	useEffect(() => {
		if (isOtpQueryLoading) {
			setRegenButton(true);
			setRegenButtonText('Regenerating');
		} else {
			setRegenButton(false);
			setRegenButtonText('Regenerate Client Credentials');
		}
	}, [isOtpQueryLoading]);

	useEffect(() => {
		const isLoading = isQueryLoading;
		const message =
			"Please wait while the selected Integration's entries are being loaded.";

		dispatch(setLoader({ isLoading, message }));
	}, [isQuerySuccess]);

	useEffect(() => {
		refetchCredentialsList();
	}, [tableFilter, selectedId()]);

	useEffect(() => {
		if (!isGenerateQueryLoading && isGenerateQuerySuccess) {
			console.log('refetchCredentialsList after isGenerateQuerySuccess');
			refetchCredentialsList();
		}
	}, [isGenerateQueryLoading, isGenerateQuerySuccess]);

	useEffect(() => {
		refetchGenerate();
	}, [refetchGenerate, generateId]);

	useEffect(() => {
		refetchGenerate();
	}, [refetchSend, credentialId]);

	useEffect(() => {
		if (otpSendVia === 'sms') {
			setOtpUserMessage(
				'Enter 6-digit code sent to the number ending ' + userMobile
			);
		} else {
			setOtpUserMessage('Enter 6-digit code sent to the email ' + userEmail);
		}
	}, [otpSendVia]);

	useEffect(() => {
		if (
			credentialListData === undefined &&
			isQuerySuccess &&
			activeTab === 'ChannelIntegration'
		) {
			showErrorMessage('There is some error with your request.');
		}
	}, [credentialListData, isQuerySuccess]);

	useEffect(() => {
		if (selectedId() === undefined && !isGenerateQueryLoading) {
			showErrorMessage('There is some error with your request.');
		} else if (
			otpErrorMessage.toLowerCase().includes('successfully verified') &&
			!isGenerateQueryLoading &&
			isOtpVerifySuccess
		) {
			showSuccessMessage(generateData.data + '.');
			refetchCredentialsList();
		}
		// else {
		// 	if (generateData) {
		// 		showSuccessMessage(generateData.data + '.');
		// 	}
		// }
	}, [generateData, isGenerateQueryLoading, otpErrorMessage]);

	useEffect(() => {
		if (selectedId() === undefined && !isSendQueryLoading) {
			showErrorMessage('There is some error with your request.');
		} else {
			if (sendData) {
				showSuccessMessage('Client Credentials was sent to email.');
				//showSuccessMessage(sendData.data);
			}
		}
	}, [sendData, isSendQueryLoading]);

	useEffect(() => {
		if (
			otpData &&
			otpData?.data?.exception &&
			otpData?.message &&
			isOtpQuerySuccess
		) {
			showErrorMessage(otpData.message);
		} else if (otpData && otpData?.data?.exception && otpData?.data?.message) {
			showErrorMessage(otpData?.data?.message);
		}
		if (otpData && otpData?.data?.mobileNumber) {
			const lastFourDigits = otpData?.data?.mobileNumber.slice(-4);
			setOtpSendVia('sms');
			setUserMobile(lastFourDigits);
			setOtpUserMessage(
				'Enter 6-digit code sent to the number ending ' + lastFourDigits
			);
			// if (countdownTimeSms === 0) {
			// 	setCountdownTimeSms(30);
			// }
			if (expirationTimeSms === 0) {
				setExpirationTimeSms(480);
			}

			setRegenButton(false);
			setRegenButtonText('Regenerate Client Credentials');
			if (otpRequest[0] != 'initial') {
				// if (otpData?.data?.otpResendCount) {
				// 	if (otpData?.data?.otpResendCount % 3 === 0) {
				// 		setCountdownTimeSms(120);
				// 		setExpirationTimeSms(480);
				// 	} else {
				// 		setCountdownTimeSms(30);
				// 		setExpirationTimeSms(480);
				// 	}
				// }
				if (otpData?.data?.otpResendCountSms) {
					if (otpData?.data?.otpResendCountSms % 3 === 0) {
						setCountdownTimeSms(120);
						setExpirationTimeSms(480);
					} else {
						setCountdownTimeSms(30);
						setExpirationTimeSms(480);
					}
				}
			}
			otpModalOnClick.action();
		}
		if (otpData && otpData?.data?.email) {
			const emailParts = otpData?.data?.email.split('@');
			const firstThreeLetters = emailParts[0].slice(0, 3);
			const maskedEmail = `${firstThreeLetters}***@${emailParts[1]}`;
			setOtpSendVia('email');
			setUserEmail(maskedEmail);
			setOtpUserMessage('Enter 6-digit code sent to the email ' + maskedEmail);

			if (expirationTime === 0) {
				setExpirationTime(300);
			}

			setRegenButton(false);
			setRegenButtonText('Regenerate Client Credentials');
			if (otpRequest[0] != 'initial') {
				// if (otpData?.data?.otpResendCount) {
				// 	if (otpData?.data?.otpResendCount % 3 === 0) {
				// 		setCountdownTime(120);
				// 		setExpirationTime(300);
				// 	} else {
				// 		setCountdownTime(30);
				// 		setExpirationTime(300);
				// 	}
				// }
				if (otpData?.data?.otpResendCountEmail) {
					if (otpData?.data?.otpResendCountEmail % 3 === 0) {
						setCountdownTime(120);
						setExpirationTime(300);
					} else {
						setCountdownTime(30);
						setExpirationTime(300);
					}
				}
			}
			otpModalOnClick.action();
		}
	}, [otpData, isOtpQuerySuccess]);

	useEffect(() => {
		if (otpVerify === null && isOtpVerifySuccess) {
			showErrorMessage('There is some error with your request.');
			return;
		}

		if (
			otpVerify &&
			otpVerify?.response?.status &&
			otpVerify?.response?.status.toLowerCase().includes('verified')
		) {
			setIntegrationQuery('generate');
			refetchGenerate();
			refetchCredentialsList();
			//showSuccessMessage(otpVerify.message);  // OTP Verify Success message
		}

		if (otpVerify && isOtpVerifySuccess) {
			if (otpVerify && otpVerify?.exception) {
				setOtpErrorMessage(otpVerify?.message);
			} else if (
				otpVerify &&
				otpVerify?.data?.exception &&
				otpVerify?.data?.message
			) {
				setOtpErrorMessage(otpVerify?.data?.message);
				if (otpVerify?.data?.message != 'Successfully verified') {
					setOTP('');
				}
			} else {
				setOtpErrorMessage(otpVerify?.message);
				if (otpVerify?.message != 'Successfully verified') {
					setOTP('');
				}
			}
		}
	}, [otpVerify, isOtpVerifySuccess]);

	useEffect(() => {
		refetchOtp();
	}, [otpRequest]);

	useEffect(() => {
		refetchOtpVerify();
	}, [otpNumber]);

	const handleOnClickViewLogs = async (row) => {
		setIntegrationQuery('send');
		setCredentialId(row.original.id);
	};

	const handlePageChange = async (_page, _pageSize) => {
		const params = { page, pageSize };
		const { page: _, pageSize: __ } = params;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			setTableFilter({
				...tableFilter,
				limit: _pageSize,
				page: _page,
			});
		}
	};

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const showSuccessMessage = (message: string) => {
		setSuccessMessage(message);
		showSuccessModal();
	};

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
		showErrorModal();
	};

	useEffect(() => {
		let interval;

		if (maxLimitTimer > 0) {
			interval = setInterval(() => {
				const minutes = Math.floor(maxLimitTimer / 60);
				const seconds = maxLimitTimer % 60;
				const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
					.toString()
					.padStart(2, '0')}`;
				setMaxLimitTimerMinute(formattedTime);
				setMaxLimitTimer((prevTime) => prevTime - 1);
			}, 1000);
			setRegenButton(true);
			setRegenButtonText(
				'Regenerate Client Credentials (' + maxLimitTimerMinute + ')'
			);
		} else {
			setRegenButton(false);
			setRegenButtonText('Regenerate Client Credentials');
		}

		// Cleanup the interval when the component unmounts or maxLimitTimer reaches 0
		return () => clearInterval(interval);
	}, [maxLimitTimer]);

	useEffect(() => {
		let interval;

		if (countdownTime > 0) {
			interval = setInterval(() => {
				setCountdownTime((prevTime) => prevTime - 1);
			}, 1000);
		}

		// Cleanup the interval when the component unmounts or countdownTime reaches 0
		return () => clearInterval(interval);
	}, [countdownTime]);

	useEffect(() => {
		let interval;

		if (countdownTimeSms > 0) {
			interval = setInterval(() => {
				setCountdownTimeSms((prevTime) => prevTime - 1);
			}, 1000);
		}

		// Cleanup the interval when the component unmounts or countdownTimeSms reaches 0
		return () => clearInterval(interval);
	}, [countdownTimeSms]);

	useEffect(() => {
		let interval;

		if (expirationTime > 0) {
			interval = setInterval(() => {
				setExpirationTime((prevTime) => prevTime - 1);
			}, 1000);
		}

		// Cleanup the interval when the component unmounts or expirationTime reaches 0
		return () => clearInterval(interval);
	}, [expirationTime]);

	useEffect(() => {
		let interval;

		if (expirationTimeSms > 0) {
			interval = setInterval(() => {
				setExpirationTimeSms((prevTime) => prevTime - 1);
			}, 1000);
		}

		// Cleanup the interval when the component unmounts or expirationTimeSms reaches 0
		return () => clearInterval(interval);
	}, [expirationTimeSms]);

	const CREDENTIAL_COLUMNS = (handleViewLogs, page) => [
		{
			Header: 'Date & Time Generated',
			id: 'createdAt',
			sortable: false,
			width: '40%',
			accessor: ({ createdAt }) => formatDate(createdAt),
		},
		{
			Header: 'Recipient',
			id: 'emailAddress',
			sortable: false,
			width: '30%',
		},
		{
			Header: (): JSX.Element => (
				<div className={styles.actionHeader}>Action</div>
			),
			id: 'actions',
			width: '30%',
			//accessor: (values: any) => values,
			Cell: ({ row }: ICell): JSX.Element => {
				const isLatestRow = row.index == 0 && page == 1;
				return (
					<div id="td-actions" className={styles.actions}>
						<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
							<button
								className={`${styles.sendButton} ${
									!isLatestRow ? 'slds-is-visually-empty' : ''
								}`}
								disabled={!isLatestRow}
								onClick={() => {
									if (!hasSendClientCredentialsChannelPermission) {
										return showPermissionErrorModal();
									}

									handleOnClickViewLogs(row);
								}}
							>
								{' '}
								Send{' '}
							</button>
						</div>
					</div>
				);
			},
		},
	];

	const inputNormal: React.CSSProperties = {
		width: '38px',
		height: '50px',
		margin: '0 5px 0 5px',
		fontSize: '1.5em',
		textAlign: 'center',
		border: '1px solid rgba(0,0,0,0.3)',
		borderRadius: '4px',
		boxShadow: 'none',
	};

	const inputError: React.CSSProperties = {
		width: '38px',
		height: '50px',
		margin: '0 5px 0 5px',
		fontSize: '1.5em',
		textAlign: 'center',
		border: '1px solid red',
		borderRadius: '4px',
	};

	return selectedChannel ? (
		<>
			{isOtpModalShown && (
				<OtpModal open={isOtpModalShown} onClose={handleModalClose}>
					<OtpModalBody>
						<OtpText>
							<div className={styles.otpHeader}>One-Time PIN</div>
						</OtpText>
						<OtpSubText>
							{otpUserMessage && (
								<>
									<div className={styles.otpModalSubtext}>{otpUserMessage}</div>
								</>
							)}
						</OtpSubText>
						<OtpSubText>
							<OtpInput
								value={otp}
								numInputs={6}
								onChange={handleOTPChange}
								renderInput={(inputProps, index) =>
									renderInput(inputProps, index, otp.length === 6)
								}
								shouldAutoFocus={true}
								placeholder=""
								renderSeparator={(index) => (index < 5 ? <span>-</span> : null)}
								containerStyle={{ display: 'flex', justifyContent: 'center' }}
								inputStyle={otpErrorDesign ? inputError : inputNormal}
								inputType="text"
							/>
						</OtpSubText>
					</OtpModalBody>
					<OtpModalActions>
						<div>
							<>
								{otpSendVia === 'email' ? (
									<>
										<div className={styles.otpModalExpirationText}>
											<ClockIcon /> OTP expire in:{' '}
											<span className={styles.expirationTimeStyle}>
												{expirationTime} secs
											</span>
											<br />
											<span
												className={
													otpErrorDesign
														? styles.otpModalErrorMessage
														: styles.otpModalSuccessMessage
												}
											>
												{otpErrorMessage}
											</span>
										</div>
									</>
								) : (
									<>
										<div className={styles.otpModalExpirationText}>
											<ClockIcon /> OTP expire in:{' '}
											<span className={styles.expirationTimeStyle}>
												{expirationTimeSms} secs
											</span>
											<br />
											<span
												className={
													otpErrorDesign
														? styles.otpModalErrorMessage
														: styles.otpModalSuccessMessage
												}
											>
												{otpErrorMessage}
											</span>
										</div>
									</>
								)}
							</>
							<PrimaryButton
								className={styles.otpModalBtn}
								disabled={otpButton}
								onClick={() => {
									setOtpNumber(otp);
									// if (!isGenerateQueryLoading) {
									// 	setIntegrationQuery('generate');
									// 	setGenerateId(selectedId());

									// 	doneBtnOnClick.action();
									// }
								}}
							>
								{otpButtonText}
							</PrimaryButton>
							<div className={styles.resendOtpButtonsContainer}>
								{otpSendVia === 'sms' ? (
									<>
										{countdownTimeSms > 0 ? (
											<div>
												{' '}
												Resend OTP (Request again after {countdownTimeSms} secs)
											</div>
										) : (
											<div className={styles.resendOtpButton}>
												<ResendOtpButton
													onClick={() => {
														if (expirationTimeSms === 0) {
															setOtpRequest(['initial', 'sms']);
														} else if (countdownTimeSms === 0) {
															setOtpRequest(['resend', 'sms']);
														}
														setOtpErrorDesign(false);
														setOtpErrorMessage('');
													}}
												/>
											</div>
										)}
										<div className={styles.resendOtpViaEmailButton}>
											<ResendOtpViaEmailButton
												onClick={() => {
													if (expirationTime === 0) {
														setOtpRequest(['initial', 'email']);
														setExpirationTime(300);
													} else {
														setOtpSendVia('email');
													}
													setOtpErrorDesign(false);
													setOtpErrorMessage('');
												}}
											/>
										</div>
									</>
								) : (
									<>
										{countdownTime > 0 ? (
											<div>
												{' '}
												Resend OTP (Request again after {countdownTime} secs)
											</div>
										) : (
											<div className={styles.resendOtpButton}>
												<ResendOtpButton
													onClick={() => {
														if (expirationTime === 0) {
															setOtpRequest(['initial', 'email']);
														} else if (countdownTime === 0) {
															setOtpRequest(['resend', 'email']);
														}
														setOtpErrorDesign(false);
														setOtpErrorMessage('');
													}}
												/>
											</div>
										)}
										<div className={styles.resendOtpViaEmailButton}>
											<ResendOtpViaSmsButton
												onClick={() => {
													if (expirationTimeSms === 0) {
														setOtpRequest(['initial', 'sms']);
														setExpirationTimeSms(480);
													} else {
														setOtpSendVia('sms');
													}
													setOtpErrorDesign(false);
													setOtpErrorMessage('');
												}}
											/>
										</div>
									</>
								)}
							</div>
						</div>
					</OtpModalActions>
				</OtpModal>
			)}

			{isSuccessModalShown && (
				<SuccessModal
					open={isSuccessModalShown}
					onClose={handleSuccessModalClose}
				>
					<SuccessModalBody>
						<SuccessText>
							<div className={stylesSuccess.successHeader}>Success!</div>
							<div className={styles.successBodyAdjust}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								hideSuccessModal();
								doneBtnOnClick.action();
								setOTP('');
								setOtpErrorDesign(false);
								setOtpErrorMessage('');
								setCountdownTimeSms(0);
								setExpirationTimeSms(0);
								setCountdownTime(0);
								setExpirationTime(0);
								refetchCredentialsList();
								hideOtpModal();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>
							{errorTitle || 'Timeout Error!'}
						</div>
						<div className={styles.errorBody} style={{ maxWidth: '210px' }}>
							{errorMessage}
						</div>
						{/* <div className={styles.errorFooter}>Please try again</div> */}
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							style={{ marginTop: '15px' }}
							fullWidth
							onClick={() => {
								hideErrorModal();
								setOtpErrorDesign(false);
								setOtpErrorMessage('');
								hideOtpModal();
								setErrorTitle('');
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Close
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{!isQueryLoading && (
				<>
					{allChannelData.data.length > 0 && allChannelData
						? allChannelData.data.map((channel: any, index: number) => (
								<PrimaryCard
									key={channel.id}
									name={channel.name}
									logo={channel.logo}
									status={'Active'}
									isSelected={channel.isSelected}
									onClick={() => {
										setChannelData(channel.id);
										setChannelData(channel.name);
									}}
								/>
						  ))
						: allChannelData.data.length <= 0
						? null
						: null}
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Grid
							column
							size={3}
							of={8}
							className={styles.channelCredentialsBtn}
						>
							<PrimaryButton
								disabled={regenButton}
								onClick={() => {
									//TODO
									if (!hasRegenerateClientCredentialsPermission) {
										return showPermissionErrorModal();
									}
									setOtpSendVia('sms');
									//generateClientCredentials;
									setRegenButton(true);
									setRegenButtonText('Regenerating');
									if (expirationTimeSms === 0) {
										if (otpRequest) {
											if (
												otpRequest[0] == 'initial' &&
												otpRequest[1] == 'sms'
											) {
												refetchOtp();
											} else {
												setOtpRequest(['initial', 'sms']);
											}
										} else {
											setOtpRequest(['initial', 'sms']);
										}
									} else {
										if (userMobile) {
											setOtpUserMessage(
												'Enter 6-digit code sent to the number ending ' +
													userMobile
											);

											setRegenButton(false);
											setRegenButtonText('Regenerate Client Credentials');
											otpModalOnClick.action();
										} else if (userEmail) {
											setOtpUserMessage(
												'Enter 6-digit code sent to the email ' + userEmail
											);

											setRegenButton(false);
											setRegenButtonText('Regenerate Client Credentials');
											otpModalOnClick.action();
										}
									}
								}}
								fullWidth
							>
								{regenButtonText}
							</PrimaryButton>
						</Grid>
					</div>
					<div className={styles.channelCredentialsTableContainer}>
						{!isQueryLoading && credentialListData ? (
							<ChannelCredentialsTable
								columns={CREDENTIAL_COLUMNS(handleOnClickViewLogs, page)}
								data={credentialListData.data}
								count={credentialListData.meta.total}
								pageSize={pageSize}
								page={page}
								onPageChange={handlePageChange}
							/>
						) : (
							<PanelPlaceholder {...panelPlaceholderProps} />
						)}
					</div>
					{hasPermission && (
						<AccessDeniedModal
							open={hasPermission}
							onClose={hidePermissionErrorModal}
						/>
					)}
				</>
			)}
		</>
	) : (
		<PanelPlaceholder {...panelPlaceholderProps} />
	);
};

export default ChannelCredentials;
