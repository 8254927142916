import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import {
	EmailTagInput,
	ContactNumberTagInput,
	TelephoneNumberTagInput,
} from 'components/TagInput/TagInputChannel';
import { ChannelOfficerProps } from './types';
import { useFormContext } from 'react-hook-form';

const ChannelOfficer: React.FC<ChannelOfficerProps> = ({ disabled }) => {
	const name = 'primaryInfo.channelOfficer';
	const { control } = useFormContext();
	return (
		<Section title="Account Officer">
			<SectionRow>
				<Grid column size={1} of={2}>
					<TextField
						required
						label="Name"
						name={`${name}.name`}
						control={control}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<EmailTagInput
						required
						control={control}
						name={`${name}.emailAddress`}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={2}>
					<TelephoneNumberTagInput
						control={control}
						name={`${name}.telNo`}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={2}>
					<ContactNumberTagInput
						required
						control={control}
						secondarySubtext="* Mobile Number should start with +639"
						name={`${name}.mobileNo`}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default ChannelOfficer;
