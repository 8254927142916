import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import { TabLabel } from 'components/BillerForm/BillerForm';
import styles from './ChannelForm.module.css';
import PrimaryInformationTab from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/PrimaryInformationTab';
import ReportsTab from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/ReportsTab';
import { ChannelFormProps } from 'containers/ChannelManagement/Channel/ChannelForm/types';
import { FormProvider, useForm } from 'react-hook-form';
import ContractDetailsTab from './Tabs/ContractDetails/ContractDetailsTab';
import { useEffect, useState } from 'react';

const ChannelForm: React.FC<ChannelFormProps> = ({
	hasPrimaryViewPermission = true,
	hasContractViewPermission = true,
	hasReportsViewPermission = true,
	channelId,
	mode,
	isAdd,
	isAutoSave,
	autoSaveValue,
	tabStatus,
	primaryData,
	handleAutoSave,
	primaryForm,
	contractForm,
	reportsForm,
	contentClassName = '',
	onChange,
	disabled = false,
	selectedIndex,
	setSelectedIndex,
	isChannelDetails = false,
}) => {
	// useEffect(() => {}, [autosaveTracker]);
	const getZoomLevel = () => {
		return Math.round(window.devicePixelRatio * 100);
	};
	const [zoomLevel, setZoomLevel] = useState<number>(getZoomLevel());

	useEffect(() => {
		const handleResize = () => {
			setZoomLevel(getZoomLevel());
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// const getStyles = () => {
	// 	if (zoomLevel >= 100 && zoomLevel < 110) {
	// 		return {
	// 			height: '51vh',
	// 		};
	// 	} else if (zoomLevel >= 110 && zoomLevel < 125) {
	// 		return {
	// 			height: '47vh',
	// 		};
	// 	} else if (zoomLevel >= 125 && zoomLevel < 150) {
	// 		return {
	// 			height: '41vh',
	// 		};
	// 	} else if (zoomLevel >= 150 && zoomLevel < 175) {
	// 		return {
	// 			height: '31vh',
	// 		};
	// 	} else if (zoomLevel >= 175 && zoomLevel < 200) {
	// 		return {
	// 			height: '21vh',
	// 		};
	// 	} else if (zoomLevel >= 200) {
	// 		return {
	// 			height: '10vh',
	// 		};
	// 	} else {
	// 		return {
	// 			height: '55vh',
	// 		};
	// 	}
	// };

	const hasPrimaryAccess = !isChannelDetails || hasPrimaryViewPermission;
	const hasContractAccess = !isChannelDetails || hasContractViewPermission;
	const hasReportsAccess = !isChannelDetails || hasReportsViewPermission;

	return (
		<Tabs
			selectedIndex={selectedIndex}
			onSelect={(selectedIndex) => {
				setSelectedIndex(selectedIndex);
				handleAutoSave && handleAutoSave();
			}}
			className={styles.tabs}
		>
			<TabsPanel
				label={
					<TabLabel
						label="Primary Information"
						done={
							tabStatus?.primaryInformation.finished
							// tabStatus.primaryInformation?.finished
						}
					/>
				}
				disabled={false}
			>
				<div
					className={cx(styles.content)}
					// style={getStyles()}
				>
					{hasPrimaryAccess && (
						<FormProvider {...primaryForm}>
							<PrimaryInformationTab
								primaryData={primaryData}
								onChange={onChange}
								isAdd={isAdd}
								autoSaveValue={autoSaveValue}
								disabled={disabled}
								isAutoSave={isAutoSave}
							/>
						</FormProvider>
					)}
				</div>
			</TabsPanel>
			<TabsPanel
				label={
					<TabLabel
						label="Contract Details"
						done={tabStatus?.contractDetails.finished}
					/>
				}
				disabled={false} // TODO: Remove force enable after implementation
			>
				<div
					className={cx(styles.content)}
					// style={getStyles()}
				>
					{hasContractAccess && (
						<FormProvider {...contractForm}>
							<ContractDetailsTab
								isChannelDetails={isChannelDetails}
								disabled={disabled}
								mode={mode}
								channelId={channelId}
								isAutoSave={isAutoSave}
							/>
						</FormProvider>
					)}
				</div>
			</TabsPanel>
			<TabsPanel
				label={<TabLabel label="Reports" done={tabStatus?.reports.finished} />}
				disabled={false} // TODO: Remove force enable after implementation
			>
				<div
					className={cx(styles.content)}
					//style={getStyles()}
				>
					{hasReportsAccess && (
						<FormProvider {...reportsForm}>
							<ReportsTab disabled={disabled} disabledEmail={true} />
						</FormProvider>
					)}
				</div>
			</TabsPanel>
		</Tabs>
	);
};

export default ChannelForm;
