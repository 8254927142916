import cx from 'classnames';
import PrimaryCardChannel from 'components/Cards/PrimaryCard/PrimaryCardChannel';
import ScrollableList from 'containers/ChannelManagement/List/ScrollableList';
import { useList } from 'containers/ChannelManagement/List/hooks';
import { UseGetChannelList } from 'containers/ChannelManagement/List/query';
import {
	ChannelManagementListProps,
	Filter,
} from 'containers/ChannelManagement/List/types';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	activeType,
	branchListFilter,
	channelListFilter,
	selectChannel,
	selectedBranch,
	selectedChannel,
	selectedTerminal,
	setBranchFormValues,
	setLoader,
	terminalListFilter,
} from 'redux/modules/channelList';
import { clearFilter } from 'redux/modules/products';
import { useToggle } from 'utils/hooks';
import AccessDeniedModal from 'containers/ChannelManagement/Permission/AccessDeniedModal';
import ConfirmationModal from 'containers/ChannelManagement/Terminal/Modal/ConfirmationModal';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import ConfirmationModalChannel from 'components/ConfirmationModal/ConfirmationModal';

const ChannelManagementList: React.FC<ChannelManagementListProps> = ({
	addNewChannelButtonClick,
	addNewBranchButtonClick,
	addNewTerminalButtonClick,
	isAddBranchModalShowing,
	isTerminalDisabled,
	isTerminalDirty,
	isChannelEditMode,
	isChannelDirty,
	setIsChannelEditMode,
}) => {
	const initialFilterFields: Filter = {
		typeItemChecked: [],
		searchFilter: '',
		statusItemChecked: [],
	};

	const [allChannels, setAllChannels] = useState<{ data: any[] }>({ data: [] });
	const [allBranches, setAllBranches] = useState<{ data: any[] }>({ data: [] });
	const [allTerminals, setAllTerminals] = useState<{ data: any[] }>({
		data: [],
	});
	const [filterFields, setFilterFields] = useState<Filter>(initialFilterFields);
	const [selectedChannelName, setSelectedChannelName] = useState<string>();
	const [selectedBranchName, setSelectedBranchName] = useState<string>();
	const [selectedTerminalName, setSelectedTerminalName] = useState<string>();
	const [showChannelList, setShowChannelList] = useState<boolean>(true);
	const [showBranchList, setShowBranchList] = useState<boolean>(false);
	const [showTerminalList, setShowTerminalList] = useState<boolean>(false);
	const [selectedTerminalID, setSelectedTerminaID] = useState<string>();
	const [terminalValues, setTerminalValues] = useState();
	const [branchValues, setBranchValues] = useState();
	const dispatch = useDispatch();

	const {
		value: hasPermission,
		valueOn: showPermissionErrorModal,
		valueOff: hidePermissionErrorModal,
	} = useToggle();

	const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();
	const {
		value: isConfirmModalShowingChannel,
		valueOn: showConfirmModalChannel,
		valueOff: hideConfirmModalChannel,
	} = useToggle();

	const pageParam = {
		[showChannelList ? 'search' : showBranchList ? 'name' : 'tpaId']:
			filterFields.searchFilter ? filterFields.searchFilter : '',
		category: filterFields.typeItemChecked
			? filterFields.typeItemChecked.join('|')
			: '',
		status: filterFields.statusItemChecked
			? filterFields.statusItemChecked.join('|')
			: '',
	};

	const {
		channelTypeFilter,
		channelDetails,
		branchDetails,
		channelFilter,
		branchFilter,
		terminalFilter,
		branchListQuery,
		terminalListQuery,
		hasViewChannelListPermission,
		hasViewBranchListPermission,
		hasViewTerminalListPermission,
	} = useList();

	const filterDebounce = debounce((value) => {
		setFilterFields(value);
	}, 700);

	let getChannelList;
	if (hasViewChannelListPermission) {
		if (channelFilter.search.length >= 3) {
			getChannelList = UseGetChannelList(channelFilter);
		} else {
			const search = '';
			const { category, status } = channelFilter;
			getChannelList = UseGetChannelList({ search, category, status });
		}
	}

	const {
		data: channelList,
		isLoading: isChannelLoading,
		isSuccess: isChannelSuccess,
		refetch: refetchChannel,
	} = getChannelList || {};

	const { refetch: refetchBranchList } = branchListQuery || {};

	useEffect(() => {
		if (!hasViewChannelListPermission) {
			return showPermissionErrorModal();
		}
	}, [channelFilter]);

	useEffect(() => {
		if (!isAddBranchModalShowing) {
			refetchBranchList?.(); //Update the branch list after closing the Add New Branch modal
		}
	}, [refetchBranchList, isAddBranchModalShowing]);

	useEffect(() => {
		refetchChannel?.();
		if (isChannelSuccess) {
			setAllChannels(channelList);
		}
	}, [isChannelSuccess, filterFields, refetchChannel, channelList]);
	useEffect(() => {
		if (!hasViewBranchListPermission && channelDetails.id) {
			return showPermissionErrorModal();
		}
	}, [channelDetails.id]);
	useEffect(() => {
		if (!hasViewBranchListPermission && channelDetails.id) return;
		if (branchListQuery?.isSuccess) {
			setAllBranches(branchListQuery?.data);
		}
	}, [
		branchListQuery?.isSuccess,
		channelDetails.id,
		branchFilter,
		branchListQuery?.data,
	]);

	useEffect(() => {
		if (!hasViewTerminalListPermission && branchDetails.id) {
			return showPermissionErrorModal();
		}
	}, [branchDetails.id]);
	useEffect(() => {
		if (!hasViewTerminalListPermission && branchDetails.id) return;
		if (terminalListQuery?.isSuccess) {
			setAllTerminals(terminalListQuery?.data);
		}
	}, [
		terminalListQuery?.isSuccess,
		branchDetails.id,
		terminalFilter,
		terminalListQuery?.data,
	]);

	useEffect(() => {
		const isLoading = isChannelLoading;
		dispatch(
			setLoader({
				isLoading,
				message: 'Please wait while data are being loaded',
			})
		);
	}, [isChannelLoading, dispatch]);

	useEffect(() => {
		if (
			branchFilter.name !== '' ||
			branchFilter.posType !== '' ||
			branchFilter.storeType !== '' ||
			branchFilter.storeFormat !== '' ||
			branchFilter.machineLocation !== ''
		) {
			const isLoading = branchListQuery?.isLoading;
			dispatch(
				setLoader({
					isLoading,
					message: 'Please wait while data are being loaded',
				})
			);
		}
	}, [branchFilter, branchListQuery?.isLoading, dispatch]);

	const scrollableListProps = useMemo(
		() => ({
			primaryButtonOnClick: showChannelList
				? addNewChannelButtonClick
				: showBranchList
				? addNewBranchButtonClick
				: addNewTerminalButtonClick,
			primaryButtonTitle: showChannelList
				? 'Add New Channel'
				: showBranchList
				? 'Add New Branch'
				: 'Add New Terminal',
			listTitle: showChannelList
				? 'Channel List'
				: showBranchList
				? 'Branch List'
				: 'Terminal List',
			dropdownOptions: channelTypeFilter,
			onFilterChange: (value: Filter) => filterDebounce(value),
			searchPlaceholder: showChannelList
				? 'Search Channel Name'
				: showBranchList
				? 'Search Branch Name'
				: 'Search Terminal',
			clearFilter: () => {
				setFilterFields(initialFilterFields);
			},
			selectedChannelName: selectedChannelName,
			selectedBranchName: selectedBranchName,
			selectedTerminalName: selectedTerminalName,
			setSelectedChannelName: setSelectedChannelName,
			setSelectedBranchName: setSelectedBranchName,
			setSelectedTerminalName: setSelectedTerminalName,
			setShowChannel: setShowChannelList,
			setShowBranch: setShowBranchList,
			setShowTerminal: setShowTerminalList,
			setSelectedTerminaID: setSelectedTerminaID,
		}),
		[
			clearFilter,
			channelTypeFilter,
			selectedChannelName,
			selectedBranchName,
			selectedTerminalName,
		]
	);

	const noListAvailable = (desc: string) => (
		<span
			className={cx(
				'slds-text-body_small slds-align_absolute-center slds-m-around_medium'
			)}
		>
			{desc}
		</span>
	);

	const { currenType, terminalDetails } = useTerminal();
	const confirmModalBodyText = (
		<>
			{!isTerminalDirty ? (
				<>
					<br />
					<br />
					Are you sure you want to exit edit mode?
				</>
			) : (
				<>
					<br />
					Are you sure you want to exit without saving?
					<br />
					Any unsaved work will be lost.
				</>
			)}
		</>
	);

	const handleBranchClick = (branch) => {
		dispatch(
			branchListFilter({
				branchFilter: {
					name: '',
					posType: '',
					storeType: '',
					storeFormat: '',
					machineLocation: '',
				},
			})
		);
		dispatch(selectedBranch({ branch }));
		dispatch(selectedTerminal({ terminal: {} }));
		dispatch(activeType({ type: 'branch' }));
		setSelectedBranchName(branch.name);
		setShowBranchList(false);
		setShowTerminalList(true);
		setSelectedTerminaID('');
	};
	const handleTerminalClick = (terminal) => {
		hideConfirmModal();
		dispatch(
			terminalListFilter({
				terminalFilter: {
					tpaId: '',
				},
			})
		);
		dispatch(selectedTerminal({ terminal }));
		dispatch(activeType({ type: 'terminal' }));
		setSelectedTerminalName(terminal.tpaId);
		setSelectedTerminaID(terminal.tpaId);
	};
	return (
		<>
			<ScrollableList {...scrollableListProps}>
				{hasViewChannelListPermission
					? allChannels.data.length > 0 && showChannelList
						? allChannels.data.map((channel: any, index: number) => (
								<PrimaryCardChannel
									key={channel.id}
									name={channel.name}
									logo={channel.logo}
									status={channel.status}
									isSelected={channel.isSelected}
									listLength={allChannels.data.length}
									onClick={() => {
										selectChannel({
											selected: channel.id,
											cnname: channel.name,
										});
										dispatch(
											channelListFilter({
												channelFilter: {
													search: '',
													category: '',
													status: '',
												},
											})
										);
										dispatch(selectedChannel({ channel }));
										dispatch(selectedBranch({ branch: {} }));
										dispatch(setBranchFormValues({}));
										dispatch(activeType({ type: 'channel' }));
										setSelectedChannelName(channel.name);
										setShowChannelList(false);
										setShowBranchList(true);
									}}
								/>
						  ))
						: allChannels.data.length <= 0
						? noListAvailable('No channel available')
						: null
					: null}
				{hasViewBranchListPermission
					? allBranches?.data.length > 0 && showBranchList
						? allBranches?.data.map((branch: any) => (
								<PrimaryCardChannel
									key={branch.id}
									name={branch.name}
									logo={''}
									status={branch.status}
									listLength={allBranches.data.length}
									onClick={() => {
										setBranchValues(branch);
										if (isChannelDirty || !isChannelEditMode) {
											showConfirmModalChannel();
										} else {
											handleBranchClick(branch);
										}
									}}
								/>
						  ))
						: showBranchList
						? noListAvailable('No branch available')
						: null
					: null}
				{hasViewTerminalListPermission
					? allTerminals?.data.length > 0 && showTerminalList
						? allTerminals?.data.map((terminal: any) => (
								<PrimaryCardChannel
									key={terminal.id}
									name={terminal.tpaId}
									logo={''}
									status={terminal.status}
									listLength={allTerminals.data.length}
									isSelected={
										selectedTerminalID == terminal.tpaId ? true : false
									}
									onClick={() => {
										setTerminalValues(terminal);
										if (
											isTerminalDisabled &&
											currenType === 'terminal' &&
											terminalDetails?.id !== terminal.id
										) {
											showConfirmModal();
										} else {
											handleTerminalClick(terminal);
										}
									}}
								/>
						  ))
						: showTerminalList
						? noListAvailable('No terminal available')
						: null
					: null}
			</ScrollableList>
			{hasPermission && (
				<AccessDeniedModal
					open={hasPermission}
					onClose={hidePermissionErrorModal}
				/>
			)}
			{isConfirmModalShowing && (
				<ConfirmationModal
					open={isConfirmModalShowing}
					onClose={hideConfirmModal}
					heading="Exit Edit Mode?"
					body={confirmModalBodyText}
					cancelButton="Cancel"
					submitButton="Confirm"
					handleSubmit={() => {
						handleTerminalClick(terminalValues);
					}}
				/>
			)}
			{isConfirmModalShowing && (
				<ConfirmationModal
					open={isConfirmModalShowing}
					onClose={hideConfirmModal}
					heading="Exit Edit Mode?"
					body={confirmModalBodyText}
					cancelButton="Cancel"
					submitButton="Confirm"
					handleSubmit={() => {
						handleTerminalClick(terminalValues);
					}}
				/>
			)}
			{isConfirmModalShowingChannel && (
				<ConfirmationModal
					open={isConfirmModalShowingChannel}
					heading={'Exit Edit Mode'}
					body={
						isChannelDirty ? (
							<>
								<br />
								Are you sure you want to exit without saving?
								<br />
								Any unsaved work will be lost.
							</>
						) : (
							<>
								<br />
								<br />
								Are you sure you want to exit edit mode?
							</>
						)
					}
					onClose={hideConfirmModalChannel}
					cancelButton="Cancel"
					submitButton="Confirm"
					handleSubmit={() => {
						setIsChannelEditMode(true);
						handleBranchClick(branchValues);
						hideConfirmModalChannel();
					}}
				/>
			)}
		</>
	);
};

export default ChannelManagementList;
