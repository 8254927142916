import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import Label from 'components/Inputs/Label/Label';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import Section from 'components/Section/Section';
import {
	SelectFieldProps,
	TextFieldProps,
} from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import MeralcoPaymentCenterSelect, {
	MeralcoPaymentCenterSelectProps,
} from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/MeralcoPaymentCenterSelect';
import {
	CHANNEL_TRANSACTION_TYPE_OPTIONS,
	MACHINE_LOCATION_OPTION,
	NAME_OF_CHANNEL_ACCOUNT_CODE,
	NAME_OF_CHANNEL_ACCOUNT_NAME,
	NAME_OF_CHANNEL_TRANSACTION_TYPE,
	NAME_OF_CHANNEL_TYPE,
	NAME_OF_POS_TYPE,
	NAME_OF_SETTLEMENT_SETUP,
	PHYSICAL,
	POST_TYPE_OPTIONS,
	SETTLEMENT_SETUP_OPTIONS,
	STORE_FORMAT_OPTIONS,
	STORE_TYPE_OPTIONS,
} from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/constants';
import {
	BRANCH_BASIC_INFORMATION,
	BRANCH_CODE,
	BRANCH_NAME,
	CHANNEL_ACCOUNT_CODE,
	CHANNEL_ACCOUNT_NAME,
	CHANNEL_TRANSACTION_TYPE,
	CIGNAL_TELLER_CODE,
	MACHINE_LOCATION,
	MERALCO_PAYMENT_CENTER_CODE,
	PLDT_TELLER_CODE,
	POS_TYPE,
	SETTLEMENT_SETUP,
	STORE_FORMAT,
	STORE_TYPE,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';
import client from 'helpers/ApiClient';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FixMeLater, SLDSDropdownOptionType } from 'types';
import { ChannelTypeDigital, ChannelTypePhysical } from 'utils/lookup';
import styles from './BranchBasicInformation.module.css';

export type BranchBasicInformationProp = {
	setCurrentSection?: FixMeLater;
	updateAutoSave?: FixMeLater;
	setPrevSection?: FixMeLater;
	sectionTitle?: string;
	channelAccountName?: TextFieldProps;
	channelAccountCode?: TextFieldProps;
	branchName?: TextFieldProps;
	branchCode?: TextFieldProps;
	channelTransactionType?: SelectFieldProps;
	channelType?: SelectFieldProps;
	posType?: SelectFieldProps;
	settlementSetup?: SelectFieldProps;
	storeType?: SelectFieldProps;
	storeFormat?: SelectFieldProps;
	machineLocation?: SelectFieldProps;
	pldtTellerCode?: TextFieldProps;
	cignalTellerCode?: TextFieldProps;
	meralcoPaymentCenterCode?: MeralcoPaymentCenterSelectProps;
	isDisabledAllEditableFields?: boolean;
	channelPrimaryInfo?: FixMeLater;
	selectedChannelId?: FixMeLater;
};

const BranchBasicInformation: React.FC<BranchBasicInformationProp> = ({
	setCurrentSection,
	updateAutoSave,
	setPrevSection,
	sectionTitle,
	channelAccountName,
	channelAccountCode,
	branchName,
	branchCode,
	channelTransactionType,
	channelType,
	posType,
	settlementSetup,
	storeType,
	storeFormat,
	machineLocation,
	pldtTellerCode,
	cignalTellerCode,
	meralcoPaymentCenterCode,
	isDisabledAllEditableFields,
}) => {
	const { control, setValue, clearErrors } = useFormContext();
	const [channelTypeFilter, setChannelTypeFilter] = useState<
		SLDSDropdownOptionType[]
	>([{ value: '', label: '' }]);

	const channelTransactionTypeCurrentValue = useWatch({
		name: NAME_OF_CHANNEL_TRANSACTION_TYPE,
	});

	let channelTypeOptions: SelectOption[];

	if (
		channelTransactionTypeCurrentValue &&
		channelTransactionTypeCurrentValue.toLowerCase() === 'digital'
	) {
		channelTypeOptions = channelTypeOptions = ChannelTypeDigital.map(
			(digital) => {
				const match: FixMeLater = channelTypeFilter.find(
					(channelType) => channelType.label === digital.label
				);
				return {
					label: match?.label,
					value: match?.value,
				};
			}
		);
	} else {
		channelTypeOptions = ChannelTypePhysical.map((physical) => {
			const match: FixMeLater = channelTypeFilter.find(
				(channelType) => channelType.label === physical.label
			);
			return {
				label: match?.label,
				value: match?.value,
			};
		});
	}

	useEffect(() => {
		const getChannelTypeFilter = async () => {
			const response = await client.get('/v2/channel-types');
			const transformData = response.data?.data.map(({ id, name }) => ({
				value: id,
				label: name,
			}));
			setChannelTypeFilter(transformData);
		};
		getChannelTypeFilter();
	}, []);

	return (
		<>
			<Section
				onClick={() => {
					setCurrentSection((prevState) => {
						setPrevSection(prevState);
						setCurrentSection('BranchBasicInformation');
					});

					updateAutoSave();
				}}
				title={sectionTitle || 'Branch Basic Information'}
			>
				<div className="slds-grid slds-wrap slds-gutters">
					<div className="slds-col slds-size_8-of-12 slds-p-bottom_small">
						<TextField
							label={_.startCase(CHANNEL_ACCOUNT_NAME)}
							name={NAME_OF_CHANNEL_ACCOUNT_NAME}
							control={control}
							disabled
							placeholder=""
							{...channelAccountName}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<TextField
							label={_.startCase(CHANNEL_ACCOUNT_CODE)}
							control={control}
							name={NAME_OF_CHANNEL_ACCOUNT_CODE}
							disabled
							placeholder=""
							{...channelAccountCode}
						/>
					</div>

					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<TextField
							label={_.startCase(BRANCH_NAME)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${BRANCH_NAME}`}
							required
							disabled={isDisabledAllEditableFields}
							{...branchName}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<TextField
							label={_.startCase(BRANCH_CODE) + ' (Auto-generated)'}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${BRANCH_CODE}`}
							disabled
							placeholder="- - - -"
							{...branchCode}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<SelectField
							label={_.startCase(CHANNEL_TRANSACTION_TYPE)}
							control={control}
							name={NAME_OF_CHANNEL_TRANSACTION_TYPE}
							options={CHANNEL_TRANSACTION_TYPE_OPTIONS}
							required
							placeholder={`Select ${_.startCase(CHANNEL_TRANSACTION_TYPE)}`}
							disabled={isDisabledAllEditableFields}
							onChange={(value) => {
								if (value !== channelTransactionTypeCurrentValue) {
									setValue(NAME_OF_CHANNEL_TYPE, '', { shouldDirty: true });
									clearErrors(NAME_OF_CHANNEL_TYPE);
									setValue(NAME_OF_POS_TYPE, '', { shouldDirty: true });
									clearErrors(NAME_OF_POS_TYPE);
								}
							}}
							{...channelTransactionType}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<SelectField
							label={'Channel Type'}
							control={control}
							name={NAME_OF_CHANNEL_TYPE}
							options={channelTypeOptions}
							required
							placeholder={`Select Channel Type`}
							disabled={isDisabledAllEditableFields}
							{...channelType}
						/>
					</div>
					{channelTransactionTypeCurrentValue === PHYSICAL && (
						<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
							<SelectField
								label={'POS Type'}
								control={control}
								name={NAME_OF_POS_TYPE}
								options={POST_TYPE_OPTIONS}
								required
								placeholder={`Select ${_.startCase(POS_TYPE)}`}
								disabled={isDisabledAllEditableFields}
								{...posType}
							/>
						</div>
					)}
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<SelectField
							label={_.startCase(SETTLEMENT_SETUP)}
							control={control}
							name={NAME_OF_SETTLEMENT_SETUP}
							options={SETTLEMENT_SETUP_OPTIONS}
							disabled
							placeholder=""
							{...settlementSetup}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<SelectField
							label={_.startCase(STORE_TYPE)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${STORE_TYPE}`}
							options={STORE_TYPE_OPTIONS}
							required
							placeholder={`Select ${_.startCase(STORE_TYPE)}`}
							disabled={isDisabledAllEditableFields}
							{...storeType}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						{channelTransactionTypeCurrentValue !== PHYSICAL && (
							<div className={styles.subtext}>
								<em>ㅤ</em>
							</div>
						)}
						<SelectField
							label={_.startCase(STORE_FORMAT)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${STORE_FORMAT}`}
							options={STORE_FORMAT_OPTIONS}
							required
							placeholder={`Select ${_.startCase(STORE_FORMAT)}`}
							disabled={isDisabledAllEditableFields}
							{...storeFormat}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						{channelTransactionTypeCurrentValue !== PHYSICAL && (
							<div className={styles.subtext}>
								<em>ㅤ</em>
							</div>
						)}
						<SelectField
							label={_.startCase(MACHINE_LOCATION)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${MACHINE_LOCATION}`}
							options={MACHINE_LOCATION_OPTION}
							required
							placeholder={`Select ${_.startCase(MACHINE_LOCATION)}`}
							disabled={isDisabledAllEditableFields}
							{...machineLocation}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<Label>{'PLDT Teller Code'}</Label>
						<div className={styles.subtext}>
							<em>If left blank, Channel cannot process PLDT biller.</em>
						</div>
						<TextField
							label={''}
							placeholder={_.startCase(PLDT_TELLER_CODE)}
							name={`${BRANCH_BASIC_INFORMATION}.${PLDT_TELLER_CODE}`}
							control={control}
							disabled={isDisabledAllEditableFields}
							{...pldtTellerCode}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<Label>{_.startCase(CIGNAL_TELLER_CODE)}</Label>
						<div className={styles.subtext}>
							<em>If left blank, Channel cannot process Cignal biller.</em>
						</div>
						<TextField
							label={''}
							placeholder={_.startCase(CIGNAL_TELLER_CODE)}
							name={`${BRANCH_BASIC_INFORMATION}.${CIGNAL_TELLER_CODE}`}
							control={control}
							disabled={isDisabledAllEditableFields}
							{...cignalTellerCode}
						/>
					</div>
					<div className="slds-col slds-size_4-of-12 slds-p-bottom_small">
						<div className={styles.subtext}>
							<em>ㅤ</em>
						</div>
						<MeralcoPaymentCenterSelect
							selectFieldProps={{
								control: control,
								name: `${BRANCH_BASIC_INFORMATION}.${MERALCO_PAYMENT_CENTER_CODE}`,
								required: true,
								disabled: isDisabledAllEditableFields,
								placeholder:
									'Select ' + _.startCase(MERALCO_PAYMENT_CENTER_CODE),
								...meralcoPaymentCenterCode?.selectFieldProps,
							}}
						/>
					</div>
				</div>
			</Section>
		</>
	);
};

export default BranchBasicInformation;
