import * as yup from 'yup';
import {
	name,
	emailAddressForOfficerSchema as emailAddress,
	mobileNo,
	telNo,
} from 'containers/ChannelManagement/Channel/ChannelForm/ChannelFormSchema';
import { YupLab } from 'utils/yupLab';
import {
	emailErrorMessage,
	defaultOnlyAlphaChar,
} from 'utils/formSchemas/common';
import { validateEmail } from 'utils/validation';
import {
	MAX_CHAR_255,
	MAX_CHAR_255_MSG,
} from 'containers/ChannelManagement/Channel/ChannelForm/const';

export const ChannelOfficerSchema = new YupLab(
	yup.object({
		emailAddress: yup
			.array()
			.of(yup.string().email())
			.nullable(true)
			.default(null)
			.label('Account Officer Email Address')
			.ensure()
			.min(1, ({ label }) => `Input Email Address`)
			.test(
				'isValidEmail',
				emailErrorMessage,
				(v) => !!v?.every((a) => validateEmail(a || ''))
			),
		telNo,
		mobileNo: yup
			.array()
			.nullable(true)
			.default(null)
			.of(yup.string())
			.test(
				'isValidNumber',
				'Mobile number should be in +639XXXXXXXXX format.',
				(v) =>
					!!v?.every((a, index) => {
						if (index && index >= 19) {
							return false;
						}

						// if (num.match(/^09\d{9}$/)) {
						// 	num = '+63' + num.slice(1);
						// }
						if (a) {
							return new RegExp(/^(\+?639)\d{9}$/).test(a);
						}
					})
			)
			.ensure()
			.min(1, ({ label }) => `Input Mobile Number.`)
			.max(15)
			.label('Mobile Number'),

		name: yup
			.string()
			.nullable(true)
			.required('Input Account Officer Name.')
			.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
			.matches(/^[a-zA-Z0-9\s]*$/, {
				excludeEmptyString: true,
				message: defaultOnlyAlphaChar,
			})
			.matches(/^[a-zA-Z0-9].*$/, 'Input Name of Account Officer.'),
	})
);

export const channelAccountOfficerValues =
	ChannelOfficerSchema.schema.getDefault();
export type ChannelOfficerType = ReturnType<
	(typeof ChannelOfficerSchema.schema)['cast']
>;
