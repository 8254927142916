import { ReactComponent as WarningIcon } from 'assets/icons/ic-warning.svg';
import cx from 'classnames';
import Alert from 'components/Alert/Alert';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModal, { ErrorModalBody } from 'components/Modal/ErrorModal';
import BranchModal from 'containers/ChannelManagement/Branch/BranchModal';
import styles from 'containers/ChannelManagement/Channel/ChannelProfile/index.module.css';
import ChannelManagementList from 'containers/ChannelManagement/List/ChannelManagementList';
import ChannelTab2 from 'containers/ChannelManagement/List/ChannelTab';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
	LoaderState,
	clearSelectedChannel,
	setBranchFormValues,
	setChannelFormValues,
	setLoader,
	setTerminalFormValues,
	resetAll,
} from 'redux/modules/channelList';
import { ReducerStateType } from 'redux/modules/reducers';
import { useToggle } from 'utils/hooks';
import { useHasUserPermission } from 'utils/permissions';
import ChannelModal from '../ChannelModal/ChannelModal';
import { ChannelProfileProps } from '../ChannelProfile/types';
import {
	useGetBranchAutoSave,
	UseGetBranchDetails,
	useGetChannelAutoSave,
	useGetChannelDetails,
	useGetTerminalAutoSave,
	UseGetTerminalDetails,
} from './query';

import BranchTab from 'containers/ChannelManagement/Branch/BranchTab/BranchTab';
import { FixMeLater } from 'types';
import {
	useDeleteBranchAutoSave,
	useDeleteChannelAutoSave,
	useDeleteTerminalAutoSave,
} from '../ChannelForm/ChannelFormQuery';
import { FormModeType } from '../ChannelForm/types';
import { formatActiveType } from '../ChannelForm/utils';
import TerminalModal from 'containers/ChannelManagement/Terminal/Modal/TerminalModal';
import TerminalTab from 'containers/ChannelManagement/Terminal/Tab/TerminalTab';
import { useList } from 'containers/ChannelManagement/List/hooks';

export const initialValues = {
	primaryInformation: {
		basicInformation: {
			channelName: '',
		},
		channelAddresses: [
			{
				locationBase: 'LOCAL',
				country: '1',
				area: '',
				region: '',
			},
		],
		channelContactDetails: [{ name: '', email: [], contactNumber: [] }],
		// accountOfficer: { name: '' },
	},
};

const ChannelProfile: React.FC<ChannelProfileProps> = ({
	clearSelectedChannel,
}) => {
	const dispatch = useDispatch();
	const [isAutoSave, setIsAutoSave] = useState(false);
	const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
	const [isTerminalDisabled, setIsTerminalDisabled] = useState<boolean>(false);
	const [isTerminalDirty, setIsTerminalDirty] = useState<boolean>(false);
	const [isChannelDirty, setIsChannelDirty] = useState<boolean>(false);
	const [isChannelEditMode, setIsChannelEditMode] = useState<boolean>(true);

	useEffect(() => {
		// Cleanup function runs when the component unmounts
		return () => {
			dispatch(resetAll());
		};
	}, [dispatch]);

	const {
		value: isAddChannelProfileModalShowing,
		valueOn: showAddChannelProfileModal,
		valueOff: hideAddChannelProfileModal,
	} = useToggle();

	const {
		value: isAddBranchModalShowing,
		valueOn: showBranchModal,
		valueOff: hideAddBranchModal,
	} = useToggle();

	const {
		value: isAddTerminalModalShowing,
		valueOn: showTerminalModal,
		valueOff: hideAddTerminalModal,
	} = useToggle();

	const {
		value: hasPermission,
		valueOn: showPermissionErrorModal,
		valueOff: hidePermissionErrorModal,
	} = useToggle();

	const {
		value: isChannelAutoSaveDialogShowing,
		valueOn: showChannelAutoSaveDialog,
		valueOff: hideChannelAutoSaveDialog,
	} = useToggle(true);

	const {
		value: isBranchAutoSaveDialogShowing,
		valueOn: showBranchAutoSaveDialog,
		valueOff: hideBranchAutoSaveDialog,
	} = useToggle(true);

	const {
		value: isTerminalAutoSaveDialogShowing,
		valueOn: showTerminalAutoSaveDialog,
		valueOff: hideTerminalAutoSaveDialog,
	} = useToggle(true);

	const selectedChannelId = useSelector((state: FixMeLater) => {
		return state.channels?.selectedChannel?.id as number;
	});

	const channelPrimaryInfo = useSelector((state: FixMeLater) => {
		return state.channels.channelFormValues.primaryInfo.channelPrimaryInfo;
	});

	const channelContractDetails = useSelector((state: FixMeLater) => {
		return state.channels.channelFormValues.contractDetails
			.channelContractDetails;
	});

	const hasChannelUserPermission = useHasUserPermission('channels');
	const hasBranchUserPermission = useHasUserPermission('branches');

	const { hasAddChannelPermission } = useMemo(() => {
		return {
			hasAddChannelPermission: hasChannelUserPermission('add'),
		};
	}, [hasChannelUserPermission]);

	const { hasAddBranchPermission, hasViewBranchPermission } = useMemo(() => {
		return {
			hasAddBranchPermission: hasBranchUserPermission('add'),
			hasViewBranchPermission: hasBranchUserPermission('view'),
		};
	}, [hasBranchUserPermission]);

	const { hasAddTerminalPermission, hasViewTerminalPermission } = useTerminal();

	const showAddChannelProfileModalViewClear = () => {
		showAddChannelProfileModal();
		clearSelectedChannel();
	};

	const activeType = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeType
	);

	const activeTab = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeTab
	);

	const loader: LoaderState = useSelector<ReducerStateType, LoaderState>(
		(state) => state.channels.loader
	);

	const { selectedChannel, selectedBranch, selectedTerminal } = useSelector<
		ReducerStateType,
		{
			selectedChannel: { id: number };
			selectedBranch: { id: number };
			selectedTerminal: { id: number };
		}
	>((state) => state.channels ?? {});

	const channelResult = useGetChannelDetails(
		selectedChannel && selectedChannel.id
	);

	const allSuccess = channelResult.every((data) => data.isSuccess === true);

	let getBranchDetails;
	if (hasViewBranchPermission) {
		getBranchDetails = UseGetBranchDetails(selectedBranch && selectedBranch.id);
	}

	const {
		data: branchDetails,
		isSuccess: isBranchSuccess,
		isFetching: isBranchFetching,
		isError: isBranchError,
		refetch: refetchBranch,
	} = getBranchDetails || {};

	let getTerminalDetails;
	if (hasViewTerminalPermission) {
		getTerminalDetails = UseGetTerminalDetails(
			selectedTerminal && selectedTerminal.id
		);
	}

	const {
		data: terminalDetails,
		isSuccess: isTerminalSuccess,
		refetch: refetchTerminal,
		isError: isTerminalError,
		isFetching: isTerminalFetching,
	} = getTerminalDetails || {};

	const {
		data: channelAutoSave,
		isSuccess: isChannelAutoSaveSuccess,
		isError: isChannelAutoSaveError,
		refetch: refetchChannelAutoSave,
	} = useGetChannelAutoSave();
	const {
		data: branchAutoSave,
		isSuccess: isBranchAutoSaveSuccess,
		isError: isBranchAutoSaveError,
		refetch: refetchBranchAutoSave,
		isLoading: isBranchAutoSaveLoading,
	} = useGetBranchAutoSave(activeType);
	const {
		data: terminalAutoSave,
		isSuccess: isTerminalAutoSaveSuccess,
		isLoading: isTerminalAutoSaveLoading,
		refetch: refetchTerminalAutoSave,
		isError: isTerminalAutoSaveError,
	} = useGetTerminalAutoSave(activeType);

	const showChannelAutoSave =
		!isChannelAutoSaveError &&
		isChannelAutoSaveSuccess &&
		!!channelAutoSave &&
		isChannelAutoSaveDialogShowing &&
		activeType === '' &&
		!isAddChannelProfileModalShowing;
	const showBranchAutoSave =
		!isBranchAutoSaveError &&
		isBranchAutoSaveSuccess &&
		!!branchAutoSave &&
		isBranchAutoSaveDialogShowing &&
		activeType === 'channel' &&
		!isAddBranchModalShowing;
	const showTerminalAutoSave =
		!isTerminalAutoSaveError &&
		isTerminalAutoSaveSuccess &&
		!!terminalAutoSave &&
		isTerminalAutoSaveDialogShowing &&
		(activeType === 'branch' || activeType === 'terminal') &&
		!isAddTerminalModalShowing;

	const deleteChannelAutoSave = useDeleteChannelAutoSave();
	const deleteBranchAutoSave = useDeleteBranchAutoSave();
	const deleteTerminalAutoSave = useDeleteTerminalAutoSave();

	const handleCancelAutoSave = () => {
		refetchChannelAutoSave();
		refetchBranchAutoSave();
		refetchTerminalAutoSave();
	};

	const handleDeleteChannelAutosave = async () => {
		dispatch(setLoader({ isLoading: false, message: 'Please wait...' }));

		hideChannelAutoSaveDialog();
		await deleteChannelAutoSave.mutateAsync(undefined);
		handleCancelAutoSave();
	};

	const handleDeleteBranchAutosave = async () => {
		dispatch(setLoader({ isLoading: false, message: 'Please wait...' }));

		hideBranchAutoSaveDialog();
		await deleteBranchAutoSave.mutateAsync(undefined);
		handleCancelAutoSave();
	};

	const handleDeleteTerminalAutosave = async () => {
		dispatch(setLoader({ isLoading: false, message: 'Please wait...' }));

		hideTerminalAutoSaveDialog();
		await deleteTerminalAutoSave.mutateAsync(undefined);
		handleCancelAutoSave();
	};

	useEffect(() => {
		if (activeType === 'channel') {
			refetchBranchAutoSave();
			if (allSuccess) {
				let payload = {};
				channelResult.map((data: any, index) => {
					if (index === 0) {
						payload = { primaryInfo: data.data.data };
					}
					if (index === 1) {
						payload = { ...payload, contractDetails: data.data.data };
					}
					if (index === 2) {
						payload = { ...payload, reports: data.data.data };
					}
				});
				dispatch(setChannelFormValues(payload));
			}
		} else if (activeType === 'branch') {
			if (!hasViewBranchPermission && activeTab === 'DetailsTab') {
				return showPermissionErrorModal();
			}
			if (isBranchError) {
				setIsErrorModalOpen(true);
			}
			if (isBranchSuccess && !!branchDetails && !isBranchFetching) {
				setIsErrorModalOpen(false);
				dispatch(setBranchFormValues(branchDetails));
			}
		} else if (activeType === 'terminal') {
			if (!hasViewTerminalPermission && activeTab === 'DetailsTab') {
				return showPermissionErrorModal();
			}
			if (activeTab === 'AuditTrail') return;
			if (isTerminalSuccess && !!terminalDetails) {
				dispatch(setTerminalFormValues(terminalDetails));
			}
		}
	}, [
		activeType,
		allSuccess,
		branchDetails,
		terminalDetails,
		isTerminalFetching,
		isBranchFetching,
		activeTab,
		selectedTerminal?.id,
	]);
	useEffect(() => {
		if (isTerminalFetching || isTerminalSuccess) {
			dispatch(
				setLoader({
					isLoading: isTerminalFetching,
					message: 'Please wait while data are being loaded',
				})
			);
		}
		if (isTerminalError) {
			dispatch(
				setLoader({
					isLoading: false,
					message: 'Please wait while data are being loaded',
				})
			);
			dispatch(setTerminalFormValues({}));
			setIsErrorModalOpen(true);
		}
	}, [isTerminalError, isTerminalSuccess, isTerminalFetching]);

	useEffect(() => {
		if (
			!(isBranchSuccess && !!branchDetails && !isBranchFetching) &&
			activeType === 'branch'
		) {
			dispatch(
				setLoader({
					isLoading: false,
					message: 'Please wait while data are being loaded',
				})
			);
		}
	}, [activeType, branchDetails, dispatch, isBranchFetching, isBranchSuccess]);

	const accessDeniedBodyText = (
		<>
			<div className={styles.confirmationHeader}>Access Denied</div>
			<div className={styles.subtext}>
				{`Sorry, you don't have access to this function.`}
				<br />
				Please contact your admin to grant access
				<br />
				permission. Thank you.
				<br />
				<br />
			</div>
			<PrimaryButton
				className={styles.successBtn}
				onClick={hidePermissionErrorModal}
			>
				Close
			</PrimaryButton>
		</>
	);
	const { terminalListQuery } = useList();
	const errorModalBodyText = (
		<>
			<div className={styles.confirmationHeader}>Timeout Error!</div>
			<div className={styles.subtext}>
				A problem occurred while loading the data.
				<br />
				Please try again.
				<br />
				<br />
				<br />
				<br />
			</div>
			<PrimaryButton
				className={styles.successBtn}
				onClick={() => {
					setIsErrorModalOpen(!isErrorModalOpen);
					dispatch(setLoader({ isLoading: true, message: 'Please wait...' }));
					if (activeType === 'terminal') {
						refetchTerminal();
					}
					if (activeType === 'branch') {
						terminalListQuery?.refetch();
						refetchBranch();
					}
				}}
			>
				Retry
			</PrimaryButton>
		</>
	);
	const handleTerminalDisabled = (value) => {
		setIsTerminalDisabled(value);
	};
	const handleTerminalDirty = (value) => {
		setIsTerminalDirty(value);
	};
	return (
		<div className={cx('slds-card', styles.paperContainer)}>
			{(showChannelAutoSave || showBranchAutoSave || showTerminalAutoSave) && (
				<Alert
					bodyText={`You have unfinished work for adding a new ${formatActiveType(
						activeType
					)}. Would you like to continue?`}
					cancelFn={async () => {
						dispatch(setLoader({ isLoading: true, message: 'Please wait...' }));
						if (selectedBranch?.id) {
							handleDeleteTerminalAutosave();
						} else if (selectedChannel?.id) {
							handleDeleteBranchAutosave();
						} else {
							await handleDeleteChannelAutosave();
						}
					}}
					confirmFn={() => {
						if (activeType === '') {
							showAddChannelProfileModal();
							setIsAutoSave(true);
						} else if (activeType === 'channel') {
							showBranchModal();
							setIsAutoSave(true);
						} else if (activeType === 'branch' || activeType === 'terminal') {
							showTerminalModal();
							setIsAutoSave(true);
						}
						// setFromAutoSave(true);
						// showAddPartnerProfileModal();
					}}
					iconLeft={() => (
						<WarningIcon
							onClick={() => {
								// Uncomment incase clicking close means dialog will be closed but autosave remains
								// if (activeType === '') {
								// 	hideChannelAutoSaveDialog();
								// } else if (activeType === 'channel') {
								// 	hideBranchAutoSaveDialog();
								// } else if (
								// 	activeType === 'branch' ||
								// 	activeType === 'terminal'
								// ) {
								// 	hideTerminalAutoSaveDialog();
								// }
							}}
						/>
					)}
				/>
			)}
			<div className={cx(styles.title)}>Channel Profile</div>
			<div className={cx('slds-grid slds-gutters', styles.mainContainer)}>
				<ChannelManagementList
					addNewChannelButtonClick={
						hasAddChannelPermission
							? showAddChannelProfileModalViewClear
							: showPermissionErrorModal
					}
					addNewBranchButtonClick={
						hasAddBranchPermission ? showBranchModal : showPermissionErrorModal
					}
					addNewTerminalButtonClick={
						hasAddTerminalPermission
							? showTerminalModal
							: showPermissionErrorModal
					}
					isAddBranchModalShowing={isAddBranchModalShowing}
					isTerminalDisabled={isTerminalDisabled}
					isTerminalDirty={isTerminalDirty}
					isChannelEditMode={isChannelEditMode}
					setIsChannelEditMode={setIsChannelEditMode}
					isChannelDirty={isChannelDirty}
				/>
				{(activeType === 'channel' || activeType === '') && (
					<ChannelTab2
						isChannelDirty={isChannelDirty}
						isChannelEditMode={isChannelEditMode}
						setIsChannelEditMode={setIsChannelEditMode}
						setIsChannelDirty={setIsChannelDirty}
						refetchChannel={() => {
							channelResult[0].refetch();
							channelResult[1].refetch();
							channelResult[2].refetch();
						}}
					/>
				)}
				{activeType === 'branch' && (
					<BranchTab
						isBranchError={isBranchError}
						refetchBranch={refetchBranch}
					/>
				)}
				{activeType === 'terminal' && (
					<TerminalTab
						refetchTerminal={refetchTerminal}
						terminalId={selectedTerminal.id}
						isDisabled={isTerminalDisabled}
						setIsDisabled={handleTerminalDisabled}
						setIsDirty={handleTerminalDirty}
						isTerminalError={isTerminalError}
					/>
				)}
			</div>

			{isAddChannelProfileModalShowing && (
				<ChannelModal
					isAutoSave={isAutoSave}
					refetchAutoSave={handleCancelAutoSave}
					deleteChannelAutoSave={handleDeleteChannelAutosave}
					setIsAutoSave={setIsAutoSave}
					autoSaveValue={channelAutoSave}
					open={isAddChannelProfileModalShowing}
					onClose={() => {
						hideAddChannelProfileModal();
						setIsAutoSave(false);
					}}
					mode={FormModeType.ADD}
					disabledForm={false}
				/>
			)}
			{activeType === 'channel' && isAddBranchModalShowing && (
				<BranchModal
					isAutoSave={isAutoSave}
					autoSaveValue={branchAutoSave}
					open={isAddBranchModalShowing}
					onClose={() => {
						handleDeleteBranchAutosave();
						hideAddBranchModal();
						setIsAutoSave(false);
					}}
					channelPrimaryInfo={channelPrimaryInfo}
					channelContractDetails={channelContractDetails}
					selectedChannelId={selectedChannelId}
					mode="ADD"
					refetch={refetchBranchAutoSave}
				/>
			)}
			{(activeType === 'branch' || activeType === 'terminal') &&
				!isTerminalAutoSaveLoading &&
				isAddTerminalModalShowing && (
					<TerminalModal
						isModal={true}
						isAutoSave={isAutoSave}
						autoSaveValue={terminalAutoSave}
						open={isAddTerminalModalShowing}
						onClose={() => {
							handleDeleteTerminalAutosave();
							hideAddTerminalModal();
							setIsAutoSave(false);
						}}
						mode="ADD"
					/>
				)}
			{isErrorModalOpen && (
				<ErrorModal
					open={isErrorModalOpen}
					onClose={() => setIsErrorModalOpen(!isErrorModalOpen)}
				>
					<ErrorModalBody>{errorModalBodyText}</ErrorModalBody>
				</ErrorModal>
			)}
			{hasPermission && (
				<ErrorModal open={hasPermission} onClose={hidePermissionErrorModal}>
					<ErrorModalBody>{accessDeniedBodyText}</ErrorModalBody>
				</ErrorModal>
			)}
			{loader.isLoading && (
				<FullPageLoader open={loader.isLoading} message={loader.message} />
			)}
		</div>
	);
};

export default connect((state) => state, { clearSelectedChannel })(
	ChannelProfile
);
