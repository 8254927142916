import Section from 'components/Section/Section';
import Configurations from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/Configurations';
import FtpContainer from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/FtpContainer';
import EmailAddressFields from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SMTP/EmailAddressFields/EmailAddressFields';
import { NAME } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SMTP/constants';
import { SmtpProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SMTP/types';

const SMTP: React.FC<SmtpProps> = ({ disabled, disabledEmail }) => {
	return (
		<>
			<Section title="SMTP"></Section>
			<EmailAddressFields disabled={disabled} disabledEmail={disabledEmail} />
			<FtpContainer name={NAME} disabled={disabled} />
			<Configurations
				name={`${NAME}.uploadConfig`}
				title="Upload Configuration"
				disabled={disabled}
			/>
		</>
	);
};

export default SMTP;
