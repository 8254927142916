import React from 'react';
import cx from 'classnames';

import FilterInner from './FilterInner';
import FilterOuter from './FilterOuter';
import styles from './Filter.module.css';
import RenderByType from '../common/RenderByType';
import { ReactNode } from 'react';

type Section = {
	children: ReactNode;
};
const Inner: React.FC<Section> = ({ children }) => (
	<RenderByType type={FilterInner}>{children}</RenderByType>
);
const Outer: React.FC<Section> = ({ children }) => (
	<RenderByType type={FilterOuter}>{children}</RenderByType>
);

type FilterContainerProps = {
	title: string;
	children: React.ReactNode;
};

const FilterContainer: React.FC<FilterContainerProps> = ({
	children,
	title,
}) => {
	return (
		<div className={cx('slds-card', styles.paperContainer)}>
			<div className='slds-grid sls-grid_align-spread'>
				<div className={cx('slds-col', styles.title)}>{title}</div>
				<div className='slds-col'>
					<Outer>{children}</Outer>
				</div>
			</div>
			<Inner>{children}</Inner>
		</div>
	);
};

export default FilterContainer;
