import Filter, { FilterInner, FilterOuter } from '../../components/Filter';
import InputWithIcon from '../../components/Inputs/InputWithIcon/InputWithIcon';
import styles from './VoidList.module.css';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import OutlineButton from '../../components/Buttons/OutlineButton';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { debounce as _debounce } from 'lodash';
import FilterSelect from './Filter/FilterSelect';
import DatePicker, {
	validateDateFrom,
	validateDateTo,
} from './Filter/Datepicker';
import ChannelNameFilter from './Filter/ChannelNameFilter';
import CreateChannelWallet from '../../components/ChannelWallet/CreateChannelWallet';
import moment from 'moment';
import DropdownCheckbox from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import { VoidReasonMultiple } from 'utils/lookup';

type Props = {
	onSubmit: (values: any) => void;
	initialValues: any;
	canCreateChannelWallet: boolean;
	initialArray: any;
};

const shouldDisableSubmission = (values: any) => {
	const { dateFrom, dateTo, channelName, serviceType, status, channelLevel } =
		values;
	return ![
		channelName,
		serviceType,
		status,
		dateFrom,
		dateTo,
		channelLevel,
	].some((v) => v);
};

const VoidListFilter: React.FC<Props> = ({
	onSubmit,
	initialValues = {},
	canCreateChannelWallet,
	initialArray = [],
}) => {
	const {
		register,
		getValues,
		setValue,
		handleSubmit,
		control,
		watch,
		formState: { errors, isValid, isDirty },
		reset,
	} = useForm({
		mode: 'all',
		defaultValues: { filter: initialValues },
	});

	const [initialized, setInitialized] = useState(false);
	const [isReset, setIsReset] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [valList, setValList] = useState(initialArray);
	const [voidReasonTxt, setVoidReasonTxt] = useState('Select Void Reason');

	const handleFilterSubmit = (values: any) => {
		values.voidReason = valList;
		onSubmit(values || {});
	};

	const transactionNumber = watch('filter.transactionNumber');
	const allValues = watch('filter');

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const filters = getValues('filter') || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...filters, transactionNumber: value });
			}
		}, 500),
		[isDirty, isValid, transactionNumber]
	);

	const handleReset = () => {
		if (isDirty) {
			reset({ filter: initialValues });
			setValue('filter', initialValues);
			window.location.reload();
			handleFilterSubmit(initialValues);
			setValList([]);
			setIsReset(true);
		}
	};

	const onChange = (value: any) => {
		let array: any = [];
		if (valList.length > 0) {
			array = [...valList];
			const index = array.indexOf(value);

			if (index !== -1) {
				array.splice(index, 1);
				setValList(array);
			} else {
				array.push(value);
				setValList(array);
			}
		} else {
			array.push(value);
			setValList(array);
		}
	};

	const handleShowCreateModal = () => {
		setShowModal(!showModal);
	};

	useEffect(() => {
		setInitialized(true);
	}, []);
	useEffect(() => {
		if (initialized && !isReset) {
			handleSearch(transactionNumber);
		}
	}, [transactionNumber]);

	useEffect(() => {
		if (isReset) {
			setIsReset(false);
		}
	}, [isReset]);

	useEffect(() => {
		if (valList.length > 0)
			setVoidReasonTxt(`${valList.length} Items Selected`);
		else setVoidReasonTxt('Select Void Reason');
	}, [valList]);

	return (
		<>
			{showModal && (
				<CreateChannelWallet open={showModal} onClose={handleShowCreateModal} />
			)}
			<Filter title="Void Request List">
				<FilterOuter>
					<div className="slds-col">
						<div className="slds-grid slds-grid_align-end slds-gutters">
							<div className="slds-col slds-size_6-of-12">
								<InputWithIcon
									placeholder="Search Transaction Ref No. / Request ID"
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
											className: styles.inputIcon,
										},
									]}
									{...register('filter.transactionNumber')}
								/>
							</div>
						</div>
					</div>
				</FilterOuter>
				<FilterInner>
					<div
						className={cx(
							'slds-grid',
							'slds-grid_align-spread',
							'slds-grid_vertical-align-start',
							styles.filters
						)}
					>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_2-of-12'
							)}
						>
							<DatePicker
								register={register}
								control={control}
								name="filter.dateFrom"
								label="Date From"
								errors={errors}
								validate={(v): any =>
									validateDateFrom(v, getValues('filter.dateTo'))
								}
							/>
						</div>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_2-of-12'
							)}
						>
							<DatePicker
								register={register}
								control={control}
								name="filter.dateTo"
								label="Date To"
								errors={errors}
								validate={(v): any =>
									validateDateTo(getValues('filter.dateFrom'), v)
								}
							/>
						</div>
						{/* <div className="slds-col slds-size_3-of-12">
							<ChannelNameFilter name="filter.channelName" control={control} />
						</div> */}
						{/* <div className="slds-col slds-size_3-of-12">
							<FilterSelect
								name="filter.walletType"
								label="Wallet Type"
								control={control}
								options={[
									{
										label: 'Channel Wallet',
										value: 'CHANNEL',
									},
									{
										label: 'Consumer Wallet',
										value: 'CONSUMER',
									},
								]}
							/>
						</div> */}
						<div className="slds-col slds-size_3-of-12">
							<DropdownCheckbox
								label="Void Reason"
								placeholder={voidReasonTxt}
								name="filter.channelLevel"
								control={control}
								options={VoidReasonMultiple}
								onChange={onChange}
							/>
						</div>
						<div className="slds-col slds-size_3-of-12">
							<FilterSelect
								name="filter.status"
								label="Void Request Status"
								control={control}
								options={[
									{ label: 'Approved', value: 'Approved' },
									{ label: 'Pending', value: 'Pending' },
									{ label: 'Rejected', value: 'Rejected' },
									{ label: 'Expired', value: 'Expired' },
								]}
							/>
						</div>
						<div
							className={cx(
								'slds-col',
								'slds-size_3-of-12',
								'slds-grid',
								'slds-grid_vertical-align-end',
								'slds-grid_align-spread',
								styles.innerButtons
							)}
						>
							<div
								className={cx(
									styles.clearFilterBtnContainer,
									'slds-col',
									'slds-size_4-of-7'
								)}
							>
								<OutlineButton fullWidth onClick={handleReset}>
									Clear All Filters
								</OutlineButton>
							</div>
							<div className="slds-col slds-size_3-of-7">
								<PrimaryButton
									disabled={shouldDisableSubmission(allValues)}
									onClick={handleSubmit(({ filter = {} }) =>
										handleFilterSubmit(filter)
									)}
									fullWidth
								>
									Filter
								</PrimaryButton>
							</div>
						</div>
					</div>
				</FilterInner>
			</Filter>
		</>
	);
};

export default VoidListFilter;
