import { createSlice } from '@reduxjs/toolkit';
import transmgtIcon from '../../assets/icons/ic-transmgt.svg';
import transmgtBlackIcon from '../../assets/icons/ic-transmgt-black.svg';
import centralWalletIcon from '../../assets/icons/ic-wallet.svg';
import centralWalletIconBlack from '../../assets/icons/ic-wallet-black.svg';
import billerMgtIcon from '../../assets/icons/ic-biller.svg';
import billerMgtIconBlack from '../../assets/icons/ic-biller-black.svg';
import channelMgtIcon from '../../assets/icons/ic-channel.svg';
import channelMgtIconBlack from '../../assets/icons/ic-channel-black.svg';
import productMgtIcon from '../../assets/icons/ic-product.svg';
import productMgtIconBlack from '../../assets/icons/ic-product-black.svg';
import businessReportIcon from '../../assets/icons/ic-reports-white.svg';
import businessReportIconBlack from '../../assets/icons/ic-reports-dark.svg';

export type Submenu = {
	id: string;
	link: string;
	name: string;
	collapsed?: boolean;
	collapsible?: boolean;
	extendCollapsed?: boolean;
	exact?: boolean;
	submenus?: Submenu[];
	submenulinks?: Submenu[];
	itemId?: any;
};
export type MenuItem = {
	id: string;
	collapsed?: boolean;
	link?: string;
	label: string;
	icon: {
		collapsed: string;
		expanded: string;
	};
	submenus: Submenu[];
};

export type SideBarSlice = {
	isCollapsed: boolean;
	itemId?: any;
	products: Array<any>;
	menuItems: MenuItem[];
};

export const PRODUCT_ROUTE_MAPPING = {
	BPY: 'bills-payment',
	BUY_LOAD: 'buy-load',
	REMITTANCE: 'remittance',
	CASH_OUT: 'cash-out-pay-out',
	CASH_IN: 'cash-in-top-up',
	E_CTPL: 'e-ctpl',
	INSTASURANCE: 'instasurance',
	BAYAD_CHECKOUT: 'bayad-checkout',
	BAYAD_CHECKOUT_OTC: 'bayad-checkout-via-otc',
	BAYAD_ATM: 'bayad-atm',
	BAYAD_MED: 'bayad-med-assist',
	BAYAD_TRAVEL: 'bayad-travel',
};

export const defaultProductModules: Array<any> = [
	{
		id: 'products',
		collapsed: false,
		label: 'Product Management',
		link: '/products/',
		icon: {
			collapsed: productMgtIconBlack,
			expanded: productMgtIcon,
		},
		submenus: [
			{
				id: 'management',
				link: '/products/management/',
				name: 'Products',
				collapsible: true,
				collapsed: true,
				extendCollapsed: true,
				submenus: [
					{
						id: 'bills-payment',
						name: 'Bills Payment',
						link: '/products/management/bills-payment',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/bills-payment/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/bills-payment/drafts',
							},
						],
					},
					{
						id: 'buy-load',
						name: 'Buy Load',
						link: '/products/management/buy-load',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/buy-load/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/buy-load/drafts',
							},
						],
					},
					{
						id: 'remittance',
						name: 'Remittance',
						link: '/products/management/remittance',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/remittance/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/remittance/drafts',
							},
						],
					},
					{
						id: 'cash-out-pay-out',
						name: 'Cash-out / Pay-out',
						link: '/products/management/cash-out-pay-out',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/cash-out-pay-out/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/cash-out-pay-out/drafts',
							},
						],
					},
					{
						id: 'cash-in-top-up',
						name: 'Cash-in / Top-up',
						link: '/products/management/cash-in-top-up',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/cash-in-top-up/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/cash-in-top-up/drafts',
							},
						],
					},
					{
						id: 'e-ctpl',
						name: 'e-CTPL',
						link: '/products/management/e-ctpl',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/e-ctpl/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/e-ctpl/drafts',
							},
						],
					},
					{
						id: 'instasurance',
						name: 'Instasurance',
						link: '/products/management/instasurance',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/instasurance/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/instasurance/drafts',
							},
						],
					},
					{
						id: 'bayad-checkout',
						name: 'Bayad Checkout',
						link: '/products/management/bayad-checkout',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/bayad-checkout/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/bayad-checkout/drafts',
							},
						],
					},
					{
						id: 'bayad-checkout-via-otc',
						name: 'Bayad Checkout (via OTC)',
						link: '/products/management/bayad-checkout-via-otc',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/bayad-checkout-via-otc/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/bayad-checkout-via-otc/drafts',
							},
						],
					},
					{
						id: 'bayad-atm',
						name: 'Bayad ATM',
						link: '/products/management/bayad-atm',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/bayad-atm/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/bayad-atm/drafts',
							},
						],
					},
					{
						id: 'bayad-med-assist',
						name: 'Bayad Med-assist',
						link: '/products/management/bayad-med-assist',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/bayad-med-assist/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/bayad-med-assist/drafts',
							},
						],
					},
					{
						id: 'bayad-travel',
						name: 'Bayad Travel',
						link: '/products/management/bayad-travel',
						collapsible: true,
						collapsed: true,
						submenulinks: [
							{
								id: 'approvals',
								name: 'Approvals',
								link: '/products/management/bayad-travel/approvals',
							},
							{
								id: 'drafts',
								name: 'Drafts',
								link: '/products/management/bayad-travel/drafts',
							},
						],
					},
				],
			},
		],
	},
];
const initialState: SideBarSlice = {
	isCollapsed: false,
	products: [],
	itemId: 1,
	menuItems: [
		{
			id: 'transaction-management',
			collapsed: false,
			label: 'Transaction Management',
			icon: {
				collapsed: transmgtBlackIcon,
				expanded: transmgtIcon,
			},
			submenus: [
				{
					id: 'transaction-list',
					link: '/',
					name: 'Transaction List',
				},
				{
					id: 'report-summary',
					link: '/report-summary',
					name: 'Report Summary',
				},
				{
					id: 'void-list',
					link: '/void-list',
					name: 'Void Request List',
				},
			],
		},
		{
			id: 'wallet-system',
			collapsed: true,
			label: 'Central Wallet System',
			link: '/wallet-system/',
			icon: {
				collapsed: centralWalletIconBlack,
				expanded: centralWalletIcon,
			},
			submenus: [
				{
					id: 'wallet-system-dashboard',
					link: '/wallet-system/',
					exact: true,
					name: 'Dashboard',
				},
				{
					id: 'management',
					link: '/wallet-system/management/cash-flow',
					name: 'Wallet Management',
					collapsible: true,
					collapsed: true,
					submenus: [
						{
							id: 'cash-flow',
							name: 'Cash Flow',
							link: '/wallet-system/management/cash-flow',
						},
						{
							id: 'adjustment',
							name: 'Wallet Adjustment',
							link: '/wallet-system/management/adjustment',
						},
						{
							id: 'replenishment',
							name: 'Wallet Replenishment',
							link: '/wallet-system/management/replenishment',
						},
						{
							id: 'list',
							name: 'Wallet List',
							link: '/wallet-system/management/list',
						},
					],
				},
			],
		},
		// {
		// 	id: 'biller-management',
		// 	collapsed: true,
		// 	label: 'Biller Management',
		// 	link: '/biller-management/',
		// 	icon: {
		// 		collapsed: billerMgtIconBlack,
		// 		expanded: billerMgtIcon,
		// 	},
		// 	submenus: [
		// 		{
		// 			id: 'biller-management-dashboard',
		// 			link: '/biller-management/',
		// 			name: 'Dashboard',
		// 		},
		// 		{
		// 			id: 'biller-management-profile',
		// 			link: '/biller-management/profile',
		// 			name: 'Biller Profile',
		// 		},
		// 		{
		// 			id: 'biller-management-status',
		// 			link: '/biller-management/status',
		// 			name: 'Biller Status',
		// 		},
		// 	],
		// },
		{
			id: 'channel-management',
			collapsed: true,
			label: 'Channel Management',
			link: '/channel-management/',
			icon: {
				collapsed: channelMgtIconBlack,
				expanded: channelMgtIcon,
			},
			submenus: [
				{
					id: 'channel-management-profile',
					link: '/channel-management/',
					name: 'Channel Profile',
					collapsible: true,
					collapsed: true,
					submenus: [
						{
							id: `approvals`,
							name: 'Approvals',
							link: `/channel-management/approvals`,
						},
						{
							id: `drafts`,
							name: 'Drafts',
							link: `/channel-management/drafts`,
						},
					],
				},
			],
		},
		{
			id: 'products',
			collapsed: true,
			label: 'Product Management',
			link: '/products/',
			icon: {
				collapsed: productMgtIconBlack,
				expanded: productMgtIcon,
			},
			submenus: [
				{
					id: 'management',
					link: '/products/management/',
					name: 'Products',
					collapsible: true,
					collapsed: true,
					extendCollapsed: true,
					submenus: [],
				},
			],
		},
		{
			id: 'business-reports',
			collapsed: true,
			label: 'Business Reports & Generation',
			link: '/business-reports',
			icon: {
				collapsed: businessReportIconBlack,
				expanded: businessReportIcon,
			},
			submenus: [
				{
					id: 'reports',
					link: '/business-reports/reports',
					name: 'Reports',
					collapsible: true,
					collapsed: true,
					extendCollapsed: true,
					submenus: [],
				},
				{
					id: 'reports-maintenance',
					link: '/business-reports/reports-maintenance',
					name: 'Report Maintenance',
					collapsible: true,
					collapsed: true,
					extendCollapsed: true,
					submenus: [],
				},
			],
		},
	],
};

const sidebar = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		prep: () => {},
		load: (state, payload: any) => {
			const productModules = [
				{
					id: 'products',
					collapsed: true,
					label: 'Product Management',
					link: '/products/',
					icon: {
						collapsed: productMgtIconBlack,
						expanded: productMgtIcon,
					},
					submenus: [
						{
							id: 'management',
							link: '/products/management/bills-payment',
							name: 'Products',
							collapsible: true,
							collapsed: true,
							extendCollapsed: true,
							submenus:
								payload.result.data.map((p) => {
									const routeCode = PRODUCT_ROUTE_MAPPING[p.code] || '';
									const submenu = {
										id: p.code,
										itemId: p.id,
										name: p.name,
										link: `/products/management/${routeCode}`,
										collapsible: true,
										collapsed: p.code === 'BPY' ? false : true,
										submenulinks: [
											{
												id: `approvals-${routeCode}`,
												name: 'Approvals',
												link: `/products/management/${routeCode}/approvals`,
											},
											{
												id: `drafts-${routeCode}`,
												name: 'Drafts',
												link: `/products/management/${routeCode}/drafts`,
											},
										],
									};

									return submenu;
								}) || [],
						},
					],
				},
			];
			state.menuItems = [
				...state.menuItems.filter((m) => m.id !== 'products'),
				...productModules,
			];
			state.products = payload.result.data;
		},
		error: (state) => {},
		collapseMenu: (state) => {
			state.isCollapsed = !state.isCollapsed;
		},
		toggleMenuItem: (state, { payload: menuItemId }) => {
			state.menuItems = state.menuItems.map((item) => {
				const { id, collapsed } = item;
				if (id === menuItemId) {
					return {
						...item,
						collapsed: !collapsed,
					};
				}
				return item;
			});
		},
		toggleSubMenuItem: (state, { payload }) => {
			const { menuItemId, submenuItemId } = payload;
			state.menuItems = state.menuItems.map((item) => {
				const { id, submenus } = item;
				if (id === menuItemId) {
					const newSubmenus = (submenus || []).map((submenu) => {
						const { id: currSubmenuId, collapsed } = submenu;
						if (currSubmenuId === submenuItemId) {
							return {
								...submenu,
								collapsed: !collapsed,
							};
						}

						return submenu;
					});

					return {
						...item,
						submenus: newSubmenus,
					};
				}
				return item;
			});
		},
		toggleSubMenuListItem: (state, { payload }) => {
			const { menuItemId, submenuItemId, submenuItemId2ndDepth } = payload;
			state.menuItems = state.menuItems.map((item) => {
				const { id, submenus } = item;
				if (id === menuItemId) {
					(submenus || []).map((submenu) => {
						if (submenu.id === submenuItemId) {
							const { submenus: submenu2ndDepth = [] } = submenu;
							const secondDepth = (submenu2ndDepth || []).map(
								(submenu2ndDepth) => {
									if (submenu2ndDepth.id === submenuItemId2ndDepth) {
										submenu2ndDepth.collapsed = !submenu2ndDepth.collapsed;
										state.itemId = submenu2ndDepth.itemId;
									}

									return submenu2ndDepth;
								}
							);

							return { ...submenu, submenus: secondDepth };
						}
						return submenu;
					});
				}
				return item;
			});
		},
		// changing itemId when selecting submenu without collapsing
		selectSubMenuItem: (state, { payload }) => {
			const { menuItemId, submenuItemId, submenuItemId2ndDepth } = payload;
			state.menuItems = state.menuItems.map((item) => {
				const { id, submenus } = item;
				if (id === menuItemId) {
					(submenus || []).map((submenu) => {
						if (submenu.id === submenuItemId) {
							const { submenus: submenu2ndDepth = [] } = submenu;
							const secondDepth = (submenu2ndDepth || []).map(
								(submenu2ndDepth) => {
									if (submenu2ndDepth.id === submenuItemId2ndDepth) {
										state.itemId = submenu2ndDepth.itemId;
									}

									return submenu2ndDepth;
								}
							);

							return { ...submenu, submenus: secondDepth };
						}
						return submenu;
					});
				}
				return item;
			});
		},
	},
});

function getProductLinks(): any {
	return {
		types: [prep.type, load.type, error.type],
		promise: (client) => client.get('v2/products/types'),
	};
}

export default sidebar.reducer;

const {
	collapseMenu,
	toggleMenuItem,
	toggleSubMenuItem,
	toggleSubMenuListItem,
	selectSubMenuItem,
	prep,
	load,
	error,
} = sidebar.actions;

export {
	collapseMenu,
	toggleMenuItem,
	toggleSubMenuItem,
	toggleSubMenuListItem,
	selectSubMenuItem,
	getProductLinks,
};
