import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@salesforce/design-system-react/components/button';
import { ReactComponent as EditIcon } from 'assets/icons/ic-edit.svg';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ConfirmationModal from 'components/ChannelConfirmationModal/ConfirmationModal';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorPermissionModal, {
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import Section, { SectionRow } from 'components/Section/Section';
import BranchForm from 'containers/ChannelManagement/Branch/BranchForm/BranchForm';
import SuccessModal from 'containers/ChannelManagement/Branch/BranchForm/SuccessModal/SuccessModal';
import {
	BranchFormModeType,
	BranchType,
} from 'containers/ChannelManagement/Branch/BranchForm/types';
import {
	putForBranchSave,
	transformBranchFormToPayload,
} from 'containers/ChannelManagement/Branch/BranchForm/utils';
import { BranchDetailsProps } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/types';
import StatusUpdate from 'containers/ChannelManagement/Channel/ChannelStatusSelect/StatusUpdate';
import ErrorModal from 'containers/ChannelManagement/Drafts/ErrorModal/ErrorModal';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useToggle } from 'utils/hooks';
import { useHasUserPermission } from 'utils/permissions';
import styles from './BranchDetails.module.css';
import { branchSchema } from 'containers/ChannelManagement/Branch/BranchForm/BranchSchema/BranchSchema';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FixMeLater } from 'types';
import { ReducerStateType } from 'redux/modules/reducers';
import { selectedBranch } from 'redux/modules/channelList';

const BranchDetails: React.FC<BranchDetailsProps> = ({
	defaultValues,
	updatedAt,
	updatedBy,
	channelAccountId,
	branchId,
	branchStatus,
	refetchBranch,
}) => {
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [updatedAtState, setUpdatedAtState] = useState('');
	const [updatedByState, setUpdatedByState] = useState('');
	const confirmationMessage = useRef<JSX.Element>(<></>);
	const confirmationHeader = useRef<string>('');
	const status = useRef<'Saving' | 'Exiting' | ''>('');
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const errorHeader = useRef<string>('');
	const errorMessage = useRef<JSX.Element>(<></>);
	const errorButton = useRef<string>('');
	const successMessage = useRef<string>('');
	const successOkayLabel = useRef<string>('');

	const dispatch = useDispatch();

	const selectedBranchState = useSelector(
		(state: FixMeLater) => state.channels.selectedBranch
	);

	const [isActivationDateDirty, setActivationDateDirty] = useState<
		boolean | undefined
	>(false);

	const [isToValidateRemarksChanges, setIsToValidateRemarksChanges] =
		useState<boolean>(false);

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const _userInfo = useSelector<ReducerStateType, FixMeLater>(
		(state) => state.userInfo
	);

	const mode: BranchFormModeType = 'EDIT';

	const branchForm = useForm<BranchType>({
		mode: 'all',
		defaultValues: defaultValues,
		resolver: yupResolver(branchSchema),
		context: { mode, isToValidateRemarksChanges, isActivationDateDirty },
	});

	const {
		formState: {
			isValid,
			isDirty,
			dirtyFields: { branchOperatingSchedule },
		},
		trigger,
		watch,
	} = branchForm;

	const watchRemarksForAuditTrail = watch(
		'branchBasicInformation.remarksChanges'
	);

	const activationDateDirtyStatus = branchOperatingSchedule?.activationDate;

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isShowErrorModal,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: hasPermission,
		valueOn: showPermissionErrorModal,
		valueOff: hidePermissionErrorModal,
	} = useToggle();

	const branchName = useWatch<BranchType>({
		control: branchForm.control,
		name: 'branchBasicInformation.branchName',
	});

	useEffect(() => {
		setUpdatedAtState(updatedAt);
		setUpdatedByState(updatedBy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setActivationDateDirty(activationDateDirtyStatus);
	}, [activationDateDirtyStatus]);

	useEffect(() => {
		if (isSubmit) {
			const submit = async (data: BranchType) => {
				const dataWithRemarksForAuditTrail = structuredClone(data);
				dataWithRemarksForAuditTrail.branchBasicInformation.remarksChanges =
					watchRemarksForAuditTrail;
				showLoading();
				try {
					await putForBranchSave(
						transformBranchFormToPayload(
							dataWithRemarksForAuditTrail,
							branchStatus,
							channelAccountId
						),
						branchId
					);
					successMessage.current =
						'The changes you made were successfully saved	.';
					successOkayLabel.current = 'Done';
					branchForm.reset(data);
					showSuccessModal();
					const branch = { ...selectedBranchState, name: branchName };
					dispatch(selectedBranch({ branch }));
				} catch (error: FixMeLater) {
					if (error?.response?.status !== 403) {
						errorHeader.current = 'Timeout Error!';
						errorButton.current = 'Retry';
						let cignalCodeError = '';
						let pldtCodeError = '';

						if (
							error?.response?.data?.errors?.primaryInfo?.cignalCode?.includes(
								'already exist'
							) ||
							error?.response?.data?.errors?.primaryInfo?.pldtCode?.includes(
								'already exist'
							)
						) {
							errorHeader.current = 'Error!';
							errorButton.current = 'Okay';
							status.current = '';
							if (
								error?.response?.data?.errors?.primaryInfo?.cignalCode?.includes(
									'already exist'
								)
							) {
								cignalCodeError = 'Cignal code already exist.';
							}

							if (
								error?.response?.data?.errors?.primaryInfo?.pldtCode?.includes(
									'already exist'
								)
							) {
								pldtCodeError = 'Pldt code already exist. ';
							}
						}

						errorMessage.current = (
							<>
								<p>A problem occurred while saving the branch.</p>
								{cignalCodeError && <p>{cignalCodeError}</p>}
								{pldtCodeError && <p>{pldtCodeError}</p>}
								<p>Please try again.</p>
							</>
						);

						showErrorModal();
					}
				}
				hideLoading();
			};

			branchForm.handleSubmit((data) => {
				submit(data);

				setUpdatedAtState(
					// eslint-disable-next-line react-hooks/exhaustive-deps
					(updatedAt = moment().format('MM/DD/YYYY hh:mm:ss a'))
				);
				// eslint-disable-next-line react-hooks/exhaustive-deps
				setUpdatedByState((updatedBy = _userInfo.username));
			})();

			setIsSubmit(false);
		}
	}, [
		branchForm,
		branchId,
		channelAccountId,
		hideLoading,
		isSubmit,
		showErrorModal,
		showLoading,
		showSuccessModal,
	]);

	const accessDeniedBodyText = (
		<>
			<div className={styles.confirmationHeader}>Access Denied</div>
			<div className={styles.subtext}>
				{`Sorry, you don't have access to this function.`}
				<br />
				Please contact your admin to grant access
				<br />
				permission. Thank you.
				<br />
				<br />
			</div>
			<PrimaryButton
				className={styles.successBtn}
				onClick={hidePermissionErrorModal}
			>
				Close
			</PrimaryButton>
		</>
	);

	const hasUserPermission = useHasUserPermission('branches');

	const { hasEditBranchPermission, hasViewBranchPermission } = useMemo(() => {
		return {
			hasEditBranchPermission: hasUserPermission('edit'),
			hasViewBranchPermission: hasUserPermission('view'),
		};
	}, [hasUserPermission]);

	const hasEditStatusPermission = hasUserPermission('edit.status');

	useEffect(() => {
		if (!hasViewBranchPermission) {
			showPermissionErrorModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasViewBranchPermission]);

	return (
		<>
			{hasViewBranchPermission ? (
				<>
					<Section>
						<SectionRow verticalAlign="center">
							<Grid column size={6} of={12}>
								<div className={styles.title}>{branchName}</div>
							</Grid>
							<Grid column size={6} of={12}>
								<Grid container align="end">
									<Button
										onClick={() => {
											if (hasEditBranchPermission) {
												if (!isDisabled) {
													//Exit Edit Mode is clicked
													status.current = 'Exiting';
													confirmationHeader.current = 'Exit Edit Mode';
													if (isDirty) {
														confirmationMessage.current = (
															<>
																<br />
																Are you sure you want to exit without saving?
																<br />
																Any unsaved work will be lost.
																<br />
																<br />
																<br />
															</>
														);
													} else {
														confirmationMessage.current = (
															<>
																<br />
																<br />
																Are you sure you want to exit edit mode?
																<br />
																<br />
																<br />
															</>
														);
													}

													showConfirmationModal();
												} else {
													//Edit Branch Details is clicked
													setIsDisabled((isDisabled) => !isDisabled);
												}
											} else {
												showPermissionErrorModal();
											}
										}}
										className={styles.linkBtn + ' ' + styles.edit}
									>
										<EditIcon />
										{isDisabled ? ' Edit Branch Details' : 'Exit Edit Mode'}
									</Button>
								</Grid>
							</Grid>
						</SectionRow>
						<SectionRow container className="slds-grid_align-spread">
							<Grid vertical={false} container verticalAlign="center">
								<Grid column>
									<div className={styles.statusLabel}>Branch Status:</div>
								</Grid>
								<Grid>
									<div
										className={styles.status}
										onClick={() => {
											if (!hasEditStatusPermission) {
												showPermissionErrorModal();
											}
										}}
									>
										<StatusUpdate
											id={branchId}
											idType="branches"
											value={branchStatus}
											onClose={() => {
												return;
											}}
											refetch={() => {
												refetchBranch();
											}}
										/>
									</div>
								</Grid>
							</Grid>
							<Grid column size={6} of={12}>
								<Grid container align="end">
									<div className={styles.titleActionTextContainer}>
										<em className={styles.titleActionText}>
											{'Last updated at ' + updatedAtState}
											{' by ' + updatedByState}
										</em>
									</div>
								</Grid>
							</Grid>
						</SectionRow>
					</Section>

					<FormProvider {...branchForm}>
						<BranchForm
							branchBasicInformationProp={{
								isDisabledAllEditableFields: isDisabled,
							}}
							branchAddress={{ isDisabledAllEditableFields: isDisabled }}
							branchContactDetails={{ disabled: isDisabled }}
							operatingScheduleProps={{ isDisabled: isDisabled }}
						/>
					</FormProvider>

					{!isDisabled && (
						<Section>
							<SectionRow align="end" verticalAlign="start">
								<Grid column className="slds-grow-none">
									<PrimaryButton
										onClick={async () => {
											const noErrors = await trigger(undefined, {
												shouldFocus: true,
											});

											if (noErrors) {
												status.current = 'Saving';
												confirmationHeader.current = 'Save Changes';
												confirmationMessage.current = (
													<div>
														<div className={styles.bodyHeader}>
															<p>
																Are you sure you want to save the changes made?
																<br></br> If yes, please input remarks.
															</p>
														</div>

														<div className={styles.bodyContent}>
															<TextField
																label={'Remarks'}
																labelClassName={styles.labelClassName}
																control={branchForm.control}
																name="branchBasicInformation.remarksChanges"
																multiLine
																required
																rows={5}
																fullWidth
																shouldUnregister
															/>
															<em className={styles.remarksSubLabel}>
																*Maximum of 1000 characters only.
															</em>
														</div>
													</div>
												);
												setIsToValidateRemarksChanges(true);
												showConfirmationModal();
											}
										}}
										className={styles.btn}
										disabled={!(isValid && isDirty)}
									>
										Save
									</PrimaryButton>
								</Grid>
							</SectionRow>
						</Section>
					)}

					{isLoading && (
						<FullPageLoader
							open={isLoading}
							message={'Please wait while changes are being saved'}
						/>
					)}
					{isConfirmationModalOpen && (
						<ConfirmationModal
							isOpen={isConfirmationModalOpen}
							heading={confirmationHeader.current}
							message={confirmationMessage.current}
							onClose={() => {
								hideConfirmationModal();
								setIsToValidateRemarksChanges(false);
							}}
							onCancelBtnClick={() => {
								hideConfirmationModal();
								setIsToValidateRemarksChanges(false);
							}}
							onConfirmBtnClick={() => {
								if (status.current === 'Exiting') {
									setIsDisabled((isDisabled) => !isDisabled);
									branchForm.reset();
								}
								if (status.current === 'Saving') {
									setIsSubmit(true);
								}
								setIsToValidateRemarksChanges(false);
								hideConfirmationModal();
							}}
							cancelBtnLabel={'Cancel'}
							confirmBtnLabel={'Confirm'}
							isPrimaryBtnDisabled={status.current === 'Saving' && !isValid}
							headerClassName={styles.header}
							containerClassName={styles.containerWidth}
						/>
					)}
					{isShowErrorModal && (
						<ErrorModal
							open={isShowErrorModal}
							onClose={hideErrorModal}
							errorHeader={errorHeader.current}
							errorMessage={errorMessage.current}
							onRetry={() => {
								hideErrorModal();

								if (status.current === 'Saving') {
									setIsSubmit(true);
								}
							}}
						/>
					)}
					{isSuccessModalOpen && (
						<SuccessModal
							open={isSuccessModalOpen}
							onClose={hideSuccessModal}
							successMessage={successMessage.current}
							onOkay={() => {
								refetchBranch();
								hideSuccessModal();
							}}
							okayLabel={successOkayLabel.current}
						></SuccessModal>
					)}
				</>
			) : null}
			{hasPermission && (
				<ErrorPermissionModal
					open={hasPermission}
					onClose={hidePermissionErrorModal}
				>
					<ErrorModalBody>{accessDeniedBodyText}</ErrorModalBody>
				</ErrorPermissionModal>
			)}
		</>
	);
};

export default BranchDetails;
