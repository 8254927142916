import { useEffect, useState } from 'react';
import { useToggle } from 'utils/hooks';
import { useFormContext, useWatch } from 'react-hook-form';
import cx from 'classnames';
import { BsPlus } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';
import Button from 'components/Buttons/Button';
import Grid from 'components/Grid/Grid';
import Section, { SectionRow } from 'components/Section/Section';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import UploadLogoModal from 'components/UploadLogoModal/UploadLogoModal';
import {
	BusinessGroup,
	ChannelTransactionType,
	ChannelTypePhysical,
	ChannelTypeDigital,
	BusinessType,
	PosType,
	SettlementSetup,
	PartnerShipTypes,
	BusinessGroupChannel,
} from 'utils/lookup';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelBasicInformation/ChannelBasicInformation.module.css';
import { UploadLogoResponse } from 'components/UploadLogoModal/UploadLogoResponse';
import { BasicInformationProps, LogoFieldProps } from './types';
import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import Input from 'components/Channel/Input/Input';
import { PrimaryInformationSchema } from '../PrimaryInformationSchema';
import RadioGroup from 'components/Channel/Radio/RadioGroup';
import { useList } from 'containers/ChannelManagement/List/hooks';

const LogoField: React.FC<LogoFieldProps> = ({
	onSuccess,
	image,
	disabled,
	isAdd,
}) => {
	const {
		value: isUploadLogoModalOpen,
		valueOn: showUploadLogoModal,
		valueOff: hideUploadLogoModal,
	} = useToggle();

	return (
		<div className={styles.logoFieldContainer}>
			<div className={cx(styles.avatar, { [styles.hasImage]: !!image })}>
				{image && image !== '' ? (
					<img src={image} className={styles.image} alt="biller-logo" />
				) : (
					<FaUserCircle />
				)}
				{disabled ? (
					<Button className={styles.uploadBtn}>
						<BsPlus />
					</Button>
				) : (
					<Button className={styles.uploadBtn} onClick={showUploadLogoModal}>
						<BsPlus />
					</Button>
				)}
			</div>
			<div className={styles.logoLabelContainer}>
				<div className={styles.logoLabel}>Upload Logo</div>
				<div className={styles.logoSubtext}>
					<em>*Maximum file size accepted:</em>
					<span className={styles.logoSubtextValue}>10MB</span>
				</div>
				<div className={styles.logoSubtext}>
					<em>*File types accepted:</em>
					<span className={styles.logoSubtextValue}>.jpg, .jpeg, .png</span>
				</div>
			</div>

			<UploadLogoModal
				open={isUploadLogoModalOpen}
				onClose={hideUploadLogoModal}
				onSuccess={onSuccess}
				url="/v2/channels/logo"
				action={isAdd ? 'add' : 'edit'} // #TODO
				selectedProductId={undefined}
				isChannel={true}
			/>
		</div>
	);
};

const BasicInformation: React.FC<BasicInformationProps> = ({
	disabled,
	isAdd,
	isAutoSave,
	autoSaveValue,
}) => {
	const { control, setValue, clearErrors } = useFormContext();

	const inCfs = useWatch({
		control,
		name: `primaryInfo.inCfs`,
	});

	const oldLogo = useWatch({
		control,
		name: `primaryInfo.logo`,
	});

	const channelTransactionType = useWatch({
		control,
		name: `primaryInfo.channelTransactionType`,
	});
	const channelType = useWatch({
		control,
		name: `primaryInfo.channelType`,
	});

	interface SelectOptions {
		label: string;
		value: number;
	}

	function compareLabels(a: SelectOptions, b: SelectOptions): number {
		const labelA = a.label.toUpperCase();
		const labelB = b.label.toUpperCase();

		if (labelA < labelB) {
			return -1;
		}
		if (labelA > labelB) {
			return 1;
		}
		return 0;
	}

	const businessType = useWatch({
		control,
		name: `primaryInfo.businessType`,
	});

	// const isOtherBusinessType = true;
	// businessType &&
	// !BusinessType.find(
	// 	(type) => type === businessType && 'others' !== businessType.toLowerCase()
	// );

	const { channelTypeFilter } = useList();

	const channelTypeOptions: SelectOption[] =
		channelTransactionType &&
		channelTransactionType?.toLowerCase() === 'digital'
			? ChannelTypeDigital.map((digital) => {
					const match: any = channelTypeFilter.find(
						(channelType) => channelType.label === digital.label
					);
					return {
						label: match?.label,
						value: match?.value,
					};
			  })
			: ChannelTypePhysical.map((physical) => {
					const match: any = channelTypeFilter.find(
						(channelType) => channelType.label === physical.label
					);
					return {
						label: match?.label,
						value: match?.value,
					};
			  });

	const isPosShown =
		channelTransactionType &&
		channelTransactionType?.toLowerCase() === 'physical';

	const isBusinessTypeShown = channelType && channelType === 2; // 2 = partner

	const isOtherBusinessTypeShown = isBusinessTypeShown && businessType === 14; // 14 = others

	const isShowThirdSection = isBusinessTypeShown;

	const [logo, setLogo] = useState('');

	useEffect(() => {
		if (oldLogo && oldLogo?.url && oldLogo.url !== '') {
			setLogo((prev) => {
				if (prev === '') {
					return oldLogo.url;
				}
				return prev;
			});
		} else if (isAutoSave) {
			return autoSaveValue.data.channelPrimaryInfo?.logoUrl
				? autoSaveValue.data.channelPrimaryInfo.logoUrl.includes('DEFAULT.png')
					? undefined
					: autoSaveValue.data.channelPrimaryInfo.logoUrl
				: undefined;
		}
	}, [oldLogo]);

	const handleUploadSuccess = (data: UploadLogoResponse) => {
		const { objectKey, url, file } = data;
		setValue(
			'primaryInfo.logo',
			{
				objectKey,
				url,
				filename: file?.name,
			},
			{ shouldDirty: true }
		);
		setLogo(URL.createObjectURL(data.file));
	};

	const TIN = () => (
		<Grid column size={1} of={3}>
			<TextField
				label="TIN"
				disabled={disabled}
				required
				control={control}
				name="basicInformation.tin"
			/>
		</Grid>
	);

	const prefixSchemaName = 'primaryInfo.';

	const logoImage = isAutoSave // check if isAutoSave
		? logo // check if user has uploaded a logo
			? logo // show user logo if there's an uploaded logo
			: autoSaveValue.data.channelPrimaryInfo?.logoUrl // Make sure autosave has value
			? autoSaveValue.data.channelPrimaryInfo?.logoUrl // show autosave logo if there's no uploaded logo
			: logo // Show Logo if there's no logo for user
		: logo; // show user logo if not autosave
	return (
		<Section title="Channel Basic Information">
			<SectionRow verticalAlign="end">
				<Grid column size={1} of={3}>
					<LogoField
						onSuccess={handleUploadSuccess}
						image={logoImage}
						onClick={() => console.log('Upload Clicked')}
						disabled={disabled}
						isAdd={isAdd}
					/>
				</Grid>
				<Grid column size={2} of={3}>
					<TextField
						name={`${prefixSchemaName}channelAccountName`}
						label={'Channel Account Name'}
						required
						control={control}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<RadioGroup
						name="primaryInfo.inCfs"
						label={PrimaryInformationSchema.getLabel('primaryInfo.inCfs')}
						required={PrimaryInformationSchema.isRequired('primaryInfo.inCfs')}
						defaultValue={PrimaryInformationSchema.getDefaultValue(
							'primaryInfo.inCfs'
						)}
						disabled={disabled}
						options={[
							{
								label: 'Yes',
								value: '1',
							},
							{
								label: 'No',
								value: '0',
							},
						]}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						name="primaryInfo.channelAccountCode"
						label={'Channel Account Code (Auto-generated)'}
						placeholder="- - - -"
						control={control}
						disabled={true}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.partnershipType'
						)}
						disabled={disabled}
						control={control}
						required
						placeholder="Partnership Type"
						name="primaryInfo.partnershipType"
						options={PartnerShipTypes}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.businessGroup'
						)}
						disabled={disabled}
						control={control}
						required
						placeholder={PrimaryInformationSchema.getLabel(
							'primaryInfo.businessGroup'
						)}
						name="primaryInfo.businessGroup"
						options={BusinessGroupChannel}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.channelTransactionType'
						)}
						disabled={disabled}
						control={control}
						required
						placeholder="Select Channel Transaction Type"
						name="primaryInfo.channelTransactionType"
						options={ChannelTransactionType}
						onChange={(value) => {
							if (value !== channelTransactionType) {
								setValue('primaryInfo.channelType', '', { shouldDirty: true });
								setValue('primaryInfo.posType', '', {
									shouldDirty: true,
								});

								const name = `primaryInfo.channelAddresses[0]`;
								if (value === 'DIGITAL') {
									setValue(`${name}.area`, 'NATIONWIDE');
								}
								clearErrors('primaryInfo.channelType');
								clearErrors('primaryInfo.posType');
							}
						}}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel('primaryInfo.channelType')}
						disabled={disabled}
						control={control}
						required
						placeholder="Select Channel Type"
						name="primaryInfo.channelType"
						options={channelTypeOptions}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				{isPosShown && (
					<Grid column size={1} of={3}>
						<SelectField
							label={PrimaryInformationSchema.getLabel('primaryInfo.posType')}
							// disabled={disabled || !channelTransactionType}
							control={control}
							required
							placeholder="Select POS Type"
							name="primaryInfo.posType"
							options={PosType}
							disabled={disabled}
						/>
					</Grid>
				)}
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.settlementSetup'
						)}
						// disabled={disabled}
						control={control}
						required
						placeholder="Select Settlement Setup"
						name="primaryInfo.settlementSetup"
						options={SettlementSetup}
						disabled={disabled}
					/>
				</Grid>
				{!channelTransactionType &&
					(!isBusinessTypeShown ||
						channelTransactionType?.toLowerCase() == 'digital') && (
						<Grid column size={1} of={3}>
							<TextField
								name="primaryInfo.tin"
								label={'TIN'}
								required
								disabled={disabled}
								control={control}
							/>
						</Grid>
					)}
				{channelTransactionType && !isBusinessTypeShown && (
					<Grid column size={1} of={3}>
						<TextField
							name="primaryInfo.tin"
							label={'TIN'}
							required
							disabled={disabled}
							control={control}
						/>
					</Grid>
				)}
				{isBusinessTypeShown && (
					<Grid column size={1} of={3}>
						<SelectField
							label={PrimaryInformationSchema.getLabel(
								'primaryInfo.businessType'
							)}
							// disabled={disabled}
							control={control}
							required
							placeholder="Select Business Type"
							name="primaryInfo.businessType"
							options={BusinessType.sort(compareLabels)}
							disabled={disabled}
						/>
					</Grid>
				)}
			</SectionRow>
			{isShowThirdSection && (
				<SectionRow>
					{isOtherBusinessTypeShown && (
						<>
							<Grid column size={1} of={3}>
								<TextField
									label="Other Business Type"
									disabled={disabled}
									required
									control={control}
									name="primaryInfo.otherBusinessType"
								/>
							</Grid>
							<Grid column size={1} of={3}>
								<TextField
									name="primaryInfo.tin"
									label={'TIN'}
									required
									disabled={disabled}
									control={control}
								/>
							</Grid>
						</>
					)}
				</SectionRow>
			)}
			<SectionRow>
				{isPosShown && isBusinessTypeShown && !isOtherBusinessTypeShown && (
					<Grid column size={1} of={3}>
						<TextField
							name="primaryInfo.tin"
							label={'TIN'}
							required
							disabled={disabled}
							control={control}
						/>
					</Grid>
				)}
			</SectionRow>
		</Section>
	);
};

export default BasicInformation;
