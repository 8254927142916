import Section, { SectionRow } from 'components/Section/Section';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import SelectField, {
	SelectOption,
} from 'components/Inputs/SelectField/SelectField';
import {
	FieldValues,
	UseFormClearErrors,
	UseFormSetError,
	UseFormSetValue,
	useFieldArray,
	useFormContext,
	useWatch,
} from 'react-hook-form';
import { useEffect, useState } from 'react';
import Button from 'components/Buttons/Button';
import styles from './ChannelAddress.module.css';
import { ReactComponent as AddIcon } from 'assets/icons/ic-add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete.svg';
import TextField from 'components/Inputs/TextField/TextField';
import { useRef, ReactNode } from 'react';
import { Country } from 'utils/queries/location';
import ProvinceSelectFilter from 'components/BillerForm/Tabs/PrimaryInformation/sections/BillerAddress/Select/ProvinceSelectFilter';
import CountrySelect from 'components/BillerForm/Tabs/PrimaryInformation/sections/BillerAddress/Select/CountrySelect';
import CitySelect from 'components/BillerForm/Tabs/PrimaryInformation/sections/BillerAddress/Select/CitySelect';
import BarangaySelect from 'components/BillerForm/Tabs/PrimaryInformation/sections/BillerAddress/Select/BarangaySelect';
import RegionSelect from 'components/BillerForm/Tabs/PrimaryInformation/sections/BillerAddress/Select/RegionSelect';
import Grid from 'components/Grid/Grid';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessChannelModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useToggle } from 'utils/hooks';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { AddressType, AddressDefaultValue, Area } from 'utils/lookup';
import {
	TChannelAddress,
	LocalChannelAddress,
	AddressEntryProps,
	ChannelAddressFields,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelAddress/types';
import { PrimaryInfoType } from '../PrimaryInformationSchema';
import { FixMeLater } from 'types';
import ZipCodeSelect from 'components/Inputs/SelectField/Types/LocationSelect/ZipCodeSelect';
import _ from 'lodash';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

function useClearFieldWatcher(
	field: TChannelAddress,
	index: number,
	name: string,
	setValue: UseFormSetValue<FieldValues>,
	clearErrors: UseFormClearErrors<FieldValues>,
	setError: UseFormSetError<FieldValues>,
	disabled: boolean,
	getValues: FixMeLater,
	isChannelDetails?: boolean
) {
	const n = `${name}.${index}` as `${typeof name}.${number}`;

	useEffect(() => {
		let rest = {};
		const channelAddress = getValues(`primaryInfo.channelAddresses.${index}`);

		if (channelAddress) {
			rest = {
				cityId: channelAddress.cityId,
				barangayId: channelAddress.barangayId,
				zipCodeId: channelAddress.zipCodeId,
				area: channelAddress.area,
				countryId:
					field.locationBase === 'INTERNATIONAL' //Check if its international
						? channelAddress.countryId === 175
							? undefined //show undefined when international but has data of 175 (basically user is created a new edit details)
							: channelAddress.countryId //Show actual countryId if not 175
						: 175, // Defualt to 175 when not international
				regionId: channelAddress.regionId,
				category: undefined,
				provinceId: channelAddress.provinceId,
				street: channelAddress.street,
				buildingName: channelAddress.buildingName,
				id: channelAddress.id || null,
			};
		} else {
			rest = {
				countryId: field.locationBase === 'LOCAL' ? 175 : undefined,
				barangayId: undefined,
				zipCodeId: undefined,
				area: undefined,
				regionId: undefined,
				category: undefined,
				provinceId: undefined,
				street: undefined,
				buildingName: undefined,
			};
		}

		if (field.locationBase === 'LOCAL' || field.locationBase === undefined) {
			setValue(
				n,
				{
					locationBase: 'LOCAL',
					countryId: channelAddress ? channelAddress.countryId : 175,
					...rest,
				},
				{ shouldDirty: true }
			);
		} else {
			if (!disabled)
				setValue(
					n,
					{
						locationBase: 'INTERNATIONAL',
						countryId: undefined,
						...rest,
					},
					{ shouldDirty: true }
				);
			setError(n, { message: 'Select Country' });
			clearErrors();
		}

		clearErrors();
	}, [field.locationBase, n, index]);
}

const AddressEntry: React.FC<AddressEntryProps<any, any>> = ({
	control,
	field,
	disabled = false,
	index,
	name: n,
	onAdd,
	onRemove,
	onLoad,
	canAdd = !disabled,
	getValues,
	setValue,
	clearErrors,
	setError,
	didDelete,
	setDidDelete,
	isChannelDetails,
}) => {
	const name = `primaryInfo.channelAddresses[${index}]`;

	useClearFieldWatcher(
		field as TChannelAddress,
		index,
		n,
		setValue,
		clearErrors,
		setError,
		disabled,
		getValues,
		isChannelDetails
	);

	const handleRegionSelect = (v: any, options: any, index: number) => {
		setValue(`${name}.regionId`, v);
		setValue(`${name}.provinceId`, null);
		setValue(`${name}.cityId`, null);
		setValue(`${name}.barangayId`, null);
	};

	const handleProvinceSelect = (v: any, options: any, index: number) => {
		setValue(`${name}.provinceId`, v);
		setValue(`${name}.cityId`, null);
		setValue(`${name}.barangayId`, null);
	};

	const handleCitySelect = (v: any, options: any, index: number) => {
		const match = options.find(({ value }: SelectOption) => value == v);
		setValue(`${name}.${index}.cityId`, match.value);
		setValue(`${name}.barangayId`, null);
	};

	const handleBarangaySelect = (value: any, options: SelectOption[]) => {
		const match = options.find(({ value: v }) => v == value);
		if (match) {
			setValue(`${name}.barangayObj`, match);
		}
	};

	const handleCountrySelect = (value: any, options: SelectOption[]) => {
		const match = options.find(({ value: v }) => v == value);
		if (match) {
			setValue(`${name}.countryId`, match.label);
		}
	};

	const channelTransactionType = useWatch({
		control,
		name: `primaryInfo.channelTransactionType`,
	});

	useEffect(() => {
		// set addresses on load, get from react form
		const channelAddress = getValues(`primaryInfo.channelAddresses.${index}`);
		setValue(
			`${name}.regionId`,
			channelAddress ? channelAddress.regionId : undefined
		);

		setValue(
			`${name}.provinceId`,
			channelAddress ? channelAddress.provinceId : undefined
		);
		setValue(
			`${name}.cityId`,
			channelAddress ? channelAddress.cityId : undefined
		);
		setValue(
			`${name}.barangayId`,
			channelAddress ? channelAddress.barangayId : undefined
		);
	}, []);

	useEffect(() => {
		const isDigital =
			channelTransactionType &&
			channelTransactionType.toLowerCase() === 'digital';
		const areaType = getValues(`primaryInfo.channelAddresses.${index}`)?.area;
		const addressLength = getValues(`primaryInfo.channelAddresses`)?.length - 1;

		if (didDelete) {
			if (addressLength === index) {
				setDidDelete(false);
			}
			return;
		} else if (areaType === undefined && isDigital) {
			setValue(`${name}.area`, 'NATIONWIDE');
		} else if (areaType && isDigital) {
			// setValue(`${name}.area`, 'NATIONWIDE');
		}

		// if (addressLength !== prevAddressLength) return;
	}, [channelTransactionType, setValue, name]);

	useEffect(() => {
		field && onLoad(field.id);
	}, [field]);

	return (
		<div className={styles.address}>
			<SectionRow align="spread">
				<Grid column size={1} of={3}>
					<RadioGroup
						label="Location Base"
						disabled={disabled}
						options={AddressType}
						name={`${name}.locationBase`}
						control={control}
						defaultValue={field.locationBase}
					/>
				</Grid>
				<Grid column>
					{index === 0 ? (
						<Button
							fullWidth
							onClick={onAdd}
							className={styles.addButton}
							disabled={!canAdd}
						>
							<AddIcon /> Add Channel Address
						</Button>
					) : (
						<Button onClick={() => onRemove(index)} disabled={disabled}>
							<DeleteIcon />
						</Button>
					)}
				</Grid>
			</SectionRow>
			{field.locationBase === 'LOCAL' ? (
				<>
					<SectionRow>
						<Grid column size={1} of={3}>
							<SelectField
								label="Country"
								disabled
								required
								placeholder="Philippines"
								name={`${name}.countryId`}
								value={''}
								options={[]}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<SelectField
								label="Area"
								placeholder="Select Area"
								disabled={disabled}
								control={control}
								name={`${name}.area`}
								required
								value={field.area}
								defaultValue={field.area}
								options={Area.map((i) => ({
									label: i,
									value: i,
								}))}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<RegionSelect
								placeholder="Select Region"
								disabled={disabled}
								control={control}
								name={`${name}.regionId`}
								value={field.regionId}
								isFromChannel={true}
								defaultValue={field.regionId}
								onChange={(v: any, options: any) =>
									handleRegionSelect(v, options, index)
								}
							/>
						</Grid>
					</SectionRow>
					<SectionRow>
						<Grid column size={1} of={3}>
							<ProvinceSelectFilter
								placeholder="Select State/Province"
								disabled={!field.regionId || disabled}
								control={control}
								regionId={field.regionId}
								isFromChannel={true}
								name={name}
								defaultValue={field.provinceId}
								onChange={(v: any, options: any) =>
									handleProvinceSelect(v, options, index)
								}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<CitySelect
								placeholder="Select Municipality/City"
								control={control}
								name={`${name}.cityId`}
								isFromChannel={true}
								provinceId={field.provinceId}
								disabled={!field.provinceId || disabled}
								defaultValue={field.cityId}
								onChange={(v: any, options: any) =>
									handleCitySelect(v, options, index)
								}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<BarangaySelect
								placeholder="Select Barangay"
								control={control}
								name={name}
								cityId={field.cityId}
								isFromChannel={true}
								disabled={!field.cityId || disabled}
								defaultValue={field.barangayId}
								onChange={(v: any, options: any) =>
									handleBarangaySelect(v, options)
								}
							/>
						</Grid>
					</SectionRow>
					<SectionRow>
						<Grid column size={1} of={3}>
							<TextField
								label="Street"
								disabled={disabled}
								control={control}
								name={`${name}.street`}
								defaultValue={field.street}
								// required
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<TextField
								label="Building Name/No."
								disabled={disabled}
								control={control}
								name={`${name}.buildingName`}
								defaultValue={field.buildingName}
								required
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<ZipCodeSelect
								control={control}
								name={`${name}.zipCodeId`}
								barangayId={field.barangayId}
								disabled={!field.barangayId || disabled}
								defaultValue={field.zipCodeId}
								required
							/>
						</Grid>
					</SectionRow>
				</>
			) : (
				<SectionRow>
					<Grid column size={1} of={3}>
						<CountrySelect
							control={control}
							disabled={disabled}
							name={`${name}.countryId`}
							countryFilter={(country: Country) =>
								country.country_code !== 'PH'
							}
							isFromChannel={true}
							defaultValue={field.countryId}
							onChange={handleCountrySelect}
						/>
					</Grid>
				</SectionRow>
			)}
		</div>
	);
};

const ChannelAddress: React.FC<{
	maxEntries?: number;
	disabled?: boolean;
	management?: any;
	primaryData?: FixMeLater;
	isChannelDetails?: boolean;
	didDeleteChannel?: any;
	setDidDeleteChannel?: any;
}> = ({
	maxEntries,
	disabled,
	primaryData,
	isChannelDetails,
	didDeleteChannel,
	setDidDeleteChannel,
}) => {
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const name = 'primaryInfo.channelAddresses';
	const [activeEntry, setActiveEntry] = useState<
		{ index: number; value: TChannelAddress } | undefined
	>(undefined);

	const { control, setValue, clearErrors, setError, getValues } =
		useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const watchAddresses = useWatch({ name, control });

	const controlledFields = fields.map((field, i) => ({
		...field,
		...watchAddresses[i],
	}));

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const lastEntryRef = useRef<HTMLDivElement>(null);
	const {
		value: isAddressErrorModalOpen,
		valueOn: openAddressErrorModal,
		valueOff: closeAddressErrorModal,
	} = useToggle();
	const {
		value: isConfirmDeleteModalOpen,
		valueOn: openConfirmDeleteModal,
		valueOff: closeConfirmDeleteModal,
	} = useToggle();

	const [isChannelAddress, setIsChannelAddress] = useState<boolean>(false);
	const handleAddEntry = () => {
		if (maxEntries && controlledFields.length >= maxEntries) {
			openAddressErrorModal();
			return;
		}

		append(AddressDefaultValue);
		setIsChannelAddress(true);
	};

	const handleScrollClick = (values: any) => {
		const lastValue = _.last(values) as { id: any };
		const lastValueWithType = `ChannelAddress-${lastValue.id}`;
		const section = document.getElementById(lastValueWithType);
		if (isChannelAddress === true) {
			if (section) {
				section.scrollIntoView({ behavior: 'smooth' });
				setIsChannelAddress(false);
			}
		}
	};

	const handleConfirmDeleteEntry = (index: number, value: TChannelAddress) => {
		setActiveEntry({ index, value });
		openConfirmDeleteModal();
	};
	const [isForceRerender, setIsForceRerender] = useState(
		`initial-${Math.random().toString(36).substring(2, 15)}`
	);

	const handleDeleteAddress = (index: any | undefined) => {
		activeEntry && remove(activeEntry.index);

		closeConfirmDeleteModal();
		setDidDeleteChannel(true);

		showSuccessMessage(
			<div className={styles.successModalText}>
				Channel Address {index} <br />
				successfully deleted.
			</div>,
			() => {
				setIsForceRerender(
					`render-${Math.random().toString(36).substring(2, 15)}`
				); // Toggle the flag to force re-render
			}
		);
	};

	const showSuccessMessage = (message: any, onDoneBtnClick?: () => void) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	return (
		<>
			{isForceRerender &&
				controlledFields.map((field, i) => (
					<div
						id={`ChannelAddress-${field.id}`}
						key={`ChannelAddress-${field.id}-${i}`}
					>
						<Section
							title={`Channel Address${i > 0 ? ` ${i + 1}` : ''}`}
							key={`${field.id}-${isForceRerender}-${i}`}
						>
							<AddressEntry
								key={`${field.id}-${isForceRerender}-${i}`}
								isChannelDetails={isChannelDetails}
								primaryData={primaryData}
								control={control}
								disabled={disabled}
								field={field}
								index={i}
								name={name}
								onAdd={handleAddEntry}
								onRemove={(index) =>
									handleConfirmDeleteEntry(index, field as TChannelAddress)
								}
								onLoad={(index) => handleScrollClick(controlledFields)}
								didDelete={didDeleteChannel}
								setDidDelete={setDidDeleteChannel}
								getValues={getValues}
								setValue={setValue}
								clearErrors={clearErrors}
								setError={setError}
							/>
							{i === controlledFields.length - 1 && <div ref={lastEntryRef} />}
						</Section>
					</div>
				))}
			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			<ErrorModal
				open={isAddressErrorModalOpen}
				onClose={closeAddressErrorModal}
			>
				<ErrorModalBody>
					<ErrorText>Failed to Add Channel Address</ErrorText>
					<ErrorSubText>
						You have reached max number of <br /> accepted channel address
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={closeAddressErrorModal}
					>
						Close
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
			{activeEntry && (
				<ConfirmationModal
					isOpen={isConfirmDeleteModalOpen}
					heading={
						<div className={styles.headerClass}>
							{activeEntry && `Delete Channel Address ${activeEntry.index + 1}`}
						</div>
					}
					message={
						<>
							Are you sure you want to delete{' '}
							<span className={styles.confirmTextName}>
								Channel Address {activeEntry.index + 1}?
							</span>
						</>
					}
					onClose={() => {
						setActiveEntry(undefined);
						closeConfirmDeleteModal();
					}}
					onCancelBtnClick={closeConfirmDeleteModal}
					onConfirmBtnClick={() => handleDeleteAddress(activeEntry.index + 1)}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Delete"
				/>
			)}
		</>
	);
};

export default ChannelAddress;
