import React, { useState, useEffect } from 'react';
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import TerminalDetails from 'containers/ChannelManagement/Terminal/Tab/TerminalDetails';
import ChannelAuditTrail from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrail';
import { TerminalTabProps } from 'containers/ChannelManagement/Terminal/types';
import { useSelector, useDispatch } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { setActiveTab } from 'redux/modules/channelList';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';

const TerminalTab: React.FC<TerminalTabProps> = ({
	refetchTerminal,
	terminalId,
	isDisabled,
	setIsDisabled,
	setIsDirty,
	isTerminalError,
}) => {
	// const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const dispatch = useDispatch();
	const activeTypes = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeType
	);
	useEffect(() => {
		if (activeTypes == 'terminal') {
			dispatch(setActiveTab({ tab: 'DetailsTab' }));
		}
	}, [activeTypes]);

	const handleSelectedIndex = (index: number) => {
		if (activeTypes == 'terminal') {
			if (index === 0) {
				dispatch(setActiveTab({ tab: 'DetailsTab' }));
			} else if (index === 1) {
				dispatch(setActiveTab({ tab: 'AuditTrail' }));
			}
		}
	};

	const activeTab = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeTab
	);

	const panelPlaceholderProps = {
		title: 'No Selected Terminal',
		subtitle: 'Please select a Terminal.',
	};

	const primaryTabProps = {
		tabs: [
			{
				title: 'Terminal Details',
				component:
					activeTab == 'DetailsTab' ? (
						<TerminalDetails
							terminalId={terminalId}
							refetchTerminal={refetchTerminal}
							isDisabled={isDisabled}
							setIsDisabled={setIsDisabled}
							setIsDirty={setIsDirty}
							isTerminalError={isTerminalError}
						/>
					) : (
						<PanelPlaceholder {...panelPlaceholderProps} />
					),
				scope: { permissions: ['view'] },
			},
			{
				title: 'Audit Trail',
				// scope: 'audit-trail',
				component:
					activeTab == 'AuditTrail' ? (
						<ChannelAuditTrail initialValues={{}} />
					) : (
						<PanelPlaceholder {...panelPlaceholderProps} />
					),
				// selectedIndex === 1 ? <ChannelAuditTrail initialValues={{}} /> : null,
				scope: { permissions: ['list.audit-trail'] },
			},
		],
		defaultIndex: 0,
	};
	return (
		<PrimaryTab
			{...primaryTabProps}
			onSelect={handleSelectedIndex}
			module={'terminals'}
		/>
	);
};

export default TerminalTab;
