import * as yup from 'yup';
import {
	defaultInvalidCharacter,
	defaultOnlyAlphaChar,
	hasSpecialCharacter,
	selectDefaultRequiredTemplate,
} from 'utils/formSchemas/common';

import {
	CHANNEL_ACCOUNT_NAME,
	CHANNEL_ACCOUNT_CODE,
	BUSINESS_GROUP,
	PARTNER_SHIP_TYPE,
	CHANNEL_TRANSACTION_TYPE,
	CHANNEL_TYPE,
	POS_TYPE,
	SETTLEMENT_SETUP,
	BUSINESS_TYPE,
	OTHER_BUSINESS_TYPE,
	TIN,
	TIN_REGEX,
	TIN_MESSAGE,
	PARTNER_LOWER_CASE,
	OTHERS_LOWER_CASE,
	CUSTOMER_FRONTLINE_SOLUTIONS,
} from './const';
import {
	MAX_CHAR_255,
	MAX_CHAR_255_MSG,
} from 'containers/ChannelManagement/Channel/ChannelForm/const';
import { YupLab } from 'utils/yupLab';
import { ChannelContactDetailsSchema } from '../ChannelContactDetails/ChannelContactDetailsSchema';
import { ChannelAddressSchema } from '../ChannelAddress/ChannelAddressSchema';
import { ChannelOfficerSchema } from '../ChannelOfficer/ChannelOfficerSchema';

export const objectSchema = yup.object({
	logo: yup.mixed(),
	channelAccountName: yup
		.string()
		.label(CHANNEL_ACCOUNT_NAME)
		.required('Input Channel Account Name')
		.matches(
			/^[a-zA-Z0-9\s]*$/,
			'This field only allows alphanumeric characters.'
		)
		.matches(/^[a-zA-Z0-9].*$/, 'Input Channel Account Name.')
		.max(MAX_CHAR_255, MAX_CHAR_255_MSG),
	channelAccountCode: yup.string().label(CHANNEL_ACCOUNT_CODE),
	inCfs: yup
		.mixed()
		.label(CUSTOMER_FRONTLINE_SOLUTIONS)
		.required('Select option for CFS.')
		.default('1'),
	partnershipType: yup
		.string()
		.label(PARTNER_SHIP_TYPE)
		.required('Select Partnership Type.')
		.nullable(true)
		.default(null)
		.max(MAX_CHAR_255, MAX_CHAR_255_MSG),
	businessGroup: yup
		.string()
		.label(BUSINESS_GROUP)
		.required('Select a ' + BUSINESS_GROUP)
		.nullable(true)
		.default(null)
		.max(MAX_CHAR_255, MAX_CHAR_255_MSG),
	channelTransactionType: yup
		.string()
		.label(CHANNEL_TRANSACTION_TYPE)
		.required('Select Channel Transaction Type.')
		.nullable(true)
		.default(null),
	channelType: yup
		.string()
		.label(CHANNEL_TYPE)
		.required('Select a Channel Type.')
		.nullable(true)
		.default(null),
	posType: yup
		.string()
		.nullable(true)
		.label(POS_TYPE)
		.when('channelTransactionType', {
			is: (channelTransactionType?: string) => {
				return channelTransactionType === 'PHYSICAL';
			},
			then: yup
				.string()
				.required('Select a POS Type.')
				.nullable(true)
				.default(null)
				.test({
					test: (value) => value !== 'NaN',
					message: 'Select a POS Type.',
				}),
		}),
	settlementSetup: yup
		.string()
		.label(SETTLEMENT_SETUP)
		.required('Select a Settlement Setup.')
		.nullable(true)
		.default(null)
		.max(MAX_CHAR_255, MAX_CHAR_255_MSG),
	businessType: yup
		.string()
		.label(BUSINESS_TYPE)
		.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
		.when('channelType', {
			is: (channelType?: string) => {
				return channelType === '2';
			},
			then: yup
				.string()
				.required('Select a Business Type.')
				.nullable(true)
				.default(null),
		}),
	otherBusinessType: yup
		.string()
		.nullable(true)
		.label(OTHER_BUSINESS_TYPE)
		.matches(/^[a-zA-Z0-9\s]*$/, {
			excludeEmptyString: true,
			message: defaultOnlyAlphaChar,
		})
		.matches(/^[a-zA-Z0-9].*$/, 'Input Other Business Type.')
		.when('businessType', {
			is: (businessType?: string) => {
				return businessType === '14';
			},
			then: (schema) =>
				schema
					.required()
					.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
					.matches(/^[a-zA-Z0-9\s]*$/, {
						excludeEmptyString: true,
						message: defaultOnlyAlphaChar,
					}),
		}),
	tin: yup
		.string()
		.label(TIN)
		.nullable(true)
		.required('Input TIN.')
		.transform((value, originalValue) => {
			return originalValue.trim() === '' ? null : value;
		})
		.matches(/^[0-9].*$/, 'Input 12 numeric characters only.')
		.matches(TIN_REGEX, {
			excludeEmptyString: true,
			message: TIN_MESSAGE,
		}),
	channelAddresses: ChannelAddressSchema,
	channelContactDetails: ChannelContactDetailsSchema,
	channelOfficer: ChannelOfficerSchema.schema,
});

export const ChannelBasicInformationSchema = new YupLab(objectSchema);

export const channelAddressValues =
	ChannelBasicInformationSchema.schema.getDefault();
export type BasicInfoType = yup.InferType<typeof objectSchema>;
