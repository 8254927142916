import { Button, Input, InputIcon } from '@salesforce/design-system-react';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import cx from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DropdownCheckbox from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import { channelStatuses } from 'constants/channelType';
import BranchDropdownCheckbox from 'containers/ChannelManagement/List/BranchDropdownCheckbox';
import styles from 'containers/ChannelManagement/List/style.module.css';
import { ScrollableListProps } from 'containers/ChannelManagement/List/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	activeType,
	branchListFilter,
	channelListFilter,
	selectedBranch,
	selectedChannel as setSelectedChannel,
	selectedTerminal,
	setBranchFormValues,
	setLoader,
	setTerminalFormValues,
	terminalListFilter,
} from 'redux/modules/channelList';
import { ReducerStateType } from 'redux/modules/reducers';
import { FixMeLater } from 'types';

const ChannelScrollableList: React.FC<ScrollableListProps> = ({
	primaryButtonOnClick,
	primaryButtonTitle,
	listTitle,
	dropdownOptions,
	onFilterChange,
	searchPlaceholder,
	children,
	clearFilter,
	filterControl,
	hasPartnerListPermission = true,
	selectedChannelName,
	selectedBranchName,
	selectedTerminalName,
	setSelectedChannelName,
	setSelectedBranchName,
	setSelectedTerminalName,
	setShowChannel,
	setShowBranch,
	setShowTerminal,
	setSelectedTerminaID,
}) => {
	const [searchFilter, setSearchFilter] = useState<string>('');
	const [typeItemChecked, setTypeItemChecked] = useState<Array<any>>([]);
	const [statusItemChecked, setStatusItemChecked] = useState<Array<any>>([]);

	const selectedChannel = useSelector<ReducerStateType, any>(
		(state) => state.channels.selectedChannel
	);

	const channelFilter = useSelector(
		(state: ReducerStateType) => state.channels.channelFilter
	);
	const branchFilter = useSelector(
		(state: ReducerStateType) => state.channels.branchFilter
	);
	const terminalFilter = useSelector(
		(state: ReducerStateType) => state.channels.terminalFilter
	);

	const dispatch = useDispatch();

	const selectedBranchState = useSelector(
		(state: FixMeLater) => state.channels.selectedBranch
	);

	const hasBadges = typeItemChecked.length && statusItemChecked.length;

	const resetChannelSearch = () => {
		setSearchFilter('');
		dispatch(
			channelListFilter({
				channelFilter: {
					search: '',
					category: channelFilter.category,
					status: channelFilter.status,
				},
			})
		);
	};

	const resetBranchSearch = () => {
		setSearchFilter('');
		dispatch(
			branchListFilter({
				branchFilter: {
					name: '',
					posType: branchFilter.posType,
					storeType: branchFilter.storeType,
					storeFormat: branchFilter.storeFormat,
					machineLocation: branchFilter.machineLocation,
				},
			})
		);
	};

	useEffect(() => {
		setSearchFilter('');
		setStatusItemChecked([]);
		setTypeItemChecked([]);
		dispatch(
			channelListFilter({
				channelFilter: {
					search: '',
					category: [],
					status: [],
				},
			})
		);
	}, []);

	const resetTerminalSearch = () => {
		setSearchFilter('');
		dispatch(
			terminalListFilter({
				terminalFilter: {
					tpaId: '',
				},
			})
		);
	};

	const clearDropdowns = () => {
		setTypeItemChecked([]);
		setStatusItemChecked([]);
	};
	const filterValues = useMemo(
		() => ({
			searchFilter,
			typeItemChecked,
			statusItemChecked,
		}),
		[searchFilter, typeItemChecked, statusItemChecked]
	);

	useEffect(() => {
		onFilterChange(filterValues);
	}, [filterValues]);

	const handleShowChannelList = () => {
		setShowChannel(true);
		setShowBranch(false);
		setShowTerminal(false);
		setSelectedChannelName(null);
		setSelectedBranchName(null);
		setSelectedTerminalName(null);
		setSelectedTerminaID('');
		clearFilter();
		clearDropdowns();
		dispatch(activeType({ type: '' }));
		dispatch(setSelectedChannel({ channel: {} }));
		dispatch(
			channelListFilter({
				channelFilter: {
					search: '',
					category: '',
					status: '',
				},
			})
		);
		dispatch(
			branchListFilter({
				branchFilter: {
					name: '',
					posType: '',
					storeType: '',
					storeFormat: '',
					machineLocation: '',
				},
			})
		);
		dispatch(
			terminalListFilter({
				terminalFilter: {
					tpaId: '',
				},
			})
		);
		dispatch(setBranchFormValues({}));
		dispatch(setTerminalFormValues({}));
		dispatch(setLoader({ isLoading: false, message: 'Please Wait...' }));
	};

	const handleShowBranchList = () => {
		setShowChannel(false);
		setShowBranch(true);
		setShowTerminal(false);
		setSelectedBranchName(null);
		setSelectedTerminalName(null);
		setSelectedTerminaID('');
		clearFilter();
		clearDropdowns();
		dispatch(activeType({ type: 'channel' }));
		dispatch(selectedBranch({ branch: {} }));
		dispatch(setBranchFormValues({}));
		dispatch(selectedTerminal({ terminal: {} }));
		dispatch(
			branchListFilter({
				branchFilter: {
					name: '',
					posType: '',
					storeType: '',
					storeFormat: '',
					machineLocation: '',
				},
			})
		);
		dispatch(
			terminalListFilter({
				terminalFilter: {
					tpaId: '',
				},
			})
		);
		dispatch(setBranchFormValues({}));
		dispatch(setTerminalFormValues({}));
		dispatch(setLoader({ isLoading: false, message: 'Please Wait...' }));
	};
	const handleShowBranchDetails = () => {
		setShowChannel(false);
		setShowBranch(false);
		setShowTerminal(true);
		setSelectedTerminalName(null);
		setSelectedTerminaID('');
		clearFilter();
		clearDropdowns();
		dispatch(activeType({ type: 'branch' }));
		dispatch(selectedTerminal({ terminal: {} }));
		dispatch(
			branchListFilter({
				branchFilter: {
					name: '',
					posType: '',
					storeType: '',
					storeFormat: '',
					machineLocation: '',
				},
			})
		);
		dispatch(
			terminalListFilter({
				terminalFilter: {
					tpaId: '',
				},
			})
		);
		dispatch(setTerminalFormValues({}));
		dispatch(setLoader({ isLoading: false, message: 'Please Wait...' }));
	};
	const newDropdownOptions = dropdownOptions.filter(
		(item) =>
			item.label !== 'Digital (Online/Mobile App)' &&
			item.label !== 'Financial Institution' &&
			item.label !== 'Machine (Inside Office)' &&
			item.label !== 'Machine (Outside Office)'
	);
	return (
		<div
			className={cx(
				'slds-col slds-small-size_5-of-12 slds-large-size_3-of-12',
				styles.listContainer
			)}
		>
			<div className={styles.listNav}>
				{selectedChannelName ? (
					<div>
						<span
							onClick={handleShowChannelList}
							className={styles.listNavItemPointer}
						>
							{'Channel List'}
						</span>
						<span
							onClick={handleShowBranchList}
							className={
								selectedChannel?.name && !selectedBranchName
									? styles.listNavItem
									: styles.listNavItemPointer
							}
						>
							{' > ' + selectedChannel?.name}
						</span>
						{selectedBranchName ? (
							<span
								onClick={handleShowBranchDetails}
								className={
									selectedBranchName && !selectedTerminalName
										? styles.listNavItem
										: styles.listNavItemPointer
								}
							>
								{' > ' + selectedBranchState?.name}
							</span>
						) : null}
						{selectedTerminalName ? (
							<span className={selectedTerminalName ? styles.listNavItem : ''}>
								{' > ' + selectedTerminalName}
							</span>
						) : null}
					</div>
				) : null}
			</div>
			<div className="slds-grid slds-grid_align-spread">
				<div className={cx('slds-col slds-size_1-of-1', styles.listTitle)}>
					{listTitle}
				</div>
				<PrimaryButton
					className="slds-col slds-size_1-of-1"
					onClick={primaryButtonOnClick}
				>
					{primaryButtonTitle}
				</PrimaryButton>
			</div>
			<div className={styles.dropdownContainer}>
				<div
					style={{ display: 'flex', paddingTop: '0.65vh' }}
					className={styles.dropdownBorderless}
				>
					{!selectedChannelName ? (
						<>
							<DropdownCheckbox
								className={cx(hasBadges && styles.dropdownNoMargin)}
								placeholder="Channel Type"
								name="filter.category"
								options={newDropdownOptions}
								control={filterControl}
								onChange={(val) => {
									setTypeItemChecked(val),
										dispatch(
											channelListFilter({
												channelFilter: {
													search: channelFilter.search,
													category: val.join('|'),
													status: channelFilter.status,
												},
											})
										);
								}}
								referenceCheckedItems={typeItemChecked}
								setReferenceCheckedItems={setTypeItemChecked}
								hasBadgeCount
								isReturnAllOnChange
								disabled={!hasPartnerListPermission}
							/>
							<DropdownCheckbox
								placeholder="Status"
								name="filter.status"
								options={channelStatuses}
								control={filterControl}
								onChange={(val) => {
									setStatusItemChecked(val),
										dispatch(
											channelListFilter({
												channelFilter: {
													search: channelFilter.search,
													category: channelFilter.category,
													status: val.join('|'),
												},
											})
										);
								}}
								referenceCheckedItems={statusItemChecked}
								setReferenceCheckedItems={setStatusItemChecked}
								hasBadgeCount
								isReturnAllOnChange
								disabled={!hasPartnerListPermission}
							/>
							{(!!typeItemChecked.length || !!statusItemChecked.length) && (
								<Button
									style={{
										marginLeft: 'auto',
										padding: hasBadges ? 0 : 'auto',
									}}
									onClick={() => {
										clearFilter();
										clearDropdowns();
										dispatch(
											channelListFilter({
												channelFilter: {
													search: channelFilter.search,
													category: '',
													status: '',
												},
											})
										);
									}}
									disabled={!hasPartnerListPermission}
								>
									<CloseIcon height="10px" width="10px" />
								</Button>
							)}
						</>
					) : !selectedBranchName ? (
						<BranchDropdownCheckbox
							branchFilter={branchFilter}
							dispatch={dispatch}
						/>
					) : null}
				</div>
			</div>
			<div className={styles.searchByName}>
				<Input
					placeholder={searchPlaceholder}
					className={cx(styles.searchBar, styles.partnerSearch)}
					type="search"
					iconLeft={
						<InputIcon
							style={{
								height: '13.5px',
								width: '13.5px',
								transform: 'scale(1.5)',
							}}
							assistiveText={{
								icon: 'search',
							}}
							name="search"
							category="utility"
						/>
					}
					iconRight={
						<InputIcon
							assistiveText={{
								icon: 'Clear',
							}}
							name="clear"
							category="utility"
							onClick={
								!selectedChannelName
									? resetChannelSearch
									: selectedChannelName && !selectedBranchName
									? resetBranchSearch
									: resetTerminalSearch
							}
						/>
					}
					value={
						!selectedChannelName
							? channelFilter.search
							: selectedChannelName && !selectedBranchName
							? branchFilter.name
							: terminalFilter.tpaId
					}
					onChange={(val) => {
						setSearchFilter(val.target.value),
							!selectedChannelName
								? dispatch(
										channelListFilter({
											channelFilter: {
												search: val.target.value,
												category: channelFilter.category,
												status: channelFilter.status,
											},
										})
								  )
								: selectedChannelName && !selectedBranchName
								? dispatch(
										branchListFilter({
											branchFilter: {
												name: val.target.value,
												posType: branchFilter.posType,
												storeType: branchFilter.storeType,
												storeFormat: branchFilter.storeFormat,
												machineLocation: branchFilter.machineLocation,
											},
										})
								  )
								: dispatch(
										terminalListFilter({
											terminalFilter: {
												tpaId: val.target.value,
											},
										})
								  );
					}}
					disabled={!hasPartnerListPermission}
				/>
			</div>
			<div className={styles.scrollableContainer}>{children}</div>
		</div>
	);
};

export default ChannelScrollableList;
