import cx from 'classnames';
import SelectField from 'components/Inputs/SelectField/SelectField';
import styles from './styles.module.css';
import { LOADING_MESSAGE, statusOption, statusOptionTwo } from './constants';
import { useToggle } from 'utils/hooks';
import { restrictSpecialChars } from 'utils/common';
import Modal from '@salesforce/design-system-react/components/modal';

import { useEffect, useState } from 'react';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { StatusOption } from './types';
import { useUpdateStatus } from './Queries';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessChannelModal';
import { startCase } from 'lodash';
type StatusSelectProps = {
	name?: string;
	control?: any;
	value?: string;
	disabled?: boolean;
	id?: string | number;
	idType?: 'channels' | 'branches' | 'TPAID';
	onClose?: any;
	refetch?: () => void;
};
/**
 * Use the status pill as preview of the status by setting `disalbed` to `true`
 * Use the status pill to update the current status of `Channel | Branch | TPAID` by providing the corresponding `ID`
 * @param disalbed if set to `false` the options cannot be selected, this can be use for preview only.
 * @param value value to display
 * @param id id of  Channel | Branch | TPAID
 * @param idType type Channel | Branch | TPAID
 * @param onClose emits onClose emits true Status is Updated`(value) => void` when emited true best to reload the data
 * @returns void null
 */
const StatusUpdate: React.FC<StatusSelectProps> = ({
	disabled,
	value,
	id,
	idType = '',
	onClose,
	refetch,
}) => {
	const {
		value: statusUpdateModal,
		valueOn: showStatusUpdateModal,
		valueOff: hideStatusUpdateModal,
	} = useToggle();

	const {
		value: isSuccessQuery,
		valueOn: showSuccess,
		valueOff: hideSuccess,
	} = useToggle();

	const {
		value: isErrorQuery,
		valueOn: showError,
		valueOff: hideError,
	} = useToggle();

	const [remarks, setRemarks] = useState<string>('');
	const [previousValue, setPreviousValue] = useState(value);
	const [updateValue, setUpdateValue] = useState<any>(previousValue);

	useEffect(() => {
		if (value) {
			setPreviousValue(value);
		}
	}, [value]);

	const { data, isLoading, isError, isSuccess, mutate } = useUpdateStatus({
		remarks,
		id,
		idType: idType === 'TPAID' ? 'terminals' : idType,
		status: updateValue,
	});

	useEffect(() => {
		if (isSuccess) {
			hideStatusUpdateModal();
			setPreviousValue(updateValue);
			onClose(true);
			setRemarks('');
			showSuccess();
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			setUpdateValue(previousValue);
			showError();
		}
	}, [isError]);

	const match = statusOption.find((option) => option.value == previousValue);
	let convertUpdateValue = startCase(updateValue.toLocaleLowerCase());
	if (updateValue.toLocaleUpperCase() == 'ONGOING_INTEGRATION') {
		convertUpdateValue = 'On-going Integration';
	}
	let convertIDType = '';
	//idType?: 'channels' | 'branches' | 'terminals';
	if (idType == 'channels') {
		convertIDType = 'Channel';
	} else if (idType == 'branches') {
		convertIDType = 'Branch';
	} else {
		convertIDType = 'Terminal';
	}
	return (
		<>
			<SelectField
				options={
					!idType || idType == 'channels' ? statusOption : statusOptionTwo
				}
				value={match?.value}
				className={cx(styles.select, match ? match.className : '')}
				buttonClassName={styles.button}
				size="small"
				disabled={disabled}
				onChange={(value) => {
					if (value.value != match?.value) {
						showStatusUpdateModal();
						setUpdateValue(value.value);
						setRemarks('');
					}
				}}
			/>

			{statusUpdateModal && (
				<Modal
					assistiveText={{ closeButton: 'close' }}
					isOpen={statusUpdateModal}
					ariaHideApp={false}
					onRequestClose={() => {
						hideStatusUpdateModal();
						onClose(false);
						setUpdateValue(previousValue);
					}}
					containerClassName={styles.modalContainer}
					headerClassName={'slds-text-heading_medium'}
					header={
						idType == 'channels' || idType == 'branches'
							? `Update ${convertIDType}  Status`
							: `Update ${idType} Status`
					}
					footer={
						<Footer
							disabled={!(remarks.length > 0)}
							hideStatusUpdateModal={hideStatusUpdateModal}
							updateStatus={mutate}
						/>
					}
				>
					<div className="slds-text-align_center slds-m-vertical_x-small">
						{idType == 'channels' || idType == 'branches' ? (
							<>
								<div>
									Are you sure you want to update {convertIDType} status?
								</div>
								<div>Kindly note that this will be the {convertIDType}</div>
								<div>status in production environment.</div>
							</>
						) : (
							<>
								<div>Are you sure you want to update {idType} status?</div>
								<div>kindly note that this will be the {idType}</div>
								<div>status in production environment.</div>
							</>
						)}
					</div>
					<div className={styles.bodyContent}>
						<div className="slds-form-element">
							<label className="slds-form-element__label">
								Remarks
								<abbr className="slds-required" title="required">
									*
								</abbr>
							</label>
							<div className="slds-form-element__control">
								<textarea
									className={'slds-textarea' + ' ' + styles.remarks}
									maxLength={1000}
									onInput={restrictSpecialChars}
									value={remarks}
									onChange={(value) => setRemarks(value.target.value)}
								></textarea>
							</div>
						</div>
						<em className={styles.remarksSubLabel}>
							*Maximum of 1000 characters only.
						</em>
					</div>
				</Modal>
			)}

			{isLoading && (
				<FullPageLoader open={isLoading} message={LOADING_MESSAGE} />
			)}

			{isSuccessQuery && (
				<SuccessModal
					open={isSuccessQuery}
					onClose={() => {
						hideSuccess();
						refetch?.();
					}}
					ariaHideApp={false}
				>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successMessage}>
								<div className={'slds-text-body_small'}>
									{idType == 'channels' || idType == 'branches' ? (
										<>
											You successfully updated the {convertIDType} status to{' '}
											{convertUpdateValue}
										</>
									) : (
										<>
											You successfully updated the {idType} status to{' '}
											{convertUpdateValue}
										</>
									)}
								</div>
							</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								hideSuccess();
								refetch?.();
							}}
						>
							Okay
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}

			{isErrorQuery && (
				<ErrorModal open={isErrorQuery} onClose={hideError} ariaHideApp={false}>
					<ErrorModalBody>
						<div className={styles.errorBodyContent}>
							<div className={styles.errorHeader}>Network Error</div>
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								hideError();
							}}
							className={styles.errorModalBtn}
						>
							Close
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
		</>
	);
};

const Footer: React.FC<{ disabled; hideStatusUpdateModal; updateStatus }> = ({
	disabled,
	hideStatusUpdateModal,
	updateStatus,
}) => {
	return (
		<div className={styles.approvalModalFooter}>
			<OutlineButton
				onClick={() => {
					hideStatusUpdateModal();
				}}
				className={styles.approvalModalBtn}
			>
				Cancel
			</OutlineButton>
			<PrimaryButton
				onClick={() => {
					updateStatus();
				}}
				className={styles.approvalModalBtn}
				disabled={disabled}
			>
				Confirm
			</PrimaryButton>
		</div>
	);
};

export default StatusUpdate;
