import SelectField from 'components/Inputs/SelectField/SelectField';
import { SelectFieldProps } from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import { useMemo } from 'react';
import { useProvinceFilterQuery } from 'utils/queries/location';

export type ProvinceSelectProps = {
	regionId?: string;
	selectFieldProps?: SelectFieldProps;
};

const ProvinceSelect: React.FC<ProvinceSelectProps> = ({
	regionId,
	selectFieldProps,
}) => {
	const { data, isLoading } = useProvinceFilterQuery(
		'provinces',
		{
			regionId,
		},
		{ enabled: !!regionId }
	);
	const options = useMemo(
		() =>
			data?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [],

		[data]
	);

	return (
		<SelectField
			label="State/Province"
			isLoading={isLoading}
			options={options}
			{...selectFieldProps}
		/>
	);
};

export default ProvinceSelect;
